import React, { useState, useEffect } from "react";
import axios from "axios";
import routes from "../../Routes/sidebar";
import { Link } from "react-router-dom";
import { useAppContext } from "../../Providers/AppProvider";
import { Config } from "../../Config";

function SidebarContent() {
  const {
    state: { selectedAgenda, authenticated },
  } = useAppContext();
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (authenticated === true) {
      axios
        .get(`${Config.ServerURL}/api/Auth/JmenoPrijmeni`, {
          withCredentials: true,
        })
        .then((res) => {
          setName(res.data);
        });
      axios
        .get(`${Config.ServerURL}/api/Auth`, {
          withCredentials: true,
        })
        .then((res) => {
          setUserName(res.data);
        })
    }
    // eslint-disable-next-line
  }, [authenticated]);

  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <Link
        className="ml-6 text-3xl font-bold text-gray-800 dark:text-gray-200"
        to="/"
      >
        iPes TEST
      </Link>
      <div className="mx-6 px-2 bg-red-600 text-center font-lg font-semibold rounded-md text-white  py-3 my-3" >Testovací verze interního Personálního systému</div>

      {/* Info: Přihlášený uživatel */}
      <div className="px-6 my-1">
        <div className="bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-200 rounded-md px-3 py-2 text-center text-sm font-semibold">
          <div>{userName}</div>
          <div>{name}</div>
        </div>
      </div>

      {/* Info: Nabídka agend */}    
      <ul className="mt-1">
        {routes.map((route) => (
          <li className="relative px-6 py-3" key={route.name}>
            <Link
              to={route.path}
              className={`${
                route.agendaType === selectedAgenda
                  ? "font-bold text-gray-900 bg-amber-500 dark:bg-amber-400 dark:text-gray-900"
                  : "font-semibold dark:text-gray-100 bg-amber-400"
              } inline-flex items-center w-full text-lg transition-all duration-300 hover:text-gray-900 dark:hover:bg-amber-400 dark:bg-amber-900 p-2 rounded-md shadow-md hover:bg-amber-500 `}
            >
              <div className="ml-2 w-8">{route.icon}</div>
              <span className="ml-4">{route.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SidebarContent;

// původní Nabídka - styl GRAY
//--------------------
// className={`${
//   route.agendaType === selectedAgenda
//     ? "font-bold text-gray-900 bg-gray-300 dark:bg-gray-400 dark:text-gray-900"
//     : "font-semibold dark:text-gray-100"
// } inline-flex items-center w-full text-lg transition-all duration-300 hover:text-gray-900 dark:hover:bg-gray-400 dark:bg-gray-900 bg-gray-100 p-2 rounded-md shadow-md hover:bg-gray-300`}
