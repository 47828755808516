import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  REFRESH_DATA,
} from "../../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../../Config";

// 1. upravit nazev componenty
export default function PridaniZamestnance() {
  const { dispatch } = useAppContext();
  const [innerProps, setInnerProps] = useState({});
  const [open, setOpen] = useState(false);
  const [odbory, setOdbory] = useState([])

  // LA 31.5.23 - s tímto nastavením vracel React do API prázdné jméno a příjmení !!!
  // Výchozí hodnoty zaměstnance
  useEffect(() => {
    if (open === true && odbory.length > 0) {
      setInnerProps({
        // prijmeni: "",
        // jmeno: "",
        denuvazek: 8,
        zobrazVKontPritom: true,
        nezobrazVNepritomnosti: false,
        domenaPrihlasovaciJmeno: "musmnet\\",
        idOdbor: odbory[0].idOdbor,
        vedouci: false
      });
    }
  }, [open, odbory]);

  // původní varianta - zde se sami nepředvyplňují logické hodnoty - nutno naklikat !!!
  useEffect(() => {
    setInnerProps({});
  }, [open]);
  
  useEffect(() => {
    if (open === true) {
      axios.get(`${Config.ServerURL}/api/Dochazka/OdboryZapis`, {
        withCredentials: true
      })
        .then(res => {
          setOdbory(res.data)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const cancelButtonRef = useRef(null);

  function Add() {
    axios
    //2. upravit post
      .post(`${Config.ServerURL}/api/Dochazka/PridaniZamestnance`, innerProps, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch ({ type: REFRESH_DATA })
        setOpen(false);
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message || "Chybná vstupní data!" });
      });
  }

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-4xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                    Přidání zaměstnance
                  </Dialog.Title>
                    <div className="grid grid-cols-6 gap-3">                      
                      
                       {/* --- Editace příjmení --- */}
                       <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Příjmení
                        </label>
                        <input
                          value={innerProps.prijmeni} //3. zaměnit za vybranou property
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps, //4. zaměnit za vybranou property
                              prijmeni: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div> 

                      {/* --- Editace jména --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Jméno
                        </label>
                        <input
                          value={innerProps.jmeno}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              jmeno: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace titul --- */}
                      <div className="col-span-6 sm:col-span-1 max-w-fit">
                        <label className="dialog-edit-pole-popis">
                          Titul
                        </label>
                        <input
                          value={innerProps.titul}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              titul: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace pohlaví...--- */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                          Pohlaví
                        </label>
                        <select value={innerProps.pohlavi} onChange={(e) => setInnerProps({...innerProps, pohlavi: e.target.value,})}
                          className="admin-dialog-edit-pole-input"> 
                                <option value="0">Muž</option>
                                <option value="1">Žena</option>
                                <option value="2">Nebinární</option>
                                <option value="3">Nespecifikováno</option>
                        </select>
                      </div>


                      {/* --- Editace Odbor --- */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Odbor
                        </label>
                        <select 
                        value={innerProps.idOdbor}
                        onChange={(e) =>
                          setInnerProps({
                            ...innerProps,
                            idOdbor: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-select"
                        >
                          {odbory.map(o => <option key={o.idOdbor} value={o.idOdbor}>{o.nazevOd} ({o.zkrOd})</option>)}
                        </select>
                      </div>

                      {/* --- Editace přihlašovací jméno --- */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                        Přihlašovací jméno do AD (musmnet\jmeno)
                        </label>
                        <input
                          value={innerProps.domenaPrihlasovaciJmeno}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              domenaPrihlasovaciJmeno: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-4">
                        <label className="dialog-edit-pole-popis">
                          Pracovní pozice
                        </label>
                        <input
                          value={innerProps.pozice}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pozice: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace denní úvazek --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Denní úvazek (počet hodin)
                        </label>
                        <input
                          value={innerProps.denuvazek || 0}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              denuvazek: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace  Zobrazit v kontaktech a přítomnosti--- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Zobrazit v "Kontakty a přítomnost"
                        </label>
                        <input type="checkbox" checked={innerProps.zobrazVKontPritom}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              zobrazVKontPritom: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Nezobrazit v přehledu nepřítomnosti--- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Nezobrazit v "Nepřítomnosti" <br /> (tj. nevede si docházku)
                        </label>
                        <input type="checkbox" checked={innerProps.nezobrazVNepritomnosti}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              nezobrazVNepritomnosti: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Nevkládat automaticky pauzu na oběd--- 
                      LA 26.4.23 - irelevantní, vyřazeno */}

                      {/* <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Nevkládat automaticky pauzu na oběd
                        </label>
                        <input type="checkbox" checked={innerProps.nevkladatPauzuObed || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              nevkladatPauzuObed: e.target.checked,
                            })
                          }
                        ></input>
                      </div> */}

                      {/* --- Editace  Nezkracovat pracovní den ...--- 
                      LA 26.4.23 - irelevantní, vyřazeno*/}
                      {/* <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Nezkracovat pr.den při pr.době do 12 hod
                        </label>
                        <input type="checkbox" checked={innerProps.nezkracovatPriViceHod || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              nezkracovatPriViceHod: e.target.checked,
                            })
                          }
                        ></input>
                      </div> */}

                      {/* Vedoucí odboru */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Je vedoucí odboru
                        </label>
                        <input type="checkbox" checked={innerProps.vedouci}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              vedouci: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Zaměstnanec vyřazen (archiv) ...--- */}
                      {/* <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                        Zaměstnanec vyřazen (archiv)
                        </label>
                        <input type="checkbox" checked={innerProps.vyrazen || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              vyrazen: e.target.checked,
                            })
                          }
                        ></input>
                      </div> */}

                      {/* --- Editace: Datum nástupu --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Datum nástupu (povinné)
                        </label>
                        <input
                          value={innerProps.datNastupu}
                          type="date"
                          onChange={(e) => {
                            dateIsValid(e.target.value) &&
                            setInnerProps({
                              ...innerProps,
                              datNastupu: e.target.value,
                            })
                          }}
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>


                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-akce"
                      onClick={() => Add()}
                    >
                      Přidat
                    </button>
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        onClick={() => setOpen(true)}
        className="admin-dialog-tlacitko-akce "
      >
        Přidat zaměstnance
      </div>
    </>
  );
}
