import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useAppContext,
  SET_LOADING,
  SET_ERROR_ALERT,
} from "../../Providers/AppProvider";
import { Config } from "../../Config";
import { SET_LAST_UPDATE, useKontaktyContext } from "../../Providers/KontaktyProvider";
import UzivatelKontakt from "./UzivatelKontakt";

export default function Tabulka({ type }) {
  const { dispatchKont } = useKontaktyContext();
  const { state: { refresh }, dispatch } = useAppContext();
  const [pritomniKontakty, setPritomni] = useState([]);

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    axios
      .get(`${Config.ServerURL}/api/Kontakty/Tabulka${type}`, {
        withCredentials: true,
      })
      .then((res) => {
        setPritomni(res.data);
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.message })
        dispatch({ type: SET_LOADING, payload: false });
      })
    axios
      .get(`${Config.ServerURL}/api/Dochazka/Cas`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatchKont({ type: SET_LAST_UPDATE, payload: res.data })
      });
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <div>
      <table className="table-auto">
        <tbody>
          {
            pritomniKontakty.map((d, index) => (
              <tr className="rounded-sm" key={index}>
                <td className="px-2 font-semibold text-center text-lg">{d.nazevSkupinyKontaktu}</td>
                <td className="flex flex-row gap-x-2 gap-y-[2px] flex-wrap">
                  {d.skupinaKontaktu.map((s, index) => (
                    <UzivatelKontakt key={index} kontakty={true} kontaktData={s} />
                  ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}