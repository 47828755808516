export const Config = {
	// API lokální ladění
	ServerURL:  "https://localhost:7052",
		
	// API Produkce
	//ServerURL: "https://ipes:444",
	
	// API Test	
	// ServerURL: "https://ipes:8444", 
	
	// API Test http
	//ServerURL:  "http://192.168.0.15:81",


	// Čas pro automatickou aktualizaci dat v milisekundách
	AutoRefreshTime: 60000, //1 minuta


    // Čas pro automatickou aktualizaci ToolTipu v milisekundách
	ToolTipAutoRefreshTime1: 500, //0,3 s
	ToolTipAutoRefreshTime2: 700, //0,5 s
};