import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  REFRESH_DATA,
} from "../../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../../Config";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { Tooltip } from "react-tooltip";

export default function PridaniUpravaStavuTach({ data, novyZaznam }) {
  const { dispatch } = useAppContext();
  const [open, setOpen] = useState(false);
  const [stavTachData, setStavTachData] = useState({});

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  useEffect(() => {
    if (open === true) {
      setStavTachData(data)
      if (novyZaznam === false) {
        var d = structuredClone(data)
        d.datumOdectu = format(new Date(data.datumOdectu), "yyyy-MM-dd")
        setStavTachData(d)
      }
      if (novyZaznam === true) {
        format(new Date(), "yyyy-MM-dd")
        dispatch({ type: SET_LOADING, payload: true })
        axios.get(`${Config.ServerURL}/api/Vozidla/StavyTachometruNoveObdobi/${data.idVozidla}`, { withCredentials: true }).then((res) => {
          setStavTachData({ datumOdectu: format(new Date(res.data[0].datumOdectu), "yyyy-MM-dd"), idVozidla: data.idVozidla, stavTachometru: res.data[0].stavTachometru, obdobiRok: res.data[0].obdobiRok, obdobiMesic: res.data[0].obdobiMesic })
        }).finally(()=> {
          dispatch({ type: SET_LOADING, payload: false })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const cancelButtonRef = useRef(null);

  function UpravitStavTach() {
    dispatch({ type: SET_LOADING, payload: true })
    axios
      .post(
        `${Config.ServerURL}/api/Vozidla/UpravaStavuTachometru`,
        stavTachData,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        setOpen(false);
        dispatch({ type: REFRESH_DATA })
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  function PridatStavTach() {
    dispatch({ type: SET_LOADING, payload: true })
    axios
      .post(
        `${Config.ServerURL}/api/Vozidla/PridaniStavuTachometru`,
        stavTachData,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch({ type: REFRESH_DATA })
        setOpen(false);
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-3xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="text-lg dialog-edit-nadpis-velky">
                  {novyZaznam === true && ("Přidání Stavu tachometru:")}
                  {novyZaznam === false && ("Oprava Stavu tachometru:")}
                  </Dialog.Title>
                    <div className="grid grid-cols-6 gap-3">
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Období Rok
                        </label>
                        <input
                          value={stavTachData.obdobiRok || 2023}
                          type="number"
                          onChange={(e) =>
                            setStavTachData({
                              ...stavTachData,
                              obdobiRok: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Období Měsíc
                        </label>
                        <input
                          value={stavTachData.obdobiMesic || 1}
                          type="number"
                          onChange={(e) =>
                            setStavTachData({
                              ...stavTachData,
                              obdobiMesic: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Datum odečtu
                        </label>
                        <input
                          value={stavTachData.datumOdectu}
                          type="date"
                          onChange={(e) => {
                            dateIsValid(e.target.value) &&
                            setStavTachData({
                              ...stavTachData,
                              datumOdectu: e.target.value,
                            })
                          }}
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Stav tachometru
                        </label>
                        <input
                          value={stavTachData.stavTachometru || 0}
                          type="number"
                          onChange={(e) =>
                            setStavTachData({
                              ...stavTachData,
                              stavTachometru: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          Poznámka
                        </label>
                        <textarea
                          value={stavTachData.poznamka || ""}
                          onChange={(e) =>
                            setStavTachData({
                              ...stavTachData,
                              poznamka: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></textarea>
                      </div>

                    </div>
                  </div>

                  {/* Tlačítka */}
                  <div className="dialog-edit-zapati-pozadi">
                    {novyZaznam === false &&
                      (<button
                        type="button"
                        className="admin-dialog-edit-tlacitko-akce"
                        onClick={() => UpravitStavTach()}
                      >
                        Uložit
                      </button>)}
                    {novyZaznam === true &&
                      (
                        <button
                          type="button"
                          className="admin-dialog-edit-tlacitko-akce"
                          onClick={() => PridatStavTach()}
                        >
                          Přidat
                        </button>
                      )}
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {novyZaznam === false &&
        (<div
          data-tooltip-id="oprava-stavutach-tooltip" 
          data-tooltip-content="Oprava stavu tachometru"
          data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
          onClick={() => setOpen(true)}
          className="admin-dialog-edit-ikona-oprava"
        >
          <span className="sr-only">Otevřit menu úprav</span>
          <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
          <Tooltip id="oprava-stavutach-tooltip" />
        </div>)}
      {novyZaznam === true && (
        <div
          onClick={() => setOpen(true)}
          className={`admin-dialog-tlacitko-akce`}
        >
          Přidat Stav Tachometru
        </div>
      )}
    </>
  );
}
