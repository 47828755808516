import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  REFRESH_DATA,
} from "../../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../../Config";
import { PencilSquareIcon } from "@heroicons/react/24/solid";

// 1. upravit nazev componenty
export default function UpravaUdalosti({ data }) {
  const { dispatch } = useAppContext();
  const [innerProps, setInnerProps] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInnerProps(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const cancelButtonRef = useRef(null);

  function Edit() {
    axios
    //2. upravit post
      .post(`${Config.ServerURL}/api/Dochazka/UpravaUdalosti`, innerProps, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch ({ type: REFRESH_DATA })
        setOpen(false);
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-4xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                    Oprava události
                  </Dialog.Title>
                    <div className="grid grid-cols-6 gap-3">
                      <div className="hidden">{data.idUdalosti}</div> {/* 2.5 změnit název */}

                      {/* --- Editace Zkratka události ...--- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Zkratka události
                        </label>
                        <input
                          value={innerProps.zkrUdal} //3. zaměnit za vybranou property
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps, //4. zaměnit za vybranou property
                              zkrUdal: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace název události ...--- */}
                      <div className="col-span-6 sm:col-span-4">
                        <label className="dialog-edit-pole-popis">
                          Název události
                        </label>
                        <input
                          value={innerProps.nazev}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              nazev: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace Zobrazení v přehledech ...--- */}
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          Způsob vyhodnocení a zobrazení události v přehledech
                        </label>
                        <select value={innerProps.typuTisk} onChange={(e) => setInnerProps({...innerProps, typuTisk: e.target.value,})}
                          className="admin-dialog-edit-pole-select"> 
                                <option value="0">--- bez výběru ---</option>
                                <option value="1">pracovní den</option>
                                <option value="2">přerušení</option>
                                <option value="3">dovolená</option>
                                <option value="4">nemoc</option>
                                <option value="5">služební cesty</option>
                                <option value="6">soc.volno</option>
                                <option value="7">home office</option>
                                <option value="8">ostatní volno</option>
                        </select>
                      </div>

                      {/* --- Editace  Systémová událost--- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Systémová událost
                        </label>
                        <input type="checkbox" checked={innerProps.system || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              system: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Základní událost--- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Základní  událost
                        </label>
                        <input type="checkbox" checked={innerProps.zakladni || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              zakladni: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Přerušení --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Přerušení
                        </label>
                        <input type="checkbox" checked={innerProps.preruseni || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              preruseni: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Povolit Přerušení --- */}
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                        Povolit Přerušení během základní události
                        </label>
                        <input type="checkbox" checked={innerProps.prepovol || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              prepovol: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Zadávat datum --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Zadávat datum události
                        </label>
                        <input type="checkbox" checked={innerProps.datumIn  || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              datumIn: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Zadávat čas --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Zadávat čas události
                        </label>
                        <input type="checkbox" checked={innerProps.casIn  || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              casIn: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Zadávat dny --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                        Zadávat prac. dny u události
                        </label>
                        <input type="checkbox" checked={innerProps.dnyIn  || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              dnyIn: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Zadávat pouze celé hodiny --- */}
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                        Zadávat pouze celé hodiny
                        </label>
                        <input type="checkbox" checked={innerProps.pouzeCelaHod  || false}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pouzeCelaHod: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                      {/* --- Editace  Výpočet pracovní doby --- */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Výpočet pracovní doby
                        </label>
                        <select value={innerProps.vypPracdoby} onChange={(e) => setInnerProps({...innerProps, vypPracdoby: e.target.value, })}
                            className="admin-dialog-edit-pole-select">
                            <option value="0">-----</option>
                            <option value="1">Odečíst od pracovní doby</option>
                            <option value="2">Bez výpočtu</option>
                            <option value="3">Přičíst k pracovní době</option>
                        </select>
                      </div>

                      {/* --- Editace  Výpočet lístků na obědy --- */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Výpočet lístků na obědy
                        </label>
                        <select value={innerProps.vypObedy} onChange={(e) => setInnerProps({...innerProps, vypObedy: e.target.value, })}
                            className="admin-dialog-edit-pole-select">
                            <option value="0">-----</option>
                            <option value="1">Odečíst lístek</option>
                            <option value="2">Bez výpočtu</option>
                            <option value="3">Přičíst lístek</option>
                        </select>
                      </div>

                      {/* --- Editace  Minimální počet dnů --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Minimální počet dnů
                        </label>
                        <input
                          value={innerProps.minDny}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              minDny: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace  Maximální počet dnů --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Maximální počet dnů
                        </label>
                        <input
                          value={innerProps.maxDny}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              maxDny: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace  Maximální počet opakování --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Max. počet opakování (den/rok)
                        </label>
                        <input
                          value={innerProps.maxOpak}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              maxOpak: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace  Minimální čas --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Minimální délka události (min)
                        </label>
                        <input
                          value={innerProps.minCas}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              minCas: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace  Maximální čas --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Maximální délka události (min)
                        </label>
                        <input
                          value={innerProps.maxCas}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              maxCas: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace  Minimální čas od začátku PD --- */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                            Minimální čas od začátku PD (min)
                        </label>
                        <input
                          value={innerProps.minCasOdZacPD}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              minCasOdZacPD: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>


                      {/* --- Editace  Vysvětlivky --- */}
                      <div className="col-span-6 sm:col-span-6 min-h-full">
                        <label className="dialog-edit-pole-popis">
                        Vysvětlivky, poznámky pro uživatele
                        </label>

                        <textarea value={innerProps.vysvetlivky} onChange={(e) =>
                            setInnerProps({...innerProps, vysvetlivky: e.target.value, })}        
                            className="admin-dialog-edit-pole-input"
                            rows="5"
                        >
                        </textarea>
                      </div>


                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 sm:flex sm:flex-row justify-end sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => Edit()}
                    >
                      Uložit
                    </button>
                    <button
                      type="button"
                      className="mt-3 dark:bg-gray-500 transition-all dark:hover:bg-gray-800 dark:text-white dark:border-black inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        onClick={() => setOpen(true)}
        className="admin-dialog-edit-ikona-oprava"
      >
        <span className="sr-only">Otevřit menu úprav</span>
        <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
      </div>
    </>
  );
}
