import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { format } from "date-fns";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  REFRESH_DATA,
} from "../../Providers/AppProvider";
import axios from "axios";
import {
  useDochazkaContext,
} from "../../Providers/DochazkaProvider";
import { Config } from "../../Config";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Tooltip } from 'react-tooltip';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export default function UpravaDochazky({ idDoch, preruseni, editOd, editDo, role }) {
  const { dispatch } = useAppContext();
  const {
    state: { globalniUdalosti },
  } = useDochazkaContext();
  const [open, setOpen] = useState(false);
  const [udalosti, setUdalosti] = useState([]);
  const [idUdalosti, setIdUdalosti] = useState(1);
  const [zacatek, setZacatek] = useState(null);
  const [konec, setKonec] = useState(null);
  const [pocpracdnu, setPocpracdnu] = useState(null);
  const [showTime, setShowTime] = useState(true);

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  useEffect(() => {
    if (open === true) {
      if (globalniUdalosti && globalniUdalosti.count !== 0) {
        if (preruseni === true) {
          setUdalosti(globalniUdalosti.filter((x) => x.preruseni === true));
        } else if (preruseni === false) {
          setUdalosti(globalniUdalosti.filter((x) => x.zakladni === true));
        } else {
          setUdalosti(globalniUdalosti);
        }
      }
      axios
        .get(`${Config.ServerURL}/api/Dochazka/${idDoch}`, {
          withCredentials: true,
        })
        .then((res) => {
          setIdUdalosti(res.data.idUdalosti);
          setZacatek(format(
            new Date(res.data.zacatek),
            "yyyy-MM-dd HH:mm:ss"
          ));
          setKonec(format(
            new Date(res.data.konec),
            "yyyy-MM-dd HH:mm:ss"
          ));
          setPocpracdnu(res.data.pocpracdnu);
          //console.log(res.data)
        });
    }
  }, [open, globalniUdalosti, preruseni, idDoch]);

  useEffect(() => {
    const udalost = globalniUdalosti.find((u) => u.idUdalosti === idUdalosti);
    if (udalost) {
      if (udalost.casIn === true) {
        setShowTime(true);
      } else {
        setShowTime(false);
      }
    }
  }, [open, idUdalosti, globalniUdalosti]);

  const cancelButtonRef = useRef(null);

  function EditDoch() {
    dispatch({ type: SET_LOADING, payload: true })

    axios
      .post(
        `${Config.ServerURL}/api/Dochazka/ZapisDochazky/Edit/${role}`,
        {
          idDoch: idDoch,
          idUdalosti: idUdalosti,
          zacatek: zacatek,
          konec: konec,
          pocpracdnu: pocpracdnu,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        dispatch({type: REFRESH_DATA})
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        setOpen(false);
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                  {preruseni === false ? "Oprava hlavní události" : "Oprava přerušení"}
                  </Dialog.Title>
                    <div className="grid grid-cols-6 gap-3">
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Typ události
                        </label>
                        <select
                          value={idUdalosti}
                          onChange={(e) =>
                            setIdUdalosti(parseInt(e.target.value))
                          }
                          className="dialog-edit-pole-select"
                        >
                          {udalosti.map((udalost, index) => (
                            <option key={index} value={udalost.idUdalosti}>
                              {udalost.nazev} ({udalost.zkrUdal})
                            </option>
                          ))}
                        </select>
                        {/* LA + AA 13.5.23 Zobrazení popisu vybrané události */}
                        <ReactMarkdown className="reactMarkDown dialog-edit-pole-napoveda">
                          {udalosti.length > 0 ? udalosti.find(u => u.idUdalosti === idUdalosti)?.vysvetlivky : ""}
                        </ReactMarkdown>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Začátek
                        </label>
                        {showTime ? (
                          <input disabled={!editOd}
                            value={format(
                              new Date(zacatek),
                              "yyyy-MM-dd HH:mm"
                            )}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setZacatek(e.target.value);
                            }}
                            type="datetime-local"
                              className="dialog-edit-pole-input"
                          ></input>
                        ) : (
                          <input disabled={!editOd}
                            value={format(new Date(zacatek), "yyyy-MM-dd")}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setZacatek(e.target.value);
                            }}
                            type="date"
                            className="dialog-edit-pole-input"
                          ></input>
                        )}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Konec
                        </label>
                        {showTime ? (
                          <input disabled={!editDo}
                            value={format(new Date(konec), "yyyy-MM-dd HH:mm")}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setKonec(e.target.value);
                            }}
                            type="datetime-local"
                            className="dialog-edit-pole-input"
                          ></input>
                        ) : (
                          <input disabled={!editDo}
                            value={format(new Date(konec), "yyyy-MM-dd")}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setKonec(e.target.value);
                            }}
                            type="date"
                            className="dialog-edit-pole-input"
                          ></input>
                        )}
                      </div>
                      {/* Pole počet pracovních dnů povolit, jen když to nebude přerušení. Pole znepřístupnit pro editaci */}
                      {!preruseni && (
                        <div className="col-span-6 sm:col-span-3">
                          <label className="dialog-edit-pole-popis">
                            Počet pracovních dnů <br /> (vypočtený dle úvazku a svátků)
                          </label>
                          <input
                            disabled
                            value={pocpracdnu}
                            onChange={(e) => setPocpracdnu(e.target.value)}
                            type="number"
                            min="0"
                            className="dialog-edit-pole-input"
                          ></input>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-akce"
                      onClick={() => EditDoch()}
                    >
                      Uložit
                    </button>
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        data-tooltip-id="oprava-dochazky-tooltip" 
        data-tooltip-content="Oprava události docházky" 
        data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info"   
        onClick={() => setOpen(true)}
        className="dialog-edit-ikona-oprava"
      >
        <span className="sr-only">Otevřit menu úprav</span>
        <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
        <Tooltip id="oprava-dochazky-tooltip" />
      </div>
    </>
  );
}
