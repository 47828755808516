import React, {  createContext, useReducer, useContext } from "react"

export const RESET = "RESET"
export const SET_VOZIDLA = "SET_VOZIDLA"
export const SET_ZAMESTNANCI = "SET_ZAMESTNANCI"
export const SET_KATEGORIE = "SET_KATEGORIE"
export const SET_SKUPINY = "SET_SKUPINY"

const initialState = {
	vozidla: [],
	zamestnanci: [],
	kategorie: [],
	skupiny: [],
}

const dataReducer = (state, action) => {
	switch (action.type) {
		case SET_VOZIDLA: {
			return { ...state, vozidla: action.payload}
		}
		case SET_ZAMESTNANCI: {
			return { ...state, zamestnanci: action.payload}
		}
		case SET_KATEGORIE: {
			return { ...state, kategorie: action.payload}
		}
		case SET_SKUPINY: {
			return { ...state, skupiny: action.payload}
		}
		case RESET: {
			return initialState
		}
		default: {
			return state
		}
	}
}

export const Context = createContext({
	state: initialState,
	dispatchVoz: () => null,
})
export const VozidlaProvider = ({ children, ...rest }) => {
	const [state, dispatchVoz] = useReducer(dataReducer, initialState)

	return (
		<Context.Provider value={{ state, dispatchVoz }}>
			{children}
		</Context.Provider>
	)
}

export const useVozidlaContext = () => useContext(Context)
