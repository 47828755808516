import { useEffect } from 'react';
import { useDochazkaContext, SET_UDALOSTI } from '../../Providers/DochazkaProvider';
import axios from "axios"
import { Config } from "../../Config";

export default function LoadUdalosti() {
    const {dispatchDoch} = useDochazkaContext()
    useEffect(() => {
        axios.get(`${Config.ServerURL}/api/Udalost`, {
          withCredentials: true
      })
          .then(res => {
            dispatchDoch({type: SET_UDALOSTI, payload: res.data})
          })
          // eslint-disable-next-line
      }, [])
}