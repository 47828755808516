import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import UpravaRoleZam from './UpravaRoleZam';
// import DeleteRoleZam from './DeleteRoleZam';
import DeleteZaznamu from './DeleteZaznamu';
import PridaniRoleZam from './PridaniRoleZam';
import CheckBox from '../../CheckBox';
import { useAppContext } from '../../../Providers/AppProvider';
//import { SET_LOADING, useAppContext } from '../../Providers/AppProvider';

export default function RoleZam() {
  const { dispatch, state: { refresh } } = useAppContext();
  const [open, setOpen] = useState(false)
  const [zpusobRazeni, setzpusobRazeni] = useState(true)
  const [zamestnanci, setZamestnanci] = useState([])
  const cancelButtonRef = useRef(null);
  // pro hledání:
  const [search, setSearch] = useState("");
  const [zamestZAPI, setZamestZAPI] = useState([])

  useEffect(() => {
    if (open === true) {
      dispatch({ type: 'SET_LOADING', payload: true })
      axios.get(`${Config.ServerURL}/api/Dochazka/RoleZapis/${zpusobRazeni ? "1" : "2"}`, {
        withCredentials: true
      })
        .then(res => {
          setZamestZAPI(res.data)
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING', payload: false })
        })
    }
    // eslint-disable-next-line
  }, [open, zpusobRazeni, refresh]);

  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setZamestnanci(zamestZAPI.filter(z => z.prijmeni?.toLowerCase().startsWith(search.toLowerCase()) || z.jmeno?.toLowerCase().startsWith(search.toLowerCase()) || 
      z.roleStr?.toLowerCase().startsWith(search.toLowerCase()) || z.agendaStr?.toLowerCase().includes(search.toLowerCase()) 
      ))
    }
    else {
      setZamestnanci(zamestZAPI)
    }
  }, [zamestZAPI, search])


  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                          Role zaměstnanců (uživatelů) v iPES
                        </div>
                      </Dialog.Title>

                        {/* Pole pro hledání/filtraci a tlačítko pro zrušení*/}
                        <div className="vyhledavani-div mt-2">
                          <input className='vyhledavani-input' 
                            value={search} placeholder="Vyhledání rolí (jméno, role, agenda) ..." onChange={(e) => setSearch(e.target.value)} />   
                          {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
                          {search.length >= 2 &&
                            <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
                              Zrušit hledání
                            </div>}
                        </div>

                        <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id Role
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id zaměstnance
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Příjmení
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Jméno
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Titul
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Odbor
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Agenda
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Role
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Výchozí role?
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Editace
                              </th>
                            </tr>
                          </thead>

                          <tbody className="tabulka-telo-Ylinka">
                            {zamestnanci.map((z, index) => {
                              return (
                                <tr key={index} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{z.idRoleZam}</td>
                                  <td className='tabulka-bunka-basic'>{z.idZam}</td>
                                  <td className='tabulka-bunka-basic'>{z.prijmeni}</td>
                                  <td className='tabulka-bunka-basic'>{z.jmeno}</td>
                                  <td className='tabulka-bunka-basic'>{z.titul}</td>
                                  <td className='tabulka-bunka-basic'>{z.odborZkr}</td>
                                  <td className='tabulka-bunka-basic'>{z.agendaStr}</td>
                                  <td className='tabulka-bunka-basic'>{z.roleStr}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={z.vychoziRole} /></td>
                                  <td className='flex mt-1 flex-row'>
                                    <UpravaRoleZam data={z} />
                                    <DeleteZaznamu urlAPI="/api/Dochazka/OdstraneniRoleZam/" idZaznamu={z.idRoleZam} 
                                                  titulek={"Zaměstnanec/Agenda/Role: " + z.prijmeni + " " + z.jmeno + "/ " + z.agendaStr + "/ " + z.roleStr}
                                                  dotaz={"Přejete si odstranit uvedenou roli uvedeného zaměstnance?"}/> 
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>  <PridaniRoleZam />
                      <div className='admin-dialog-tlacitko-prepinac' onClick={() => setzpusobRazeni(!zpusobRazeni)}>{zpusobRazeni ? "Řazení dle agendy" : "Řazení dle abecedy"}</div>
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
        Role zaměstnanců
      </button>
    </>
  )
}