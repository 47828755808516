import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import { useAppContext } from '../../../Providers/AppProvider';
import PridaniUpravaSkupinyCilu from './PridaniUpravaSkupinyCilu';
import { SET_SKUPINY, useVozidlaContext } from '../../../Providers/VozidlaProvider';
import OdstraneniSkupinyCilu from './OdstraneniSkupinyCilu';
export default function SkupinyCilu() {
  const { state:{refresh} } = useAppContext();
  const { dispatchVoz } = useVozidlaContext();
  const [open, setOpen] = useState(false);
  const [skupinyCilu, setSkupinyCilu] = useState([])
  const cancelButtonRef = useRef(null);

  useEffect(() => {
      axios.get(`${Config.ServerURL}/api/Vozidla/SkupinyCiluZapis`, {
        withCredentials: true
      })
        .then(res => {
          dispatchVoz({ type: SET_SKUPINY, payload: res.data })
          setSkupinyCilu(res.data)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, refresh]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Editace Skupin cílů rezervací
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id skupiny
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Název skupiny
                              </th>
{/*                               <th className="text-left tabulka-zahlavi-sticky-admin">
                                Cíle skupiny
                              </th> */}
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Pořadí zobrazení
                              </th>
                              <th className='tabulka-zahlavi-sticky-admin'>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tabulka-telo-Ylinka">
                            {skupinyCilu.map((s) => {
                              return (
                                <tr key={s.idSkupiny} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{s.idSkupiny}</td>
                                  <td className='tabulka-bunka-basic'>{s.nazevSkupiny}</td>
                                  {/* <td className='pr-5'>{s.cileSkupiny}</td> */}
                                  <td className='tabulka-bunka-basic'>{s.poradiSkupiny}</td>
                                  <td className='flex mt-1 flex-row'>
                                    <PridaniUpravaSkupinyCilu novyZaznam={false} data={s}/>
                                    <OdstraneniSkupinyCilu idSkupiny={s.idSkupiny} />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>
                    <PridaniUpravaSkupinyCilu novyZaznam={true}/>
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
       Skupiny cílů
      </button>
    </>
  )
}