import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../Config"
import AdminKontaktyUprava from './AdminKontaktyUprava';
import { useAppContext } from '../../Providers/AppProvider';
import GlobalniParametry from '../GlobalniParametry';
import CheckBox from '../CheckBox';

export default function AdminKontakty() {
  const { state: { refresh } } = useAppContext()
  const [open, setOpen] = useState(false)
  const [aktivniKontakty, setAktivniKontakty] = useState(true)
  const [kontakty, setKontakty] = useState([])
  const [kontaktyZAPI, setKontaktyZAPI] = useState([])
  const cancelButtonRef = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    axios.get(`${Config.ServerURL}/api/Kontakty/KontaktyZapis/${aktivniKontakty ? "1" : "0"}`, {
      withCredentials: true
    })
      .then(res => {
        setKontaktyZAPI(res.data)
      })
  }, [open, aktivniKontakty, refresh]);


  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setKontakty(kontaktyZAPI.filter(k => k.prijmeni?.toLowerCase().startsWith(search.toLowerCase()) || k.jmeno?.toLowerCase().startsWith(search.toLowerCase()) || 
      k.odbor?.toLowerCase().startsWith(search.toLowerCase()) || k.pozice?.toLowerCase().includes(search.toLowerCase()) || k.tsPevna?.includes(search) || k.tsMobil?.startsWith(search)   
      )) 
    }
    else {
      setKontakty(kontaktyZAPI)
    }
  }, [kontaktyZAPI, search])
  
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                          Seznam {aktivniKontakty ? "aktivních" : "neaktivních"} kontaktů
                        </div>
                      </Dialog.Title>                     

                        {/* Pole pro hledání/filtraci a tlačítko pro zrušení*/}
                        <div className="vyhledavani-div mt-2">
                          <input className='vyhledavani-input' 
                            value={search} placeholder="Vyhledání rezervací (datum, jméno, cíl) ..." onChange={(e) => setSearch(e.target.value)} />   
                          {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
                          {search.length >= 2 &&
                            <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
                              Zrušit hledání
                            </div>}
                        </div>

                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">

                          <table className="tabulka-telo-Ylinka">
                            <thead>
                              <tr>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Příjmení
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Jméno
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Titul
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Odbor
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Pozice
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Pevná linka
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Mobil
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  E-mail
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Dat.akt.PL
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Dat.akt.mobil
                                </th>
                                <th className="text-left tabulka-zahlavi-sticky-admin">
                                  Aktivní? 
                                </th>
                                <th
                                  className="text-left tabulka-zahlavi-sticky-admin">
                                  Oprav
                                </th>
                              </tr>
                            </thead>

                            <tbody className="tabulka-telo-Ylinka">
                              {kontakty.map((k) => {
                                return (
                                  <tr key={k.idZam} className='tabulka-radek-hover'>
                                    <td className='tabulka-bunka-basic'>{k.prijmeni}</td>
                                    <td className='tabulka-bunka-basic'>{k.jmeno}</td>
                                    <td className='tabulka-bunka-basic'>{k.titul}</td>
                                    <td className='tabulka-bunka-basic'>{k.odbor}</td>
                                    <td className='tabulka-bunka-basic'>{k.pozice}</td>
                                    <td className='tabulka-bunka-basic'>{k.tsPevna}</td>
                                    <td className='tabulka-bunka-basic'>{k.tsMobil}</td>
                                    <td className='tabulka-bunka-basic'>{k.tsEmail}</td>
                                    <td className='tabulka-bunka-basic'>{k.tsDaktpev}</td>
                                    <td className='tabulka-bunka-basic'>{k.tsDaktmob}</td>
                                    <td className='tabulka-bunka-basic'><CheckBox checked={k.zobrazVKontPritom}/></td>
                                    <td className='flex mt-1 flex-row'>
                                      <AdminKontaktyUprava data={k} />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                      </div>
                    </div>
                  </div>

                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>
                      <div className='admin-dialog-tlacitko-prepinac' onClick={() => setAktivniKontakty(!aktivniKontakty)}>{aktivniKontakty ? "Zobrazit neaktivní kontakty" : "Zobrazit aktivní kontakty"}</div>
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="admin-plocha-nabidka-pozadi">
        <h3 className="admin-plocha-nabidka-popis">Administrace kontaktů</h3>
        <div className="flex flex-row">
          <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
            Kontakty - administrace
          </button>
          <GlobalniParametry/>
        </div>
      </div>
    </>
  )
}