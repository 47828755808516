import { MinusSmallIcon, CheckIcon } from "@heroicons/react/24/outline";

export default function CheckBox({ checked }) {
  return checked ? (
    <CheckIcon className="h-4 w-4 text-black bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-200" />
  ) : (
    <MinusSmallIcon className="h-4 w-4 text-black bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-200" />
  );
}



