import { format } from "date-fns"
import React, {  createContext, useReducer, useContext } from "react"

export const RESET = "RESET"
export const SET_LAST_UPDATE = "SET_LAST_UPDATE"


const initialState = {
	lastUpdate: "",
}

const dataReducer = (state, action) => {
	switch (action.type) {
		case SET_LAST_UPDATE: {
			return { ...state, lastUpdate: format(new Date(action.payload), "dd.MM. HH:mm") }
		}
		case RESET: {
			return initialState
		}
		default: {
			return state
		}
	}
}

export const Context = createContext({
	state: initialState,
	dispatchKont: () => null,
})
export const KontaktyProvider = ({ children, ...rest }) => {
	const [state, dispatchKont] = useReducer(dataReducer, initialState)

	return (
		<Context.Provider value={{ state, dispatchKont }}>
			{children}
		</Context.Provider>
	)
}

export const useKontaktyContext = () => useContext(Context)
