import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from "../../Config";
import { useAppContext, SET_LOADING } from '../../Providers/AppProvider';
export default function TydenniPrehled() {
    const { dispatch, state: { selectedRole, refresh } } = useAppContext()
    const [dochazkaTyden, setDochazkaTyden] = useState({});
    useEffect(() => {
        dispatch({type: SET_LOADING, payload: true})
        axios.get(`${Config.ServerURL}/api/Dochazka/Prehled/Tyden/${selectedRole}`, {
            withCredentials: true
        })
            .then(res => {
                setDochazkaTyden(res.data);
                dispatch({type: SET_LOADING, payload: false})
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRole, refresh]);

    return (
        <>
        {dochazkaTyden &&
            <div className="plocha-prehledy-ramecek">
                <div className="sm:flex sm:items-start">
                    <div className="mt-1 text-center sm:mt-0 sm:text-left">
                        <div className="">
                            <div>
                                {/* Přehled pro zaměstnance */}
                                <h3 className="plocha-prehledy-nadpis">Týdenní přehled: {dochazkaTyden.popisPrehledu}</h3>
                                <table className="mt-2">
                                    <tbody>
                                        <tr>
                                            <td className="pr-2 align-top plocha-prehledy-popis">
                                                {dochazkaTyden.odpracovaneDny !== null &&<div>Odpracované dny:</div>}
                                                {dochazkaTyden.neukoncenePracDny !== null &&<div>Neukončené pracovní dny:</div>}
                                                {dochazkaTyden.neukoncenaPreruseni !== null &&<div>Neukončená přerušení:</div>}
                                                {dochazkaTyden.neschvalenePracDny !== null &&<div>Neschválené pracovní dny:</div>}                                              
                                                {dochazkaTyden.neschvalenaPreruseni !== null &&<div>Neschválená přerušení:</div>} 
                                            </td>
                                            <td className="pr-10 align-top plocha-prehledy-obsah">
                                                {dochazkaTyden.odpracovaneDny !== null &&<div className="text-right">{dochazkaTyden.odpracovaneDny}</div>}
                                                {dochazkaTyden.neukoncenePracDny !== null &&<div className="text-right">{dochazkaTyden.neukoncenePracDny}</div>}
                                                {dochazkaTyden.neukoncenaPreruseni !== null &&<div className="text-right">{dochazkaTyden.neukoncenaPreruseni}</div>}
                                                {dochazkaTyden.neschvalenePracDny !== null &&<div className="text-right">{dochazkaTyden.neschvalenePracDny}</div>}
                                                {dochazkaTyden.neschvalenaPreruseni !== null &&<div className="text-right">{dochazkaTyden.neschvalenaPreruseni}</div>} 
                                            </td>
                                            <td className="pr-2 align-top plocha-prehledy-popis">
                                                {dochazkaTyden.uvazekHod &&<div>Týdenní úvazek:</div>}
                                                {dochazkaTyden.casvPraci &&<div>Čas v práci:</div>}
                                                {dochazkaTyden.odpracovano &&<div>Odpracováno:</div>}
                                                {dochazkaTyden.celkemNahrady &&<div>Náhrady:</div>}
                                                {dochazkaTyden.zbyvaOdpracovat && <div>{dochazkaTyden.popisZbyvaOdpracovat}</div>}
                                            </td>
                                            <td className="pr-10 align-top plocha-prehledy-obsah">
                                                {dochazkaTyden.uvazekHod &&<div className="text-right">{dochazkaTyden.uvazekHod}</div>}
                                                {dochazkaTyden.casvPraci &&<div className="text-right">{dochazkaTyden.casvPraci}</div>}
                                                {dochazkaTyden.odpracovano &&<div className="text-right">{dochazkaTyden.odpracovano}</div>}
                                                {dochazkaTyden.celkemNahrady &&<div className="text-right">{dochazkaTyden.celkemNahrady}</div>}
                                                {dochazkaTyden.zbyvaOdpracovat && <div className="text-right">{dochazkaTyden.zbyvaOdpracovat}</div>}
                                            </td>
                                            <td className="pr-2 align-top plocha-prehledy-popis">
                                                {dochazkaTyden.dovolene > 0 && <div>Dovolená (dny):</div>}
                                                {dochazkaTyden.nemoci > 0 && <div>Nemoc:</div>}
                                                {dochazkaTyden.sluzebniCesty > 0 && <div>Služební cesty:</div>}
                                                {dochazkaTyden.homeOffice > 0 && <div>Home Office:</div>}
                                                {dochazkaTyden.socVolno > 0 && <div>Sociální volno:</div>}
                                                {dochazkaTyden.ostatniVolno > 0 && <div>Ostatní volno:</div>}
                                            </td>
                                            <td className="pr-10 align-top plocha-prehledy-obsah">
                                                {dochazkaTyden.dovolene > 0 && <div className="text-right">{dochazkaTyden.dovolene}</div>}
                                                {dochazkaTyden.nemoci > 0 && <div className="text-right">{dochazkaTyden.nemoci}</div>}
                                                {dochazkaTyden.sluzebniCesty > 0 && <div className="text-right">{dochazkaTyden.sluzebniCesty}</div>}
                                                {dochazkaTyden.homeOffice > 0&& <div className="text-right">{dochazkaTyden.homeOffice}</div>}
                                                {dochazkaTyden.socVolno > 0 && <div className="text-right">{dochazkaTyden.socVolno}</div>}
                                                {dochazkaTyden.ostatniVolno > 0 && <div className="text-right">{dochazkaTyden.ostatniVolno}</div>}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                                {/* Přehled pro zaměstnance - Zobrazení seznamu pracovních dnů pomocí funkce map */}
                                {dochazkaTyden.prehledTyden &&
                                <table className="max-w-5xl mt-5">
                                    <tbody>
                                        <tr>
                                            <th className="pr-5 plocha-prehledy-zahlavi">Den v<br />týdnu</th>
                                            <th className="pr-5 plocha-prehledy-zahlavi">Název <br />události</th>
                                            <th className="pr-5 plocha-prehledy-zahlavi text-right">Čas v <br />práci</th>
                                            <th className="pr-5 plocha-prehledy-zahlavi text-right">Odpra-<br />cováno</th>
                                            <th className="pr-5 plocha-prehledy-zahlavi text-right">Přerušení<br />pracovní</th>
                                            <th className="pr-5 plocha-prehledy-zahlavi text-right">Přerušení<br />nepracovní</th>
                                            <th className="pr-5 plocha-prehledy-zahlavi text-right">Dovolená<br />(hod)</th>
                                        </tr>
                                        {dochazkaTyden.prehledTyden.map((p, index) => 
                                        <tr key={index} className='plocha-prehledy-hover'>
                                            <td className="plocha-prehledy-obsah">{p.popisRadku}</td>
                                            <td className="plocha-prehledy-obsah">{p.nazevHU}</td>
                                            <td className="pr-5 text-right plocha-prehledy-obsah">{p.casvPraci}</td>
                                            <td className="pr-5 text-right plocha-prehledy-obsah">{p.casOdprac}</td>
                                            <td className="pr-5 text-right plocha-prehledy-obsah">{p.casPrPrerus}</td>
                                            <td className="pr-5 text-right plocha-prehledy-obsah">{p.casNePrPrerus}</td>
                                            <td className="pr-5 text-right plocha-prehledy-obsah">{p.casDovolenaPre}</td>
                                        </tr>)}
                                    </tbody>
                                </table>}

                                {/* Přehled pro Vedoucí ... */}
                                {dochazkaTyden.pvZamestInfo &&
                                        (<div>
                                            <div className="pt-1 font-bold"></div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="pr-1 plocha-prehledy-zahlavi">Příjmení</th>
                                                        <th className="pr-1 plocha-prehledy-zahlavi">Jméno</th>
                                                        <th className="pr-1 plocha-prehledy-zahlavi">Odbor</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Odpra-<br />cováno</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Čas v<br /> práci</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Pracovní<br /> přerušení</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Nepracovní<br /> přerušení</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Pracovní<br /> dny</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Dovolená<br /> (dny)</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Dovolená<br /> (hodiny)</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Nemoc</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Služební<br /> cesta</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Home<br /> office</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Sociální<br /> volno</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Ostatní<br /> volno</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Absence</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Neukon.<br />PD</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Neukon.<br />přeruš.</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Neschval.<br />PD</th>
                                                        <th className="pr-1 text-right plocha-prehledy-zahlavi">Neschval.<br />přeruš.</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dochazkaTyden.pvZamestInfo.map((z, index) => (
                                                        <tr key={index} className='plocha-prehledy-hover'>
                                                            <td className="pr-1 plocha-prehledy-obsah">{z.prijmeniZamestnace}</td>
                                                            <td className="pr-1 plocha-prehledy-obsah">{z.jmenoZamestnace}</td>
                                                            <td className="pr-1 plocha-prehledy-obsah">{z.odborZkr}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.casOdprac}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.casvPraci}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.casPrPrerus}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.casNePrPrerus}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.dnyPracDen}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.dnyDovolena}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.casDovolenaPre}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.dnyNemoc}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.dnySluzCesta}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.dnyHomeOffice}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.dnySocVolno}</td>
                                                            <td className="pr-1 text-right plocha-prehledy-obsah">{z.dnyOstatniVolno}</td>
                                                            <td className={`pr-2 text-right plocha-prehledy-obsah ${z.dnyAbsence >= 1 && "doch-udal-absence"}`}>{z.dnyAbsence}</td>
                                                            <td className={`pr-2 text-right plocha-prehledy-obsah ${z.neukoncenePracDny >= 1 && "doch-udal-neukon"}`}>{z.neukoncenePracDny}</td>
                                                            <td className={`pr-2 text-right plocha-prehledy-obsah ${z.neukoncenaPreruseni >= 1 && "doch-udal-neukon"}`}>{z.neukoncenaPreruseni}</td>
                                                            <td className={`pr-2 text-right plocha-prehledy-obsah ${z.neschvalenePracDny >= 1 && "doch-udal-nepotvrzeno"}`}>{z.neschvalenePracDny}</td>
                                                            <td className={`pr-2 text-right  plocha-prehledy-obsah ${z.neschvalenaPreruseni >= 1 && "doch-udal-nepotvrzeno"}`}>{z.neschvalenaPreruseni}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}