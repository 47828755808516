// LA 20.12.22 Nové kontakty - zobrazení ve skupinách dle abecedy

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useAppContext,
  SET_AGENDA,
} from "../../Providers/AppProvider";
import { KontaktyProvider } from "../../Providers/KontaktyProvider";
import AdminKontakty from "./AdminKontakty";
import KontaktyPritomnost from "./KontaktyPritomnost";
import LastUpdateTime from "./LastUpdateTime";
import Legenda from "./Legenda";
import Tabulka from "./Tabulka";

export default function Kontakty() {
  const { state: { selectedRole }, dispatch } = useAppContext();
  const type = useParams()["type"]

  useEffect(() => {
    dispatch({
      type: SET_AGENDA,
      payload: { header: "Kontakty", selectedAgenda: 3 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    selectedRole !== undefined && (
      <KontaktyProvider>
      <div className="kontakty-plocha-hlavni-div">
        {selectedRole === 0 && (
          <div className="sm:flex sm:items-start">
            <div className="mt-3">
              <div className="grid grid-cols-7">
              <div className="flex flex-row gap-x-2 col-span-6 justify-self-start">
                <Link to="/kontakty/seznam" className={`px-4 py-2 transition ${type === "seznam" ? "kontakty-plocha-tlacitko-aktivni " : "kontakty-plocha-tlacitko-neaktivni"} kontakty-plocha-hlavni-tlacitko`}>
                  Kontakty - seznam
                </Link>
                <Link to="/kontakty/abc" className={`px-4 py-2 transition ${type === "abc" ? "kontakty-plocha-tlacitko-aktivni " : "kontakty-plocha-tlacitko-neaktivni"} kontakty-plocha-hlavni-tlacitko`}>
                  Kontakty - skupiny dle abecedy
                </Link>
                <Link to="/kontakty/odbory" className={`px-4 py-2 transition ${type === "odbory" ? "kontakty-plocha-tlacitko-aktivni " : "kontakty-plocha-tlacitko-neaktivni"} kontakty-plocha-hlavni-tlacitko`}>
                  Kontakty - skupiny dle odboru
                </Link>
              </div>
              <div className="justify-self-end kontakty-plocha-aktualizace">
                <div>Aktualizováno</div>
                <div><LastUpdateTime /></div>
              </div>
              </div>
              <div className="kontakty-plocha-seznam">
                {type === "abc" && <Tabulka type="ABC" />}
                {type === "odbory" && <Tabulka type="Odbory" />}
                {type === "seznam" && <KontaktyPritomnost />}
              </div>
              <Legenda />
            </div>
            {/* ----- Zobrazení legendy ----- */}

          </div>)}
        {(selectedRole === 1 || selectedRole === 3) && (
          <AdminKontakty />
        )}
      </div>
      </KontaktyProvider>)
  );
}
