import React, {  createContext, useReducer, useContext } from "react"

export const RESET = "RESET"
export const SET_ZAPIS_DOCHAZKY = "SET_ZAPIS_DOCHAZKY"
export const SET_UDALOSTI = "SET_UDALOSTI"

const initialState = {
	zapisDochazky: [],
	globalniUdalosti: [],
}



const dataReducer = (state, action) => {
	switch (action.type) {
		case SET_ZAPIS_DOCHAZKY: {
			return { ...state, zapisDochazky: action.payload}
		}
		case SET_UDALOSTI: {
			return { ...state, globalniUdalosti: action.payload}
		}
		case RESET: {
			return initialState
		}
		default: {
			return state
		}
	}
}


//const statedData = JSON.parse(localStorage.getItem(STORAGE_KEY));

export const Context = createContext({
	state: initialState,
	dispatchDoch: () => null,
})
export const DochazkaProvider = ({ children, ...rest }) => {
	const [state, dispatchDoch] = useReducer(dataReducer, initialState) // statedData || initialState

	// useEffect(() => {
	// 	localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
	// }, [state])

	return (
		<Context.Provider value={{ state, dispatchDoch }}>
			{children}
		</Context.Provider>
	)
}

export const useDochazkaContext = () => useContext(Context)
