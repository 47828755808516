import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useAppContext,
  SET_LOADING,
  SET_ERROR_ALERT,
} from "../../Providers/AppProvider";
import { Config } from "../../Config";
import OdstraneniRezervace from "./OdstraneniRezervace";
import InformacePotvrzeniRezervace from "./InformacePotvrzeniRezervace";
import PridaniUpravaRezervace from "./PridaniUpravaRezervace";
import KopieRezervace from "./KopieRezervace";
import Napoveda from '../Napoveda';

export default function RezervaceVozidelHistorie({pocetMesicu}) {
  const {
    state: { refresh, selectedRole },
    dispatch,
  } = useAppContext();
  const [rezervace, setRezervace] = useState([]);
  // proměnné pro hledání
  const [search, setSearch] = useState("");
  const [rezervaceZAPI, setRezervaceZAPI] = useState([]);

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    axios
    .get(`${Config.ServerURL}/api/Vozidla/TabulkaRezervaciVozidel/${selectedRole}/0/${pocetMesicu}`, {
      withCredentials: true,
    })
    .then((res) => {
      setRezervaceZAPI(res.data);
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({ type: SET_ERROR_ALERT, payload: err.message });
      dispatch({ type: SET_LOADING, payload: false });
    });

    // eslint-disable-next-line
  }, [refresh, pocetMesicu]);

  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setRezervace(
        rezervaceZAPI.filter(r => r.nazevSkupinyRezervaci?.toLowerCase().includes(search.toLowerCase()) || 
        r.skupinaRezervaci.find(s=>s.jmenoZam?.toLowerCase().includes(search.toLowerCase())) || 
        r.skupinaRezervaci.find(s=>s.cesta?.toLowerCase().includes(search.toLowerCase()))  
      ))
    }
    else {
      setRezervace(rezervaceZAPI)
    }
  }, [rezervaceZAPI, search])

  return (
    <div>
      {/* záhlaví s vyhledáváním a tlačítkem nápovědy */}
      <div className="flex justify-between w-full">
        {/* Pole pro hledání/filtraci a tlačítko pro zrušení*/}
        <div className="vyhledavani-div">
          <input className='vyhledavani-input' 
            value={search} placeholder="Vyhledání rezervací (datum, jméno, cíl) ..." onChange={(e) => setSearch(e.target.value)} />   
          {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
          {search.length >= 2 &&
            <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
              Zrušit hledání
            </div>}
        </div>
            {/* --- Nápověda - pro uživatele --- */}
            {selectedRole === 0 && <Napoveda urlSlug="rezervace-vozidel" />} 
      </div>       
    {/* Zde se definuje min/max velikost tabulky */}
    <div className="vozidla-tabulka-MinMaxVelikost">   

      <table className="table-auto gap-y-3">
        <tbody>
          {rezervace.map((r, index) => (
            <tr className="tabulka-radek-hover" key={index}>
              <td className="vozidla-zobrazeni-NazevSkupRez">
                {r.nazevSkupinyRezervaci}
              </td>
              <td className="flex flex-row gap-x-2 gap-y-2 py-2 flex-wrap">
              {r.skupinaRezervaci.map((s) => (
                    <>
                      {/* Pozn.podmínka pro zobrazení pouze rezervací splňujích vyhledávací kriteria */}
                      {((search.length < 2) || (search.length >= 2 && (r.nazevSkupinyRezervaci?.toLowerCase().includes(search.toLowerCase()) || s.jmenoZam?.toLowerCase().includes(search.toLowerCase()) || s.cesta?.toLowerCase().includes(search.toLowerCase()))))  
                      && (
                        <div key={s.idRezerv} className={`vozidla-zobrazeni-rezervace-historie`} >
                            <div className="flex items-center">
                              {(selectedRole === 3 || selectedRole === 6) &&
                                <div className="mr-2 justify-self-center">
                                    {s.rezEdit &&<div className="my-1"><PridaniUpravaRezervace data={s} novaRezervace={false}/></div>}
                                    {s.rezOdstranit && <div className="my-1"><OdstraneniRezervace idRezervace={s.idRezerv} zamestnanec={s.jmenoZam} nazev={s.cesta} datum={s.txtDatumCasRez}/></div>}
                                    {s.rezInfo && <div className="my-1"><InformacePotvrzeniRezervace data={s} potvrzeni={false}/></div>}
                                    {s.rezPrecistOdp && <div className="my-1"><InformacePotvrzeniRezervace data={s} potvrzeni={true}/></div>}
                                    {s.rezCopy && <div className="my-1"><KopieRezervace data={s}/></div>}
                                </div>}
                              {(selectedRole !== 3 && selectedRole !== 6) &&
                                <div className="mr-2 justify-self-center">
                                    {s.rezInfo && <div className="my-1"><InformacePotvrzeniRezervace data={s} potvrzeni={false}/></div>}
                                </div>}
                                <div className="text-left text-sm">
                                    <div className="font-semibold">{s.txtVozidlo}</div>
                                    <div className="font-semibold">{s.jmenoZam}</div>
                                    <div>{s.cesta}</div>
                                    <div>{s.txtCasRez}</div>
                                </div>
                            </div>
                        </div>
                      )}
                    </>                      
                    ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

  </div>
  </div>
  );
}
