import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  REFRESH_DATA,
} from "../../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../../Config";


// 1. upravit nazev componenty
export default function PridaniUvazkuZam() {
  const { dispatch, state:{refresh} } = useAppContext();
  const [innerProps, setInnerProps] = useState({});
  const [open, setOpen] = useState(false);
  const [zamestnanci, setZamest] = useState([])

  useEffect(() => {
    setInnerProps({});
  }, [open]);

  useEffect(() => {
    if (open === true) {
      axios.get(`${Config.ServerURL}/api/Dochazka/ZamestnanciZapis/2`, {
        withCredentials: true
      })
        .then(res => {
          setZamest(res.data)
        })
    }
  }, [open, refresh]);

  const cancelButtonRef = useRef(null);

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  function Add() {
    axios
      .post(`${Config.ServerURL}/api/Dochazka/PridaniUvazkuZam`, innerProps, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch ({ type: REFRESH_DATA })
        setOpen(false);
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message || "React: Chybná vstupní data!" });
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-4xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                    Přidání Úvazku zaměstnance:
                  </Dialog.Title>
                    <div className="grid grid-cols-7 gap-3">                      
                      
                       {/* --- Výběr zaměstnance --- */}
                       <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          Zaměstnanec
                        </label>
                        <select 
                        value={innerProps.idZam}
                        onChange={(e) =>
                          setInnerProps({
                            ...innerProps,
                            idZam: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                        >
                          {zamestnanci.map(z => <option key={z.idZam} value={z.idZam}>{z.prijmeni} {z.jmeno} {z.titul}</option>)}
                        </select>
                      </div>

                      {/* --- Editace týdenní úvazek --- */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Týdenní úvazek (hod)
                        </label>
                        <input
                          value={innerProps.uvazekTyden}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              uvazekTyden: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace denní úvazek --- */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Denní úvazek (hod)
                        </label>
                        <input
                          value={innerProps.uvazekDen}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              uvazekDen: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace úvazek platí od --- */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Platí Od:
                        </label>
                        <input
                          value={innerProps.platiOd}
                          type="date"
                          onChange={(e) => {
                            dateIsValid(e.target.value) &&
                            setInnerProps({
                              ...innerProps,
                              platiOd: e.target.value,
                            })
                          }}
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* --- Editace úvazek platí do --- */}
                      <div className="col-span-6 sm:col-span-4">
                        <label className="dialog-edit-pole-popis">
                          Platí Do: (pozn. datum zadejte, jen když chcete ukončit platnost úvazku)
                        </label>
                        <input
                          value={innerProps.platiDo}
                          type="date"
                          onChange={(e) => {
                            dateIsValid(e.target.value) &&
                            setInnerProps({
                              ...innerProps,
                              platiDo: e.target.value,
                            })
                          }}
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      {/* Text a oddělovač */}
                      <div className="col-span-6 sm:col-span-7">
                        <label className="dialog-edit-pole-popis">
                        Označte dny, kdy zaměstnanec bude pracovat:
                        </label>
                      </div>

                      {/* Pracuje v Po */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                        Po:
                        </label>
                        <input type="checkbox" checked={innerProps.pracPo}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pracPo: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                      {/* Pracuje v Út */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                        Út:
                        </label>
                        <input type="checkbox" checked={innerProps.pracUt}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pracUt: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                      {/* Pracuje v St */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                        St:
                        </label>
                        <input type="checkbox" checked={innerProps.pracSt}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pracSt: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                      {/* Pracuje v čt */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                        Čt:
                        </label>
                        <input type="checkbox" checked={innerProps.pracCt}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pracCt: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                      {/* Pracuje v Pá */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                        Pá:
                        </label>
                        <input type="checkbox" checked={innerProps.pracPa}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pracPa: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                      {/* Pracuje v So */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                        So:
                        </label>
                        <input type="checkbox" checked={innerProps.pracSo}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pracS0: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                      {/* Pracuje v Ne */}
                      <div className="col-span-6 sm:col-span-1">
                        <label className="dialog-edit-pole-popis">
                        Ne:
                        </label>
                        <input type="checkbox" checked={innerProps.pracNe}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              pracNe: e.target.checked,
                            })
                          }
                          className="admin-dialog-edit-pole-checkbox"
                        ></input>
                      </div>

                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-akce"
                      onClick={() => Add()}
                    >
                      Přidat
                    </button>
                    <button
                      type="button"
                      className="mt-3 admin-dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        onClick={() => setOpen(true)}
        className="admin-dialog-tlacitko-akce"
      >
        Přidat úvazek
      </div>
    </>
  );
}
