import React, { useEffect } from "react";
import { useAppContext, SET_AGENDA } from "../../Providers/AppProvider";
import RezervaceVozidel from "./RezervaceVozidel";
import RezervaceVozidelSPZ from "./RezervaceVozidelSPZ";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import VozidlaAdmin from "./Admin/VozidlaAdmin";
import { VozidlaProvider } from "../../Providers/VozidlaProvider";
import LoadVozidlaData from "./LoadVozidlaData";
import PridaniUpravaRezervace from "./PridaniUpravaRezervace";
import RezervaceVozidelSkupinyCilu from "./RezervaceVozidelSkupinyCilu";
import RezervaceVozidelHistorie from "./RezervaceVozidelHistorie";
import VozidlaDispecer from "./Admin/VozidlaDispecer";

export default function Vozidla() {
  const { dispatch, state: { selectedRole } } = useAppContext();
  const type = useParams()["type"]

  useEffect(() => {
    dispatch({
      type: SET_AGENDA,
      payload: { header: "Služební vozidla", selectedAgenda: 2 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    selectedRole !== undefined && (
    <VozidlaProvider>
      <LoadVozidlaData/>
      <div className="dark:text-gray-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          {(selectedRole !== 3 && selectedRole !== 6) &&
            (<div className="mt-3 w-full">
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row gap-x-2">
                  <Link to="/vozidla/datum" className={`px-4 py-2 transition ${type === "datum" ? "vozidla-plocha-tlacitko-aktivni " : "vozidla-plocha-tlacitko-neaktivni"} vozidla-plocha-hlavni-tlacitko`}>
                    Rezervace dle času
                  </Link>
                  {/* Pro běžné uživatele bloknuto na přání Ivet ... */}
{/*                   <Link to="/vozidla/spz" className={`px-4 py-2 transition ${type === "spz" ? "bg-purple-600 text-white " : "text-purple-700 dark:text-purple-100"} border border-purple-600 rounded-md w-fit cursor-pointer hover:bg-purple-600 shadow-lg hover:text-white shadow-purple-100 dark:shadow-none`}>
                    Rezervace dle vozidel
                  </Link> */}
                  <Link to="/vozidla/skupiny-cilu" className={`px-4 py-2 transition ${type === "skupiny-cilu" ? "vozidla-plocha-tlacitko-aktivni " : "vozidla-plocha-tlacitko-neaktivni"} vozidla-plocha-hlavni-tlacitko`}>
                    Rezervace dle cíle
                  </Link>
                  {/* odebrání odsazení tlačítka historie - ml-10 */}
                  <Link to="/vozidla/historie" className={`px-4 py-2 transition ${type === "historie" ? "vozidla-plocha-tlacitko-aktivni " : "vozidla-plocha-tlacitko-neaktivni"} vozidla-plocha-hlavni-tlacitko`}>
                    Historie rezervací
                  </Link>
                </div>
                {type !== "historie" && <PridaniUpravaRezervace novaRezervace={true} />}
              </div>
              <div className="vozidla-plocha-seznam ">
                {type === "spz" && <RezervaceVozidelSPZ />}
                {type === "datum" && <RezervaceVozidel />}
                {type === "skupiny-cilu" && <RezervaceVozidelSkupinyCilu />}
                {type === "historie" && <RezervaceVozidelHistorie pocetMesicu={6}/>}
              </div>
            </div>)}
          {(selectedRole === 3) &&
          (<div className="mt-3 w-full">
            <VozidlaAdmin />
          </div>)}
          {(selectedRole === 6) &&
          (<div className="mt-3 w-full">
            <VozidlaDispecer />
          </div>)}
        </div>
      </div>
    </VozidlaProvider>)
  );
}
