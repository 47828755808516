import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import { useAppContext } from '../../../Providers/AppProvider';
import PridaniUpravaVozidla from './PridaniUpravaVozidla';
import DeleteVozidlo from './DeleteVozidlo';
import { format } from 'date-fns';
import CheckBox from '../../CheckBox';

export default function VozidlaCiselnik() {
  const { state:{refresh} } = useAppContext();
  const [open, setOpen] = useState(false);
  const [vozidla, setVozidla] = useState([])
  const [aktivniVozidla, setAktivniVozidla] = useState(true)
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open === true) {
      axios.get(`${Config.ServerURL}/api/Vozidla/VozidlaZapis/${aktivniVozidla ? "1" : "0"}`, {
        withCredentials: true
      })
        .then(res => {
          setVozidla(res.data)
        })
    }
  }, [open, refresh, aktivniVozidla]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Úprava číselníku {aktivniVozidla ? "aktivních" : "vyřazených"} vozidel
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id vozidla
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Označení vozidla (typ)
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Spotreba
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Barva
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                SPZ
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Tech. stav
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Poč. míst
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Benzín
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Tech. kontrola
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Příští tech. kont.
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Číslo CCS
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Platnost CCS
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Dálniční známka
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Tažné zařízení
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Pořadi pro SPZ zobrazení
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Nabízet Rezervace
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Nabízet Provoz. náklady
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Vyřazeno
                              </th>
                              <th className='pr-5 tabulka-zahlavi-sticky-admin'>

                              </th>
                            </tr>
                          </thead>
                          <tbody className="tabulka-telo-Ylinka">
                            {vozidla.map((v) => {
                              return (
                                <tr key={v.idVozidla} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{v.idVozidla}</td>
                                  <td className='tabulka-bunka-basic'>{v.typVozidla}</td>
                                  <td className='tabulka-bunka-basic'>{v.spotreba}</td>
                                  <td className='tabulka-bunka-basic'>{v.barva}</td>
                                  <td className='tabulka-bunka-basic'>{v.spz}</td>
                                  <td className='tabulka-bunka-basic'>{v.techStav}</td>
                                  <td className='tabulka-bunka-basic'>{v.maxPocMist}</td>
                                  <td className='tabulka-bunka-basic'>{v.benzin}</td>
                                  <td className='tabulka-bunka-basic'>{v.techKontr && format(new Date(v.techKontr), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic'>{v.pristiTechKontr && format(new Date(v.pristiTechKontr), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic'>{v.cisloCCSKarty}</td>
                                  <td className='tabulka-bunka-basic'>{v.platnostCCS && format(new Date(v.platnostCCS), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={v.dalnicniZnamka}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={v.tazneZarizeni}/></td>
                                  <td className='tabulka-bunka-basic'>{v.poradiZobr}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={v.rezervaceNabizet}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={v.sledovatProvozNaklady}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={v.vyrazeno} /></td>
                                  <td className='flex mt-1 flex-row'>
                                  <PridaniUpravaVozidla noveVozidlo={false} data={v}/>
                                  <DeleteVozidlo idVozidla={v.idVozidla} />
                                    {/* <UpravaOdboru data={o}/>
                                    <DeleteOdbor idOdbor={o.idOdbor}/> */}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>
                    {aktivniVozidla && <PridaniUpravaVozidla noveVozidlo={true}/>}
                      <div className='admin-dialog-tlacitko-prepinac' onClick={() => setAktivniVozidla(!aktivniVozidla)}>{aktivniVozidla ? "Zobrazit vyřazená vozidla" : "Zobrazit aktivní vozidla"}</div>
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
        Vozidla
      </button>
    </>
  )
}