import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Config } from "../../../Config"
import UpravaUvazkuZam from './UpravaUvazkuZam';
import UvazekPrepocetPD from './UvazekPrepocetPD';
import DeleteZaznamu from './DeleteZaznamu';
import PridaniUvazkuZam from './PridaniUvazkuZam';
import CheckBox from '../../CheckBox';
import { format } from 'date-fns';
import { useAppContext } from '../../../Providers/AppProvider';

export default function UvazkyZam() {
  const { dispatch, state: {refresh} } = useAppContext()
  const [open, setOpen] = useState(false)
  const [zamestnanci, setZamestnanci] = useState([])
  const cancelButtonRef = useRef(null);
  // pro hledání:
  const [search, setSearch] = useState("");
  const [zamestZAPI, setZamestZAPI] = useState([])

  useEffect(() => {
    if (open === true) {
      dispatch({ type: 'SET_LOADING', payload: true })
      axios.get(`${Config.ServerURL}/api/Dochazka/ZamestUvazekZapis`, {
        withCredentials: true
      })
        .then(res => {
          setZamestZAPI(res.data)
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING', payload: false })
        })
    }
    // eslint-disable-next-line
  }, [open, refresh]);

  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setZamestnanci(zamestZAPI.filter(z => z.prijmeni?.toLowerCase().startsWith(search.toLowerCase()) || z.jmeno?.toLowerCase().startsWith(search.toLowerCase()) || 
      z.odborZkr?.toLowerCase().startsWith(search.toLowerCase())  
      ))
    }
    else {
      setZamestnanci(zamestZAPI)
    }
  }, [zamestZAPI, search])

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Pracovní úvazky zaměstnanců
                        </div>
                      </Dialog.Title>

                        {/* Pole pro hledání/filtraci a tlačítko pro zrušení*/}
                        <div className="vyhledavani-div mt-2">
                          <input className='vyhledavani-input' 
                            value={search} placeholder="Vyhledání úvazků (jméno, odbor) ..." onChange={(e) => setSearch(e.target.value)} />   
                          {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
                          {search.length >= 2 &&
                            <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
                              Zrušit hledání
                            </div>}
                        </div>

                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>

                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id Úvazku
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id zaměstnance
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Příjmení 
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Jméno
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Titul
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Odbor
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                               Týdenní úvazek
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Denní úvazek
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Platnost Od
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Platnost Do
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Po
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Út
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                St
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Čt
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Pá
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                So
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Ne
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Editace
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Přepočet PD
                              </th>
                            </tr>
                          </thead>

                         <tbody className="tabulka-telo-Ylinka">
                            {zamestnanci.map((u, index) => {
                              return (
                                <tr key={index}  className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{u.idUvazek}</td>
                                  <td className='tabulka-bunka-basic'>{u.idZam}</td>
                                  <td className='tabulka-bunka-basic'>{u.prijmeni}</td>
                                  <td className='tabulka-bunka-basic'>{u.jmeno}</td>
                                  <td className='tabulka-bunka-basic'>{u.titul}</td>
                                  <td className='tabulka-bunka-basic'>{u.odborZkr}</td>
                                  <td className='tabulka-bunka-basic'>{u.uvazekTyden}</td>
                                  <td className='tabulka-bunka-basic'>{u.uvazekDen}</td>
                                  <td className='tabulka-bunka-basic'>{format(new Date(u.platiOd), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic'>{u.platiDo != null && format(new Date(u.platiDo), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={u.pracPo}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={u.pracUt}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={u.pracSt}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={u.pracCt}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={u.pracPa}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={u.pracSo}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={u.pracNe}/></td>
                                  <td className='flex mt-1 flex-row'>
                                    <UpravaUvazkuZam data={u} /> 
                                    <DeleteZaznamu urlAPI="/api/Dochazka/OdstraneniUvazkuZam/" idZaznamu={u.idUvazek} 
                                                  titulek={"Zaměstnanec: " + u.prijmeni + " " + u.jmeno}
                                                  dotaz={"Přejete si odstranit úvazek uvedeného zaměstnance?"}/> 
                                  </td>
                                  <td className=''>
                                  <UvazekPrepocetPD idZaznamu={u.idUvazek} 
                                                titulek1={"Zaměstnannec: " + u.prijmeni + " " + u.jmeno} 
                                                titulek2={"Platnost Od: " + format(new Date(u.platiOd), "dd.MM.yyyy")} 
                                                titulek3={"Týdenní úvazek: " + u.uvazekTyden} /> 
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                  <div className='flex flex-row gap-2 flex-wrap justify-center'>  <PridaniUvazkuZam/> 
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
        Pracovní Úvazky zaměstnanců
      </button>
    </>
  )
}