import React, { useEffect, useState } from 'react';
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header";
import InformationAlert from './InformationAlert';
import Error from "./Error";
import Loader from './Loader';
import { SET_AUTHENTICATED, SET_ERROR_ALERT, SET_LOADING, useAppContext } from '../Providers/AppProvider';
import axios from 'axios';
import { Config } from '../Config';
import { useLocation } from 'react-router';
// import PlanovaneSpusteni from "./PlanovaneSpusteni";

export default function Layout({ children }) {
    const location = useLocation()
    const hide = location.pathname.includes('navod')
    const { dispatch, state:{refreshWithoutLoader, loading, authenticated, refresh} } = useAppContext()
    const [l, setLoading] = useState(true)
    const [load, setLoad] = useState(true)
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        if (loading === true && refreshWithoutLoader === false) {
            setLoad(true)
        }
        if (loading === false) {
            sleep(100).then(() => {
                if (loading === false) {
                    setLoad(false)
                }
              })
        }
        // eslint-disable-next-line
    }, [loading])

    useEffect(() => {
        dispatch({ type: SET_LOADING, payload: true })
        axios
        .get(`${Config.ServerURL}/api/Auth/JmenoPrijmeni`, {
          withCredentials: true,
        })
        .then(() => {
          dispatch({ type: SET_AUTHENTICATED, payload: true })
          setLoading(false)
          sleep(1000).then(() => {
            dispatch({ type: SET_LOADING, payload: false })
          })
        })
        .catch((res) => {
            dispatch({ type: SET_ERROR_ALERT, payload: "Chyba připojení k serveru, kontaktujte správce. Chybová hláška: "+ res.message +" (" + res.code +")" })
            setLoading(false)
            sleep(1000).then(() => {
                dispatch({ type: SET_LOADING, payload: false })
              })
        });
        // eslint-disable-next-line
    }, [refresh])

    //return (<PlanovaneSpusteni />)

    if (hide) return (<div className='w-full min-h-screen bg-gray-100 dark:bg-gray-900'>{children}</div>)
    
    return (
        <>
            <InformationAlert />
            <Error />
            {load && <Loader/>}
            <div className={`flex h-screen bg-gray-50 dark:bg-gray-900`}>
                <Sidebar></Sidebar>
                <div className="flex flex-col flex-1 w-full overflow-auto">
                    <Header></Header>
                    <main className="max-h-full overflow-auto">
                        <div className="container grid px-6 mx-auto my-4">
                            {authenticated ? children : !l && <div className='dark:text-gray-200 text-lg text-center font-bold'>Nejste přihlášen</div>}
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}