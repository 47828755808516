import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useAppContext,
  SET_LOADING,
  SET_ERROR_ALERT,
} from "../../Providers/AppProvider";
import { Config } from "../../Config";
import OdstraneniRezervace from "./OdstraneniRezervace";
import InformacePotvrzeniRezervace from "./InformacePotvrzeniRezervace";
import PridaniUpravaRezervace from "./PridaniUpravaRezervace";
import KopieRezervace from "./KopieRezervace";
import UzivatelKontakt from "../Kontakty/UzivatelKontakt";

export default function RezervaceVozidelSPZ() {
  const {
    state: { refresh, selectedRole },
    dispatch,
  } = useAppContext();
  const [rezervace, setRezervace] = useState([]);
  const [vozidlaData, setVozidlaData] = useState([]);
  // proměnné pro hledání
  const [search, setSearch] = useState("");
  const [rezervaceZAPI, setRezervaceZAPI] = useState([]);

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    axios
      .get(`${Config.ServerURL}/api/Vozidla`, {
        withCredentials: true,
      })
      .then((res) => {
        var vozidlazAPI = res.data;
        axios
          .get(`${Config.ServerURL}/api/Vozidla/TabulkaRezervaciVozidel/${selectedRole}/0/0`, {
            withCredentials: true,
          })
          .then((res) => {
            setRezervaceZAPI(res.data);
            var vozidla = [{ idVozidla: null, typVozidla: "", spz: "Nepřiřazené vozidlo" }];
            vozidlazAPI.filter((v) => v.poradiZobr !== null).forEach((v) => { vozidla.push({ idVozidla: v.idVozidla, typVozidla: v.typVozidla, spz: v.spz }) });
            res.data.forEach((d) => {
              d.skupinaRezervaci.forEach((e) => {
                var v = vozidla.find((v) => v.idVozidla === e.idVozidla);
                if (v === undefined) {
                  var vozidlozAPI = vozidlazAPI.find(
                    (v) => v.idVozidla === e.idVozidla
                  );
                  vozidla.push({
                    idVozidla: e.idVozidla,
                    typVozidla: vozidlozAPI.typVozidla,
                    spz: vozidlozAPI.spz,
                  });
                }
              });
            });
            setVozidlaData(vozidla);
            dispatch({ type: SET_LOADING, payload: false });
          })
          .catch((err) => {
            dispatch({ type: SET_ERROR_ALERT, payload: err.message });
            dispatch({ type: SET_LOADING, payload: false });
          });
      });

    // eslint-disable-next-line
  }, [refresh]);

  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setRezervace(
        rezervaceZAPI.filter(r => r.nazevSkupinyRezervaci?.toLowerCase().includes(search.toLowerCase()) ||
          r.skupinaRezervaci.find(s => s.jmenoZam?.toLowerCase().includes(search.toLowerCase())) ||
          r.skupinaRezervaci.find(s => s.cesta?.toLowerCase().includes(search.toLowerCase()))
        ))
    }
    else {
      setRezervace(rezervaceZAPI)
    }
  }, [rezervaceZAPI, search])

  return (
    <div>
      {/* Pole pro hledání/filtraci a tlačítko pro zrušení*/}
      <div className="vyhledavani-div">
        <input className='vyhledavani-input' 
          value={search} placeholder="Vyhledání rezervací (datum, jméno, cíl) ..." onChange={(e) => setSearch(e.target.value)} />   
        {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
        {search.length >= 2 &&
          <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
            Zrušit hledání
          </div>}
      </div>

      {/* Zde se definuje min/max velikost tabulky */}
      <div className="vozidla-tabulka-MinMaxVelikost">

        <table className="table-auto">
          <thead>
            <tr>
              <th className="vozidla-tabulka-zahlavi-sticky"></th>
              {vozidlaData.map((v) => (
                <th className="vozidla-tabulka-zahlavi-sticky pl-2 pr-2 text-center text-sm" key={v.idVozidla}>
                  {v.typVozidla}
                  <br />
                  {v.spz}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rezervace.map((r, index) => (
              <tr className="vozidla-tabulka-radek tabulka-radek-hover" key={index}>
                <td className="vozidla-zobrazeni-NazevSkupRez">
                  {r.nazevSkupinyRezervaci}
                </td>
                {vozidlaData.map((v, index) => (
                  <td key={index} className="text-center align-top px-1">
                    {r.skupinaRezervaci.filter((r) => r.idVozidla === v.idVozidla).count !== 0 ? (
                      <>
                        {/* skryté zobrazení popisu sloupce v každém řádku*/}
                        {/* <div className="text-center font-normal text-sm text-white hover:text-black dark:text-gray-800 dark:hover:text-gray-200">{v.typVozidla.length !== 0 ? v.typVozidla : "Nepřiřazené vozidlo"}</div> */}

                        {r.skupinaRezervaci.filter((r) => r.idVozidla === v.idVozidla).map((s) => (

                          <>
                            {/* Pozn.podmínka pro zobrazení pouze rezervací splňujích vyhledávací kriteria */}
                            {((search.length < 2) || (search.length >= 2 && (r.nazevSkupinyRezervaci?.toLowerCase().includes(search.toLowerCase()) || s.jmenoZam?.toLowerCase().includes(search.toLowerCase()) || s.cesta?.toLowerCase().includes(search.toLowerCase())))) &&
                              (

                                <div className={`vozidla-zobrazeni-rezervace-cileSPZ${" "}
                                  ${s.rezColor === 1 && "vozidla-barva-rez-RC1 "} 
                                  ${s.rezColor === 2 && "vozidla-barva-rez-RC2 "} 
                                  ${s.rezColor === 3 && "vozidla-barva-rez-RC3 "} 
                                  ${s.rezColor === 4 && "vozidla-barva-rez-RC4 "} 
                                  ${s.rezColor === 5 && "vozidla-barva-rez-RC5 "} 
                                  ${s.rezColor === 9 && "vozidla-barva-rez-RC9 "} 
                                  ${s.vedouci && " font-bold"}`}
                                  key={s.idRezerv}>

                                  <div className="mr-2 justify-self-center">
                                    {s.rezEdit && <div className="my-1"><PridaniUpravaRezervace data={s} novaRezervace={false} /></div>}
                                    {s.rezOdstranit && <div className="my-1"><OdstraneniRezervace idRezervace={s.idRezerv} zamestnanec={s.jmenoZam} nazev={s.cesta} datum={s.txtDatumCasRez} /></div>}
                                    {s.rezInfo && <div className="my-1"><InformacePotvrzeniRezervace data={s} potvrzeni={false} /></div>}
                                    {s.rezPrecistOdp && <div className="my-1"><InformacePotvrzeniRezervace data={s} potvrzeni={true} /></div>}
                                    {s.rezCopy && <div className="my-1"><KopieRezervace data={s} /></div>}
                                  </div>
                                  <div className="text-left text-sm">
                                    <div className="font-semibold flex"><span>{s.jmenoZam}</span>
                                      {/* Podmíněné zobrazení tlačítka Kontakt */}
                                      {s.rezKontakt && <UzivatelKontakt vozidla={true} kontaktData={s.kontakt} />}
                                    </div>
                                    <div>{s.cesta}</div>
                                    <div>{s.txtCasRez}</div>
                                  </div>
                                </div>
                              )}
                          </>
                        ))}
                      </>
                    ) : (<></>)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
