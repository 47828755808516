import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  UPDATE_ADMIN,
} from "../Providers/AppProvider";
import axios from "axios";
import { Config } from "../Config";

export default function GlobalniParametry() {
  const { dispatch, state: { selectedAgenda } } = useAppContext();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  // function dateIsValid(date) {
  //   return !Number.isNaN(new Date(date).getTime());
  // }

  useEffect(() => {
    axios.get(`${Config.ServerURL}/api/App/GlobParams/${selectedAgenda}`, {withCredentials: true}).then((res) => {
      setData(res.data)
    })
  }, [selectedAgenda])

  const cancelButtonRef = useRef(null);

  function Upravit() {
    dispatch({ type: SET_LOADING, payload: true })

    axios
      .post(
        `${Config.ServerURL}/api/App/UpravaGlobParam/${selectedAgenda}`,
        data,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        //DO: refresh rezervaci
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        setOpen(false);
        dispatch({ type: UPDATE_ADMIN })
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-3xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                    Oprava globálních parametrů 
                    {selectedAgenda === 1 && " Docházky"}
                    {selectedAgenda === 2 && " Vozidel"}
                    {selectedAgenda === 3 && " Kontaktů"}
                  </Dialog.Title>

                    <div className="grid grid-cols-6 gap-3">
                      {/* ----- Editace parametrů Docházky ----- */}
                      {selectedAgenda === 1 && (<>
                        <div className="col-span-6 sm:col-span-6">
                          <label className="dialog-edit-pole-popis">
                            Název organizace
                          </label>
                          <input
                            value={data.nazevorg || ""}
                            onChange={(e) =>
                              setData({
                                ...data,
                                nazevorg: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label className="dialog-edit-pole-popis">
                            ID události "Pracovní den"
                          </label>
                          <input
                            value={data.idPd || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                idPd: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label className="dialog-edit-pole-popis">
                            ID události "Oběd"
                          </label>
                          <input
                            value={data.idObed || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                idObed: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label className="dialog-edit-pole-popis">
                            Délka oběda (min)
                          </label>
                          <input
                            value={data.delObed || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                delObed: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        {/* LA 3.3.23 Přidané položky ...  */}
                        <div className="col-span-6 sm:col-span-2">
                          <label className="dialog-edit-pole-popis">
                            ID události "Dovolená - celý den"
                          </label>
                          <input
                            value={data.idDovolenaHU || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                idDovolenaHU: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label className="dialog-edit-pole-popis">
                            ID události "Dovolená - přerušení"
                          </label>
                          <input
                            value={data.idDovolenaPre || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                idDovolenaPre: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-2">
                          <label className="dialog-edit-pole-popis">
                          ID události "Soc. volno"
                          </label>
                          <input
                            value={data.idSocVolno || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                idSocVolno: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label className="dialog-edit-pole-popis">
                            Korekce začátku prac. doby (min)
                          </label>
                          <input
                            value={data.korekceZacatek || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                korekceZacatek: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label className="dialog-edit-pole-popis">
                            Korekce konce prac. doby (min)
                          </label>
                          <input
                            value={data.korekceKonec || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                korekceKonec: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label className="dialog-edit-pole-popis">
                            Minimální začátek pracovního dne (hh:mm)
                          </label>
                          <input
                            value={data.minZacPdGt || 0}
                            type="time"
                            onChange={(e) =>
                              setData({
                                ...data,
                                minZacPdGt: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label className="dialog-edit-pole-popis">
                            Čas pro automatický refresh (s)
                          </label>
                          <input
                            value={data.refreshInt || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                refreshInt: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                      </>)}
                      {/* ----- Editace parametrů Rezervací Vozidel ----- */}
                      {selectedAgenda === 2 && (<>
                        <div className="col-span-6 sm:col-span-3">
                          <label className="dialog-edit-pole-popis">
                            Minimální délka rezervace (min)
                          </label>
                          <input
                            value={data.rvMinDelkaRezervace || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                rvMinDelkaRezervace: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label className="dialog-edit-pole-popis">
                            Maximální délka rezervace (hod)
                          </label>
                          <input
                            value={data.rvMaxDelkaRezervace || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                rvMaxDelkaRezervace: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-6">
                          <label className="dialog-edit-pole-popis">
                            Minimální posun začátku rezervace od aktuálního času (min), tj. kdy nejdříve může rezervace začínat
                          </label>
                          <input
                            value={data.rvMinPosunZacRez || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                rvMinPosunZacRez: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                        <div className="col-span-6 sm:col-span-6">
                          <label className="dialog-edit-pole-popis">
                            Min. počet dnů pro výběr rezervací
                          </label>
                          <input
                            value={data.rvPocDnuEdit || 0}
                            type="number"
                            onChange={(e) =>
                              setData({
                                ...data,
                                rvPocDnuEdit: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>
                      </>)}
                      {/* ----- Editace parametrů Kontakty ----- */}
                      {selectedAgenda === 3 &&
                        (<div className="col-span-6 sm:col-span-6">
                          <label className="dialog-edit-pole-popis">
                            Předvolba tel. ústředny (pro separaci pobočkových linek)
                          </label>
                          <input
                            value={data.tsUstredna || ""}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tsUstredna: e.target.value,
                              })
                            }
                            className="admin-dialog-edit-pole-input"
                          ></input>
                        </div>)}
                    </div>
                  </div>

                  {/* Tlačítka */}
                  <div className="dialog-edit-zapati-pozadi">
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-akce"
                      onClick={() => Upravit()}
                    >
                      Uložit
                    </button>
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
        Globální parametry
      </button>
    </>
  );
}
