import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { useAppContext, SET_ERROR_ALERT } from '../Providers/AppProvider';

export default function Error() {
  const { dispatch, state:{errorAlert} } = useAppContext()
  const [open, setOpen] = useState(true)
  const [message, setMessage] = useState("")

  const cancelButtonRef = useRef(null)
  useEffect(() => {
      if (errorAlert) {
          setMessage(errorAlert)
          setOpen(true)
      }
      else {
          setOpen(false)
      }
  }, [errorAlert])

    return(
        <>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={() => dispatch({ type: SET_ERROR_ALERT, payload: null })}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="dialog-transition" />
            </Transition.Child>
    
            <div className="fixed inset-0 z-30 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="dialog-dotaz-ramecek">
                    <div className="dialog-dotaz-obsah">
                      <div className="sm:flex sm:items-start">
                        <div className="dialog-dotaz-ikona-pozadi-cervenykruh">
                          <ExclamationTriangleIcon className="dialog-dotaz-ikona-cervena" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="dialog-dotaz-nadpis-cerveny">
                            Chybné zadání!
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="dialog-dotaz-otazka">
                              {message}
                            </p>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="dialog-dotaz-zapati-pozadi">
                      {/* <button type="button" className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" 
                      onClick={() => DeleteDoch()}>Odstranit</button> */}
                      <button
                        type="button"
                        className="dialog-tlacitko-dotaz-zrusit"
                        onClick={() => dispatch({ type: SET_ERROR_ALERT, payload: null })}
                        ref={cancelButtonRef}
                        >
                        Zpět
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        </>
    )
}