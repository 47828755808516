import React, { useState, useEffect } from "react";
import axios from "axios";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Config } from "../../Config";
import { SET_LOADING, useAppContext } from "../../Providers/AppProvider";
import MesicniPrehledPodrobny from "./MesicniPrehledPodrobny";
import { Tooltip } from "react-tooltip";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";


export default function MesicniPrehled() {
  const {
    dispatch,
    state: { selectedRole },
  } = useAppContext();
  const [open, setOpen] = useState(false);
  const [dochazkaMesic, setDochazkaMesic] = useState([]);
  const [rok, setRok] = useState(new Date().getFullYear());
  const [mesic, setMesic] = useState(new Date().getMonth() + 1);

  const cancelButtonRef = useRef(null);

  function GetData(withLoader) {
    if (withLoader === true) {
      dispatch({ type: SET_LOADING, payload: true });
    }
    axios
      .get(
        `${Config.ServerURL}/api/Dochazka/Prehled/Mesic/${selectedRole}/${rok}/${mesic}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setDochazkaMesic(res.data);
        if (withLoader === true) {
          dispatch({ type: SET_LOADING, payload: false });
        }
      });
  }

  useEffect(() => {
    if (open === true) {
      GetData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedRole]);

  useEffect(() => {
    if (open === true) {
      GetData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mesic]);

  function PredchoziMesic() {
    if (mesic > 1) {
      setMesic(mesic - 1);
    } else {
      setRok(rok - 1);
      setMesic(12);
    }
  }

  function DalsiMesic() {
    if (mesic < 12) {
      setMesic(mesic + 1);
    } else {
      setRok(rok + 1);
      setMesic(1);
    }
  }

  // LA 24.4.23 - ? proč se musí přičíst 1 ? nemůže být měsíc č. 13 ? (vrací v prosinci číslo 11?)
  function AktualniMesic() {  
    setRok(new Date().getFullYear());
    setMesic(new Date().getMonth()+1);    
  }

 // console.log(dochazkaMesic)

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full  w-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek">
                  <div className="dialog-obsah">
                    <div className="sm:flex sm:items-start">
                      <div className="dialog-obsah-umisteni2">
                        <Dialog.Title
                          as="h3"
                          className="dialog-nadpis"
                        >
                          Měsíční přehled - {mesic}/{rok}
                        </Dialog.Title>
                        {/* ----- Přehled pro zaměstnance ----- */}
                        {/* LA 15.3.23 upravena podmínka - přidána role  */}
                        {(dochazkaMesic.pracovniDny !== null && selectedRole === 0) && (
                                                          <table className="mt-2">
                                                          <tbody>
                                                              <tr>
                                                                <td className="dialog-prehledy-popisB">Informace</td>
                                                                <td></td>
                                                                <td className="dialog-prehledy-popisB">Problémy </td>
                                                                <td></td>
                                                                <td className="dialog-prehledy-popisB">Časové události</td>
                                                                <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="pr-2 align-top dialog-prehledy-obsah">
                                                                    <div>Pracovní dny v měsíci:</div>
                                                                    <div>Placené svatky:</div>
                                                                    <div>Odpracované dny:</div>
                                                                    <div>Bez stravenky:</div>
                                                                    <div>Se stravenkou:</div>
                                                                  </td>
                                                                  <td className="pr-10 align-top dialog-prehledy-obsah">
                                                                    <div className="text-right">{dochazkaMesic.pracovniDnyUZ}</div>
                                                                    <div className="text-right">{dochazkaMesic.dnySvatky}</div>
                                                                    <div className="text-right">{dochazkaMesic.odpracovaneDny}</div>
                                                                    <div className="text-right">{dochazkaMesic.preruseniBezStravenky}</div>
                                                                    <div className="text-right">{dochazkaMesic.preruseniSeStravenkou}</div>
                                                                  </td>
                                                                  <td className="pr-2 align-top dialog-prehledy-obsah">
                                                                    <div className={dochazkaMesic.dnyAbsence >= 1 ? "doch-udal-absence" : undefined}>Absence:</div>
                                                                    <div className={dochazkaMesic.neukoncenePracDny >= 1 ? "doch-udal-neukon" : undefined}>Neukončené PD:</div>
                                                                    <div className={dochazkaMesic.neukoncenaPreruseni >= 1 ? "doch-udal-neukon" : undefined}>Neukončená přerušení:</div>
                                                                    <div className={dochazkaMesic.neschvalenePracDny >= 1 ? "doch-udal-nepotvrzeno" : undefined}>Neschválené PD:</div>
                                                                    <div className={dochazkaMesic.neschvalenaPreruseni >= 1 ? "doch-udal-nepotvrzeno" : undefined}>Neschvalená přerušení:</div>
                                                                  </td>
                                                                  <td className="pr-10 align-top dialog-prehledy-obsah">
                                                                    <div className={`text-right ${dochazkaMesic.dnyAbsence >= 1 ? "doch-udal-absence" : undefined}`}>{dochazkaMesic.dnyAbsence}</div>
                                                                    <div className={`text-right ${dochazkaMesic.neukoncenePracDny >= 1 ? "doch-udal-neukon" : undefined}`}>{dochazkaMesic.neukoncenePracDny}</div>
                                                                    <div className={`text-right ${dochazkaMesic.neukoncenaPreruseni >= 1 ? "doch-udal-neukon" : undefined}`}>{dochazkaMesic.neukoncenaPreruseni}</div>  
                                                                    <div className={`text-right ${dochazkaMesic.neschvalenePracDny >= 1 ? "doch-udal-nepotvrzeno" : undefined}`}>{dochazkaMesic.neschvalenePracDny}</div>
                                                                    <div className={`text-right ${dochazkaMesic.neschvalenaPreruseni >= 1 ? "doch-udal-nepotvrzeno" : undefined}`}>{dochazkaMesic.neschvalenaPreruseni}</div>  
                                                                  </td>
                                                                  <td className="pr-2 align-top dialog-prehledy-obsah">
                                                                    <div>Čas v práci:</div>
                                                                    <div>Odpracováno:</div>
                                                                    <div>Přerušení pracovní:</div>
                                                                    <div>Přerušení nepracovní:</div>
                                                                    <div>Dovolená (přerušení):</div>
                                                                  </td>
                                                                  <td className="pr-10 align-top dialog-prehledy-obsah">
                                                                    <div className="text-right">{dochazkaMesic.casvPraci}</div>
                                                                    <div className="text-right">{dochazkaMesic.odpracovano}</div>
                                                                    <div className="text-right">{dochazkaMesic.preruseniPracovni}</div>
                                                                    <div className="text-right">{dochazkaMesic.preruseniNepracovni}</div>
                                                                    <div className="text-right">{dochazkaMesic.casDovolenaPre}</div>
                                                                  </td>

                                                              </tr>
                                                              {/* Druhá řada informací */}
                                                              {/* <tr><td>{" "}</td><td>{" "}</td><td>{" "}</td></tr> */}
                                                              <tr> 
                                                                <td className="dialog-prehledy-popisB"><br />Denní události</td>
                                                                <td></td>
                                                                <td className="dialog-prehledy-popisB"><br />Náhrady</td>
                                                                <td></td>
                                                                <td className="dialog-prehledy-popisB"><br />Vyhodnocení prac. doby</td>
                                                                <td></td>
                                                              </tr>
                                                              <tr>
                                                                <td className="pr-2 align-top dialog-prehledy-obsah">
                                                                    <div>Dovolená:</div>                                                                  
                                                                    <div>Soc. volno:</div>
                                                                    <div>Ostatní volno:</div>
                                                                    <div>Home office:</div>                                                                  
                                                                    <div>Služební cesty:</div>
                                                                    <div>Nemoc:</div>
                                                                  </td>
                                                                  <td className="pr-10 align-top dialog-prehledy-obsah">
                                                                    <div className="text-right">{dochazkaMesic.dovolene}</div>                                                                  
                                                                    <div className="text-right">{dochazkaMesic.socVolno}</div>
                                                                    <div className="text-right">{dochazkaMesic.ostatniVolno}</div>
                                                                    <div className="text-right">{dochazkaMesic.homeOffice}</div>                                                                  
                                                                    <div className="text-right">{dochazkaMesic.sluzebniCesty}</div>
                                                                    <div className="text-right">{dochazkaMesic.nemoci}</div>
                                                                  </td>
                                                                  <td className="pr-2 align-top dialog-prehledy-obsah">
                                                                    <div>Dovolená:</div>
                                                                    <div>Soc.volno:</div>
                                                                    <div>Ostatní volno:</div>
                                                                    <div>Home Office:</div>
                                                                    <div>Služební cesty:</div>
                                                                    <div>Nemoc:</div>
                                                                    {/* <div>Placené svátky:</div> */}
                                                                    <div>Celkem náhrady:</div>
                                                                  </td>
                                                                  <td className="pr-10 align-top dialog-prehledy-obsah">
                                                                    <div className="text-right">{dochazkaMesic.dovoleneUvazHod}</div>
                                                                    <div className="text-right">{dochazkaMesic.socVolnoUvazHod}</div>
                                                                    <div className="text-right">{dochazkaMesic.ostatniVolnoUvazHod}</div>
                                                                    <div className="text-right">{dochazkaMesic.homeOfficeUvazHod}</div>
                                                                    <div className="text-right">{dochazkaMesic.sluzebniCestyUvazHod}</div>
                                                                    <div className="text-right">{dochazkaMesic.nemociUvazHod}</div>
                                                                    {/* <div className="text-right">{dochazkaMesic.svatkyUvazHod}</div> */}
                                                                    <div className="text-right">{dochazkaMesic.celkemNahrady}</div>
                                                                  </td>
                                                                  <td className="pr-10 align-top dialog-prehledy-obsah">
                                                                    <div>Denní úvazek:</div>
                                                                    <div>Tydenní úvazek:</div>
                                                                    <div>Měsíční úvazek:</div>
                                                                    {/* <div>(+) Placené svátky:</div> */}
                                                                    <div>(-) Odpracováno:</div>
                                                                    <div>(-) Celkem náhrady:</div>
                                                                    <div>{dochazkaMesic.popisZbyvaOdpracovat}</div>
                                                                  </td>
                                                                  <td className="pr-10 align-top dialog-prehledy-obsah">
                                                                    <div className="text-right">{dochazkaMesic.infoDenniUvazekHod}</div>
                                                                    <div className="text-right">{dochazkaMesic.infoTydenniUvazekHod}</div>
                                                                    <div className="text-right">{dochazkaMesic.uvazekHod}</div>
                                                                    {/* <div className="text-right">{dochazkaMesic.svatkyUvazHod}</div> */}
                                                                    <div className="text-right">{dochazkaMesic.odpracovano}</div>
                                                                    <div className="text-right">{dochazkaMesic.celkemNahrady}</div>
                                                                    <div className="text-right">{dochazkaMesic.zbyvaOdpracovat}</div>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                        )}
                        {/* ----- Přehled pro vedoucí, tajemníka ----- */}
                        {/* LA 15.3.23 - úprava podmínky - přidány role */}
                        <div className="mt-2">
                          {(dochazkaMesic.pvZamestInfo && (selectedRole === 1 || selectedRole === 2 || selectedRole === 5)) && (
                            <div className="tabulka-MinMaxVelikost">                            
                              <table>
                                <thead>
                                  <tr>
                                    <th className="pr-2 dialog-prehledy-zahlavi">PMP</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Příjmení</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Jméno</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Odbor</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Odpracováno</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Čas v <br />práci</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Pracovní<br />přerušení</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Nepracovní<br />přerušení</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Prac.<br />dny</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br />(dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br />(hodiny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Nemoc</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Služební<br />cesta</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Home<br />office</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Sociální<br />volno</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Ostatní<br />volno</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Absence</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />PD</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />přerušení</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neschval.<br />PD</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neschval.<br />přerušení</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dochazkaMesic.pvZamestInfo.map(
                                    (z, index) => (
                                      <tr key={index} className="tabulka-radek-hover">
                                        <th className="pr-2">
                                        <div data-tooltip-id="MesicniPrehledPodrobny-tooltip" data-tooltip-content="Podrobný měsíční přehled docházky zaměstnance"
                                            data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" >
                                            <MesicniPrehledPodrobny
                                              zamestnanec={z}
                                              rok={rok}
                                              mesic={mesic}
                                              role={selectedRole}
                                            />
                                            <Tooltip id="MesicniPrehledPodrobny-tooltip" />
                                          </div>
                                        </th>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.prijmeniZamestnace}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.jmenoZamestnace}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.odborZkr}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casOdprac}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casvPraci}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casPrPrerus}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casNePrPrerus}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyPracDen}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyDovolena}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casDovolenaPre}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyNemoc}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnySluzCesta}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyHomeOffice}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnySocVolno}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyOstatniVolno}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.dnyAbsence >= 1 && "doch-udal-absence"}`}>{z.dnyAbsence}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenePracDny >= 1 && "doch-udal-neukon"}`}>{z.neukoncenePracDny}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenaPreruseni >= 1 && "doch-udal-neukon"}`}>{z.neukoncenaPreruseni}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neschvalenePracDny >= 1 && "doch-udal-nepotvrzeno"}`}>{z.neschvalenePracDny}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neschvalenaPreruseni >= 1 && "doch-udal-nepotvrzeno"}`}>{z.neschvalenaPreruseni}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {/* ----- Přehled pro Personalistu ----- */}
                          {/* LA 15.3.23 - pouze údaje pro mzdy a personalistiku (jako přehled Mzdy ve staré verzi docházky)  */}
                          {(dochazkaMesic.pvZamestInfo && (selectedRole === 4 )) && (
                            <div className="tabulka-MinMaxVelikost">
                              <table>
                                <thead>
                                  <tr>
                                    <th className="pr-2 dialog-prehledy-zahlavi">PMP</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Příjmení</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Jméno</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Odbor</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Úvazek<br/>(den/týden  )</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Odprac.<br/>dny</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br/>(dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br/>(hodiny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Soc.volno<br/>(dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Ostatní volno<br/>(dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Nemoc,<br/>ošetřování</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Absence</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />PD</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />přerušení</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neschval.<br />PD</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neschval.<br />přerušení</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dochazkaMesic.pvZamestInfo.map(
                                    (z, index) => (
                                      <tr key={index} className="hover:bg-gray-200 dark:hover:bg-gray-900">
                                        <th className="pr-2">
                                        <div data-tooltip-id="MesicniPrehledPodrobny-tooltip" data-tooltip-content="Podrobný měsíční přehled docházky zaměstnance"
                                            data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" >
                                            <MesicniPrehledPodrobny
                                              zamestnanec={z}
                                              rok={rok}
                                              mesic={mesic}
                                              role={selectedRole}
                                            />
                                            <Tooltip id="MesicniPrehledPodrobny-tooltip" />
                                          </div>
                                        </th>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.prijmeniZamestnace}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.jmenoZamestnace}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.odborZkr}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.uvazekZamestnance}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyOdpracovane}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyDovolena}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casDovolenaPre}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnySocVolno}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyOstatniVolno}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyNemoc}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.dnyAbsence >= 1 && "doch-udal-absence"}`}>{z.dnyAbsence}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenePracDny >= 1 && "doch-udal-neukon"}`}>{z.neukoncenePracDny}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenaPreruseni >= 1 && "doch-udal-neukon"}`}>{z.neukoncenaPreruseni}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neschvalenePracDny >= 1 && "doch-udal-nepotvrzeno"}`}>{z.neschvalenePracDny}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neschvalenaPreruseni >= 1 && "doch-udal-nepotvrzeno"}`}>{z.neschvalenaPreruseni}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className="dialog-zapati-zarovnani">
                      <div
                          onClick={() => PredchoziMesic()}
                          className="tlacitko-dialog-prehledy-tmave flex"
                          data-tooltip-id="tooltip-pmesic" data-tooltip-content="Zobrazit předchozí měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                        >
                          <Tooltip id="tooltip-pmesic"/>
                          Měsíc <MinusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                        </div>
                      <div
                          onClick={() => AktualniMesic()}
                          className="tlacitko-dialog-prehledy-tmave flex"
                          data-tooltip-id="tooltip-amesic" data-tooltip-content="Zobrazit aktuální měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                        >   
                          <Tooltip id="tooltip-amesic"/>
                          Měsíc <CheckCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>
                      <div
                          onClick={() => DalsiMesic()}
                          className="tlacitko-dialog-prehledy-tmave flex"
                          data-tooltip-id="tooltip-nmesic" data-tooltip-content="Zobrazit následující měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                        >
                          <Tooltip id="tooltip-nmesic"/>
                          Měsíc <PlusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>
                      <div
                        type="button"
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {dochazkaMesic && (
        <>
        <button
          className="tlacitko-plocha-prehledy"
          onClick={() => setOpen(true)}
        >
          Měsíční<br></br>přehledy
        </button>
        </>
      )}
    </>
  );
}
