import React, { useState, useEffect } from "react";
import axios from "axios";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Config } from "../../Config";
import { SET_LOADING, useAppContext } from "../../Providers/AppProvider";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from 'react-tooltip';

export default function RocniPrehled() {
  const {
    dispatch,
    state: { selectedRole },
  } = useAppContext();
  const [open, setOpen] = useState(false);
  const [dochazkaRok, setDochazkaRok] = useState([]);
  const [rok, setRok] = useState(new Date().getFullYear());

  const cancelButtonRef = useRef(null);

  function GetData(withLoader) {
    if (withLoader) {
      dispatch({ type: SET_LOADING, payload: true });
    }
    axios
      .get(
        `${Config.ServerURL}/api/Dochazka/Prehled/Rok/${selectedRole}/${rok}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setDochazkaRok(res.data);
        if (withLoader) {
          dispatch({ type: SET_LOADING, payload: false });
        }
      });
  }

  useEffect(() => {
    if (open === true) {
      GetData(false); // without loader
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedRole]);

  useEffect(() => {
    if (open === true) {
      GetData(true); // with loader
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rok]);

  function PredchoziRok() {
    setRok(rok - 1);
  }

  function DalsiRok() {
    setRok(rok + 1);
  }

  function AktualniRok() {
    setRok(new Date().getFullYear());
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek">
                  <div className="dialog-obsah">
                    <div className="sm:flex sm:items-start">
                      <div className="dialog-obsah-umisteni2">
                        <Dialog.Title
                          as="h3"
                          className="dialog-nadpis"
                        >
                          Roční přehled - {rok}
                        </Dialog.Title>
                        {/* ----- Přehled pro zaměstnance ----- */}
                        {/* LA 15.3.23 upravena podmínka - přidána role  */}
                        {(dochazkaRok.pracovniDny && selectedRole === 0) && (
                            <table className="mt-2">
                            <tbody>
                                  <tr>
                                      <td className="dialog-prehledy-popisB">Informace a problémy</td>
                                      <td></td>
                                      <td className="dialog-prehledy-popisB">Časové události</td>
                                      <td></td>
                                      <td className="dialog-prehledy-popisB">Denní události</td>
                                      <td></td>
                                  </tr>

                                <tr>
                                    <td className="pr-2 align-top dialog-prehledy-obsah">
                                      <div>Pracovní dny v roce:</div>
                                      <div>Placené svátky:</div>
                                      <div>Odpracované dny:</div>
                                      <div>Neukončené PD:</div>
                                      <div>Neukončená přerušení:</div>
                                      <div>Bez stravenky:</div>
                                      <div>Se stravenkou:</div>
                                    </td>
                                    <td className="pr-10 align-top dialog-prehledy-obsah">
                                      <div className="text-right">{dochazkaRok.pracovniDny}</div>
                                      <div className="text-right">{dochazkaRok.dnySvatky}</div>
                                      <div className="text-right">{dochazkaRok.odpracovaneDny}</div>
                                      <div className="text-right">{dochazkaRok.neukoncenePracDny}</div>
                                      <div className="text-right">{dochazkaRok.neukoncenaPreruseni}</div>  
                                      <div className="text-right">{dochazkaRok.preruseniBezStravenky}</div>
                                      <div className="text-right">{dochazkaRok.preruseniSeStravenkou}</div>
                                    </td>
                                    <td className="pr-2 align-top dialog-prehledy-obsah">
                                      <div>Čas v práci:</div>
                                      <div>Odpracováno:</div>
                                      <div>Přerušení pracovní:</div>
                                      <div>Přerušení nepracovní:</div>
                                      <div>Dovolená (hod):</div>
                                    </td>
                                    <td className="pr-10 align-top dialog-prehledy-obsah">
                                      <div className="text-right">{dochazkaRok.casvPraci}</div>
                                      <div className="text-right">{dochazkaRok.odpracovano}</div>
                                      <div className="text-right">{dochazkaRok.preruseniPracovni}</div>
                                      <div className="text-right">{dochazkaRok.preruseniNepracovni}</div>
                                      <div className="text-right">{dochazkaRok.casDovolenaPre}</div>
                                    </td>
                                    <td className="pr-2 align-top dialog-prehledy-obsah">
                                      <div>Dovolená (dny):</div>
                                      <div>Soc. volno:</div>
                                      <div>Ostatní volno:</div>
                                      <div>Home office:</div>
                                      <div>Služební cesty:</div>
                                      <div>Nemoci:</div>
                                    </td>
                                    <td className="pr-10 align-top dialog-prehledy-obsah">
                                      <div className="text-right">{dochazkaRok.dovolene}</div>
                                      <div className="text-right">{dochazkaRok.socVolno}</div>
                                      <div className="text-right">{dochazkaRok.ostatniVolno}</div>
                                      <div className="text-right">{dochazkaRok.homeOffice}</div>
                                      <div className="text-right">{dochazkaRok.sluzebniCesty}</div>
                                      <div className="text-right">{dochazkaRok.nemoci}</div>
                                    </td>
                  
                                </tr>
                            </tbody>
                        </table>
                        )}
                        {/* ----- Přehled pro vedoucí, tajemníka ----- */}
                        {/* LA 15.3.23 - úprava podmínky - přidány role */}
                        <div className="mt-2">
                          {(dochazkaRok.pvZamestInfo && (selectedRole === 1 || selectedRole === 2 || selectedRole === 5)) && (
                            <div className="tabulka-MinMaxVelikost">
                              <table>
                                <thead>
                                  <tr>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Příjmení</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Jméno</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Odbor</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Odprac.</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Čas v <br />práci</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Pracovní<br />přerušení</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Nepracovní<br /> přerušení</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Prac.<br /> dny</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br /> (dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br /> (hodiny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Nemoc</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Služební<br /> cesta</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Home<br /> office</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Sociální<br /> volno</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Ostatní<br /> volno</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />PD</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />přerušení</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dochazkaRok.pvZamestInfo.map((z, index) => (
                                    <tr
                                      key={index}
                                      className="tabulka-radek-hover"
                                    >
                                      <td className="pr-2 dialog-prehledy-obsah-tab">{z.prijmeniZamestnace}</td>
                                      <td className="pr-2 dialog-prehledy-obsah-tab">{z.jmenoZamestnace}</td>
                                      <td className="pr-2 dialog-prehledy-obsah-tab">{z.odborZkr}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casOdprac}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casvPraci}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casPrPrerus}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casNePrPrerus}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyPracDen}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyDovolena}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casDovolenaPre}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyNemoc}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnySluzCesta}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyHomeOffice}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnySocVolno}</td>
                                      <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.dnyOstatniVolno}</td>
                                      <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenePracDny >= 1 && "doch-udal-neukon"}`}>{z.neukoncenePracDny}</td>
                                      <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenaPreruseni >= 1 && "doch-udal-neukon"}`}>{z.neukoncenaPreruseni}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {/* ----- Přehled pro Personalistu ----- */}
                          {/* LA 15.3.23 - pouze údaje pro mzdy a personalistiku (jako přehled Mzdy ve staré verzi docházky)  */}
                          {(dochazkaRok.pvZamestInfo && (selectedRole === 4 )) && (
                            <div className="tabulka-MinMaxVelikost">
                              {/* <div className="pt-1 font-bold" ></div> */}
                              <table>
                                <thead>
                                  <tr>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Příjmení</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Jméno</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Odbor</th>
                                    <th className="pr-2 dialog-prehledy-zahlavi">Denní<br/>úvazek<br/>(hod)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Odpracováné<br/>dny</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br/>(dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Dovolená<br/>(hodiny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Soc.volno<br/>(dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Ostatní volno<br/>(dny)</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Nemoc,<br/>ošetřování</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />PD</th>
                                    <th className="pr-2 text-right dialog-prehledy-zahlavi">Neukon.<br />přerušení</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dochazkaRok.pvZamestInfo.map(
                                    (z, index) => (
                                      <tr
                                        key={index}
                                        className="hover:bg-gray-200 dark:hover:bg-gray-900"
                                      >
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.prijmeniZamestnace}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.jmenoZamestnace}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.odborZkr}</td>
                                        <td className="pr-2 dialog-prehledy-obsah-tab">{z.uvazekZamestnance}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyOdpracovane}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyDovolena}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnySocVolno}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyOstatniVolno}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.casDovolenaPre}</td>
                                        <td className="pr-2 text-right dialog-prehledy-obsah-tab">{z.mzdyDnyNemoc}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenePracDny >= 1 && "doch-udal-neukon"}`}>{z.neukoncenePracDny}</td>
                                        <td className={`pr-2 text-right dialog-prehledy-obsah-tab ${z.neukoncenaPreruseni >= 1 && "doch-udal-neukon"}`}>{z.neukoncenaPreruseni}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className="dialog-zapati-zarovnani">

                      {/* Předchozí rok */}
                      <div
                        onClick={() => PredchoziRok()}
                        className="tlacitko-dialog-prehledy-tmave flex"
                        data-tooltip-id="tooltip-prok" data-tooltip-content="Zobrazit předchozí rok" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                      >
                        <Tooltip id="tooltip-prok"/>
                        Rok <MinusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>

                      {/* Aktuální rok */}
                      <div
                        onClick={() => AktualniRok()}
                        className="tlacitko-dialog-prehledy-tmave flex"
                        data-tooltip-id="tooltip-arok" data-tooltip-content="Zobrazit aktuální rok" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                      >
                        <Tooltip id="tooltip-arok"/>
                        Rok <CheckCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>

                      {/* Následujcí rok */}
                      <div
                        onClick={() => DalsiRok()}
                        className="tlacitko-dialog-prehledy-tmave flex"
                        data-tooltip-id="tooltip-nrok" data-tooltip-content="Zobrazit následující rok" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                      >
                        <Tooltip id="tooltip-nrok"/>
                        Rok <PlusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>

                      <div
                        type="button"
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {dochazkaRok && (
        <button
          className="tlacitko-plocha-prehledy"
          // shadow-md hover:shadow-lg bg-yellow-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-yellow-600 focus:outline-none focus:shadow-outline
          onClick={() => setOpen(true)}
        >
          Roční<br></br>přehledy
        </button>
      )}
    </>
  );
}
