export default function Legenda() {
    return(
        <div className="px-10 py-3">
        <table className=""> 
          <tbody>        
          <tr className="rounded-sm">           
              <td><div className="kontakty-plocha-barva-DN0 dark:text-gray-100 dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-sm border transition-all text-sm">Přítomen - na pracovišti</div></td>            
              <td><div className="kontakty-plocha-barva-DN4 dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-sm border transition-all text-sm" >Nepřítomen - přerušení</div></td>
              <td><div className="kontakty-plocha-barva-DN1 dark:text-gray-100 dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-sm border transition-all text-sm " >Nepřítomen - není info</div></td>
              <td><div className="kontakty-plocha-barva-DN2 dark:text-gray-100 dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-sm border transition-all text-sm " >Nepřítomen - byl v práci</div></td>
              <td><div className="kontakty-plocha-barva-DN3 dark:text-gray-100 dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-sm border transition-all text-sm " >Nepřítomen - celý den (dovolená apod.)</div></td>
              <td><div className="kontakty-plocha-barva-DN5 dark:text-gray-300 dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-sm border transition-all text-sm " >Nevede docházku</div></td>
          </tr>
          </tbody>
        </table>
        </div>
    )
}