import Odbory from "./Odbory";
import Udalosti from "./Udalosti";
import Zamestnanci from "./Zamestnanci";
import RoleZam from "./RoleZam";
import GlobalniParametry from "../../GlobalniParametry";
import UvazkyZam from "./UvazkyZam";
import AuditLog from "../../AuditLog";
import Navody from "./Navody";

export default function DochazkaAdmin() {
  return (
    <div className="w-full mt-8 bg-blue-100 p-4 rounded-lg">
      <h3 className="font-bold text-blue-900">Administrace docházky - Zaměstnanci</h3>
      <div className="flex flex-row">
        <Zamestnanci />
        <UvazkyZam />
        <RoleZam />
      </div>
      <h3 className="font-bold text-blue-900">Administrace docházky - Nastavení</h3>
      <div className="flex flex-row">
        <Odbory />
        <Udalosti />
        <GlobalniParametry />
      </div>
      <h3 className="font-bold text-blue-900">Administrace docházky - provozní informace</h3>
      <div className="flex flex-row">
        <AuditLog agenda="1"/>
      </div>
      <h3 className="font-bold text-blue-900">Administrace aplikace- správa návodů</h3>
      <div className="flex flex-row">
        <Navody />
      </div>
    </div>
  );
}
