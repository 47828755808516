import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Config } from "../../../Config"
import DeleteZaznamu from './DeleteZaznamu';
import { useAppContext } from '../../../Providers/AppProvider';
import PridaniUpravaNavodu from './PridaniUpravaNavodu';

export default function Navody() {
  const { dispatch, state: {refresh} } = useAppContext();
  const [open, setOpen] = useState(false);
  const [navody, setNavody] = useState([])
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open === true) {
      dispatch({ type: 'SET_LOADING', payload: true })
      axios.get(`${Config.ServerURL}/api/Navody`, {
        withCredentials: true
      })
        .then(res => {
          setNavody(res.data)
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING', payload: false })
        })
    }
    // eslint-disable-next-line
  }, [open, refresh]);



  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Úprava návodů
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka ">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Název
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Slug (URL)
                              </th>
                              <th className='text-left tabulka-zahlavi-sticky-admin'>

                              </th>
                              <th className='text-left tabulka-zahlavi-sticky-admin'>

                              </th>
                            </tr>
                          </thead>
                          <tbody className="tabulka-telo-Ylinka ">
                            {navody.map((n) => {
                              return (
                                <tr key={n.id} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{n.id}</td>
                                  <td className='tabulka-bunka-basic'>{n.nazev}</td>
                                  <td className='tabulka-bunka-basic'>{n.slug}</td>
                                  <td className='flex mt-1 flex-row'>
                                    <PridaniUpravaNavodu novyNavod={false} data={n}/>
                                    <DeleteZaznamu urlAPI="/api/Navody/Odstraneni/" idZaznamu={n.id} 
                                                  titulek={"Název návodu: " + n.nazev}
                                                  dotaz={"Přejete si odstranit uvedený návod?"}/> 
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2'>
                      <PridaniUpravaNavodu novyNavod={true}/>
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
        Návody
      </button>
    </>
  )
}