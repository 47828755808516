import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import { useAppContext } from '../../../Providers/AppProvider';
// import { format } from 'date-fns';
// import { useVozidlaContext } from '../../../Providers/VozidlaProvider';
import CurrencyFormat from 'react-currency-format';
import { MinusSmallIcon, CheckIcon } from "@heroicons/react/24/outline";

export default function PrehledRocniNaklady() {
  const { state:{refresh} } = useAppContext();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [prehled, setPrehledRPN] = useState([]);
  const [selectedRok, setSelectedRok] = useState(2023);
  const [showNaklady, setShowNaklady] = useState(true);
  const [showQData, setShowQData] = useState(true);
  const [showMData, setShowMData] = useState(true);
  const [showPohHmoty, setShowPohHmoty] = useState(false);

  useEffect(() => {
    if (open === true) {
      axios.get(`${Config.ServerURL}/api/Vozidla/VozidlaRocniProvozniNaklady/${selectedRok}`, {
        withCredentials: true
      })
        .then(res => {
          setPrehledRPN(res.data)
        })
    }
  }, [open, refresh, selectedRok]);


  function PredchoziRok() {
    setSelectedRok(selectedRok - 1);
    if (selectedRok <= 2022) setSelectedRok(2022);
  }

  function DalsiRok() {
    setSelectedRok(selectedRok + 1);
  }

  function AktualniRok() {
    setSelectedRok(new Date().getFullYear());
  }

  function CheckShow({ checked }) {
    return checked ? (
      <CheckIcon className="h-4 w-4 text-black bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-200" />
    ) : (
      <MinusSmallIcon className="h-4 w-4 text-black bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-200" />
    );
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Roční přehled provozních nákladů vozidel - {selectedRok}
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                      
                      <table className="table-auto gap-y-3">
                          <thead>
                            <tr>
                              <th className="text-left tabulka-zahlavi-sticky-admin">Vozidlo - SPZ</th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">Popis (jednotky)</th>
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Leden</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Únor</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Březen</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Duben</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Květen</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Červen</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Červenec</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Srpen</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Září</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Říjen</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Listopad</th>}
                              {showMData && <th className="text-left tabulka-zahlavi-sticky-admin">Prosinec</th>}
                              {showQData && <th className="text-left tabulka-zahlavi-sticky-admin">1.čtvrtletí</th>}
                              {showQData && <th className="text-left tabulka-zahlavi-sticky-admin">2.čtvrtletí</th>}
                              {showQData && <th className="text-left tabulka-zahlavi-sticky-admin">3.čtvrtletí</th>}
                              {showQData && <th className="text-left tabulka-zahlavi-sticky-admin">4.čtvrtletí</th>}
                              <th className="text-left tabulka-zahlavi-sticky-admin">Rok {selectedRok}</th>
                            </tr>
                          </thead>
                        <tbody>
                          {prehled.map((v, index) => (
                            <tr className="tabulka-radek-hover" key={index}>
                              {/* Název vozidla */}
                              <td className="px-2 font-semibold text-left text-base py-1 border border-gray-200 dark:border-gray-700">
                                {v.typVozidla} - {v.spz}
                              </td>                            
                              {/* Označení zobrazovaných hodnot */}
                              <td className="dark:text-gray-100 align-top px-2 my-[2px] py-[2px] border transition-all text-sm border-gray-200 dark:border-gray-700">
                                <div className="text-left text-sm">Stav tachometru (km)</div>
                                <div className="text-left text-sm">Nájezd za období (km)</div>
                                {showNaklady && <div className="text-left text-sm">Celkové provozní náklady (Kč)</div>}
                                {showNaklady && <div className="text-left text-sm">Provozní náklady na km (Kč/km)</div>}
                                {showPohHmoty && <div className="text-left text-sm">Spotřeba PH (litry)</div>}
                                {showPohHmoty && <div className="text-left text-sm">Náklady na PH (Kč)</div>}
                                {showPohHmoty && <div className="text-left text-sm">Náklady na PH na km (Kč/km)</div>}
                                {showPohHmoty && <div className="text-left text-sm">Průměrná spotřeba (l/100km)</div>}
                              </td>
                              {/* Data za měsíce   */}
                              {showMData && v.mesicniPNV.map((m) => (
                                      <td key={m.obdobi} className={`dark:text-gray-100 align-top px-2 my-[2px] py-[2px] border transition-all text-sm border-gray-200 dark:border-gray-700`}>
                                          {m.zobrvPrehl && 
                                              <div className="text-right text-sm">                                                
                                                  <div className="">{m.stavTachometru && <CurrencyFormat value={m.stavTachometru} displayType={'text'} thousandSeparator={" "} />}</div>
                                                  <div className="">{m.najetoKm && <CurrencyFormat value={m.najetoKm} displayType={'text'} thousandSeparator={" "} />}</div>
                                                  {showNaklady && <div className="">{m.nakladyKc && <CurrencyFormat value={m.nakladyKc} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showNaklady && <div className="">{m.nakladyKcKm && <CurrencyFormat value={m.nakladyKcKm} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                                  {showPohHmoty && <div className="">{m.pohHmotyLitry && <CurrencyFormat value={m.pohHmotyLitry} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showPohHmoty && <div className="">{m.pohHmotyKc && <CurrencyFormat value={m.pohHmotyKc} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showPohHmoty && <div className="">{m.pohHmotyKcKm && <CurrencyFormat value={m.pohHmotyKcKm} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                              </div>
                                          }
                                      </td>
                                    ))}
                              {/* Data za čtvrtletí */}
                              {/* {showQData ? ( */}
                              {showQData && v.ctvrtletniPNV.map((q) => (
                                      (<td key={q.obdobi} className={`dark:text-gray-100 align-top px-2 my-[2px] py-[2px] border transition-all text-sm border-gray-200 dark:border-gray-700`}>
                                          {q.zobrvPrehl && showQData && 
                                              <div className="text-right text-sm">                                                
                                                  <div className="">{q.stavTachometru && <CurrencyFormat value={q.stavTachometru} displayType={'text'} thousandSeparator={" "} />}</div>
                                                  <div className="">{q.najetoKm && <CurrencyFormat value={q.najetoKm} displayType={'text'} thousandSeparator={" "} />}</div>
                                                  {showNaklady && <div className="">{q.nakladyKc && <CurrencyFormat value={q.nakladyKc} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showNaklady && <div className="">{q.nakladyKcKm && <CurrencyFormat value={q.nakladyKcKm} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                                  {showPohHmoty && <div className="">{q.pohHmotyLitry && <CurrencyFormat value={q.pohHmotyLitry} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showPohHmoty && <div className="">{q.pohHmotyKc && <CurrencyFormat value={q.pohHmotyKc} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showPohHmoty && <div className="">{q.pohHmotyKcKm && <CurrencyFormat value={q.pohHmotyKcKm} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                                  {showPohHmoty && <div className="">{q.prumerSpotreba && <CurrencyFormat value={q.prumerSpotreba} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                              </div>
                                          }
                                      </td>)                                    
                                    ))} 
                              {/* //  ) : null}   */}
                              {/* Data za celý rok */}
                              {v.rocniPNV.map((r) => (
                                      <td key={r.obdobi} className={`dark:text-gray-100 align-top px-2 my-[2px] py-[2px] border transition-all text-sm border-gray-200 dark:border-gray-700`}>
                                          {r.zobrvPrehl && 
                                              <div className="text-right text-sm">                                                
                                                  <div className="">{r.stavTachometru && <CurrencyFormat value={r.stavTachometru} displayType={'text'} thousandSeparator={" "} />}</div>
                                                  <div className="">{r.najetoKm && <CurrencyFormat value={r.najetoKm} displayType={'text'} thousandSeparator={" "} />}</div>
                                                  {showNaklady && <div className="">{r.nakladyKc && <CurrencyFormat value={r.nakladyKc} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showNaklady && <div className="">{r.nakladyKcKm && <CurrencyFormat value={r.nakladyKcKm} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                                  {showPohHmoty && <div className="">{r.pohHmotyLitry && <CurrencyFormat value={r.pohHmotyLitry} displayType={'text'} thousandSeparator={" "} decimalSeparator={","} />}</div>}
                                                  {showPohHmoty && <div className="">{r.pohHmotyKc && <CurrencyFormat value={r.pohHmotyKc} displayType={'text'} thousandSeparator={" "} decimalSeparator={","}/>}</div>}
                                                  {showPohHmoty && <div className="">{r.pohHmotyKcKm && <CurrencyFormat value={r.pohHmotyKcKm} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                                  {showPohHmoty && <div className="">{r.prumerSpotreba && <CurrencyFormat value={r.prumerSpotreba} displayType={'text'} decimalSeparator={","} decimalScale={"1"}/>}</div>}
                                              </div>
                                          }
                                      </td>
                                    ))} 

                            </tr>
                          ))}
                        </tbody>
                      </table>

                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-gray-900 dark:text-gray-300 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>

                      {/* Tlačítko pro zobrazení / skrytí měsíčních nákladů */}
                      <div
                        onClick={() => setShowMData(!showMData)}
                        className="text-ceter text-sm shadow-md hover:shadow-lg cursor-pointer bg-pink-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-pink-500 focus:outline-none focus:shadow-outline"
                      >
                        Měsíční <br /> náklady 
                        <div className='flex justify-center mt-1'>
                        <CheckShow checked={showMData}/>
                        </div>
                      </div>            

                      {/* Tlačítko pro zobrazení / skrytí čtvrtletních nákladů  */}
                      <div
                        onClick={() => setShowQData(!showQData)}
                        className="text-center text-sm shadow-md hover:shadow-lg cursor-pointer  bg-pink-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-pink-500 focus:outline-none focus:shadow-outline"
                      >
                        Čtvrtletní <br />náklady 
                        <div className='flex justify-center mt-1'>
                        <CheckShow checked={showQData}/>
                        </div>
                      </div>

                      {/* Tlačítko pro zobrazení / skrytí Provozních nákladů */}
                      <div
                        onClick={() => setShowNaklady(!showNaklady)}
                        className="text-center text-sm shadow-md hover:shadow-lg cursor-pointer  bg-purple-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-purple-500 focus:outline-none focus:shadow-outline"
                      >
                        Provozní <br /> náklady 
                        <div className='flex justify-center mt-1'>
                        <CheckShow checked={showNaklady}/>
                        </div>
                      </div>

                      {/* Tlačítko pro zobrazení / skrytí Pohonných hmot a spotřeby */}
                      <div
                        onClick={() => setShowPohHmoty(!showPohHmoty)}
                        className="text-center text-sm shadow-md hover:shadow-lg cursor-pointer  bg-purple-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-purple-500 focus:outline-none focus:shadow-outline"
                      >
                        Pohonné<br />hmoty
                        <div className='flex justify-center mt-1'>
                        <CheckShow checked={showPohHmoty}/>
                        </div>
                      </div>

                      {/* tlačítka pro výběr roků */}
                      <button onClick={() => PredchoziRok()} className="shadow-md hover:shadow-lg cursor-pointer bg-blue-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-blue-500 focus:outline-none focus:shadow-outline">
                          Předchozí<br /> rok
                      </button>

                      <button onClick={() => AktualniRok()} className="shadow-md hover:shadow-lg cursor-pointer bg-blue-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-blue-500 focus:outline-none focus:shadow-outline" >
                          Aktuální<br /> rok
                      </button>
                      
                      <button onClick={() => DalsiRok()} className="shadow-md hover:shadow-lg cursor-pointer bg-blue-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-blue-500 focus:outline-none focus:shadow-outline">
                          Následující<br /> rok
                      </button>

                      <button onClick={() => setOpen(false)} ref={cancelButtonRef} className="tlacitko-dialog-zavrit">
                          Zavřít
                      </button>

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" 
      onClick={() => setOpen(true)}>
       Přehled provozních nákladů
      </button>

    </>
  )
}