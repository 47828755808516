import React, { useState, useEffect } from "react";
import axios from "axios";
import { Config } from "../../Config";
import { SET_LOADING, useAppContext } from "../../Providers/AppProvider";

export default function DenniPrehled() {
    const { dispatch, state: { selectedRole, refresh } } = useAppContext();
    const [dochazkaDen, setDochazkaDen] = useState({});

    useEffect(() => {
        dispatch({type: SET_LOADING, payload: true})
        axios
            .get(`${Config.ServerURL}/api/Dochazka/Prehled/Den/${selectedRole}`, {
                withCredentials: true,
            })
            .then((res) => {
                setDochazkaDen(res.data);
                dispatch({type: SET_LOADING, payload: false})
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRole, refresh]);

    return (
        <>
            {dochazkaDen &&
                (<div className="plocha-prehledy-ramecek max-w-7xl">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-1 text-center sm:mt-0 sm:text-left">
                            <div className="">
                                <div>
                                    <h3 className="plocha-prehledy-nadpis">
                                        Denní přehled: {dochazkaDen.dpzZpusobZobrazeni === 1 && <br /> } {dochazkaDen.popisPrehledu}
                                    </h3>
                                    {dochazkaDen.dpzZpusobZobrazeni === 0 && <div className="font-bold">{dochazkaDen.dpzHlavniUdalost}</div>}
                                    {dochazkaDen.dpzZpusobZobrazeni === 2 && <div className="font-bold">Dnes: {dochazkaDen.dpzHlavniUdalost}</div>}
                                    <table className="mt-2">
                                    <tbody>
                                        <tr >
                                            <td className="pr-5 font-normal align-top ">
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.uvazekHod ? <div className="plocha-prehledy-popis">Denní úvazek:</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.casPredPracDobou ? <div className="plocha-prehledy-popis">Čas nezapočítaný do PD:</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.casvPraci ? <div className="plocha-prehledy-popis">Čas v práci:</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.odpracovano ? <div className="plocha-prehledy-popis">Odpracováno:</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.zbyvaOdpracovat ? <div className="plocha-prehledy-popis">{dochazkaDen.popisZbyvaOdpracovat}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.casKonecPracDne ? <div className="plocha-prehledy-popis">Konec prac.doby:</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.preruseniPracovni ? <div className="mt-2 plocha-prehledy-popis">Přerušení pracovní:</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.preruseniNepracovni ? <div className="plocha-prehledy-popis">Přerušení nepracovní:</div> : null}
                                            </td>
                                            <td className="pr-5 font-normal align-top">
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.uvazekHod ? <div className="text-right plocha-prehledy-obsah">{dochazkaDen.uvazekHod}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.casPredPracDobou ? <div className="text-right plocha-prehledy-obsah">{dochazkaDen.casPredPracDobou}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.casvPraci ? <div className="text-right plocha-prehledy-obsah">{dochazkaDen.casvPraci}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.odpracovano ? <div className="text-right plocha-prehledy-obsah">{dochazkaDen.odpracovano}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.zbyvaOdpracovat ? <div className="text-right plocha-prehledy-obsah">{dochazkaDen.zbyvaOdpracovat}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.casKonecPracDne ? <div className="text-right plocha-prehledy-obsah">{dochazkaDen.casKonecPracDne}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.preruseniPracovni ? <div className="mt-2 text-right plocha-prehledy-obsah">{dochazkaDen.preruseniPracovni}</div> : null}
                                                {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.preruseniNepracovni ? <div className="text-right plocha-prehledy-obsah">{dochazkaDen.preruseniNepracovni}</div> : null}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                                    {dochazkaDen.dpzZpusobZobrazeni === 1 && dochazkaDen.preruseni ?
                                        (<div>
                                            <div className="pt-5 plocha-prehledy-popisM">Přerušení</div>
                                            <table>
                                                <tbody>
                                                    {dochazkaDen.preruseni.map((p, index) => (
                                                        <tr key={index} className='plocha-prehledy-hover'>
                                                            <td className="pr-5 plocha-prehledy-popis">{p.nazev}</td>
                                                            <td className="text-right plocha-prehledy-obsah">{p.casCelkem}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>) : null}

                                    {dochazkaDen.pvZamestInfo &&
                                        (<div>
                                            <div className="pt-1 font-bold"></div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="pr-2 plocha-prehledy-zahlavi">Příjmení</th>
                                                        <th className="pr-2 plocha-prehledy-zahlavi">Jméno</th>
                                                        <th className="pr-2 plocha-prehledy-zahlavi">Odbor</th>
                                                        <th className="pr-2 plocha-prehledy-zahlavi">Aktuální událost</th>
                                                        <th className="pr-2 plocha-prehledy-zahlavi">Příchod</th>
                                                        <th className="pr-2 plocha-prehledy-zahlavi">Odchod</th>
                                                        <th className="pr-2 text-right plocha-prehledy-zahlavi">Odpra-<br />cováno</th>
                                                        <th className="pr-2 text-right plocha-prehledy-zahlavi">Čas v <br />práci</th>
                                                        <th className="pr-2 text-right plocha-prehledy-zahlavi">Pracovní<br />přerušení</th>
                                                        <th className="pr-2 text-right plocha-prehledy-zahlavi">Nepracovní<br />přerušení</th>
                                                        <th className="pr-2 text-right plocha-prehledy-zahlavi">Dovolená<br />(hod)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dochazkaDen.pvZamestInfo.map((z, index) => (
                                                        <tr key={index} className='plocha-prehledy-hover'>
                                                            <td className="pr-2 plocha-prehledy-obsah">{z.prijmeniZamestnace}</td>
                                                            <td className="pr-2 plocha-prehledy-obsah">{z.jmenoZamestnace}</td>
                                                            <td className="pr-2 plocha-prehledy-obsah">{z.odborZkr}</td>
                                                            <td className="pr-2 plocha-prehledy-obsah">{z.nazevUdalosti}</td>
                                                            <td className="pr-2 plocha-prehledy-obsah">{z.casPrichodu}</td>
                                                            <td className="pr-2 plocha-prehledy-obsah">{z.casOdchodu}</td>
                                                            <td className="pr-2 text-right plocha-prehledy-obsah">{z.casOdprac}</td>
                                                            <td className="pr-2 text-right plocha-prehledy-obsah">{z.casvPraci}</td>
                                                            <td className="pr-2 text-right plocha-prehledy-obsah">{z.casPrPrerus}</td>
                                                            <td className="pr-2 text-right plocha-prehledy-obsah">{z.casNePrPrerus}</td>
                                                            <td className="pr-2 text-right plocha-prehledy-obsah">{z.casDovolenaPre}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </>
    );
}
