import KategorieNakladu from "./KategorieNakladu";
import RezervaceVozidelAdmin from "./RezervaceVozidelAdmin";
import VozidlaCiselnik from "./VozidlaCiselnik";
import SkupinyCilu from "./SkupinyCilu";
import Naklady from "./Naklady";
import StavyTach from "./StavyTach";
import PrehledRocniNaklady from "./PrehledRocniNaklady";
import GlobalniParametry from "../../GlobalniParametry";
import AuditLog from "../../AuditLog";

export default function VozidlaAdmin() {
  return (
    <div className="w-full bg-blue-100 p-4 rounded-lg">
      <h3 className="font-bold text-blue-900">Administrace rezervací vozidel</h3>
      <div className="flex flex-row">
        <RezervaceVozidelAdmin type="spz"/>
        <RezervaceVozidelAdmin type="datum"/>
        <RezervaceVozidelAdmin type="skupiny-cilu"/>
        <RezervaceVozidelAdmin type="historie"/>
      </div>
      <h3 className="font-bold text-blue-900">Administrace provozních nákladů vozidel</h3>
      <div className="flex flex-row">
        <Naklady />
        <StavyTach />
        <PrehledRocniNaklady />
      </div>
      <h3 className="font-bold text-blue-900">Vozidla a provozní náklady - nastavení</h3>
      <div className="flex flex-row">
        <VozidlaCiselnik/>
        <KategorieNakladu/>
        <SkupinyCilu/>
        <GlobalniParametry />
      </div>
      <h3 className="font-bold text-blue-900">Administrace - provozní informace</h3>
      <div className="flex flex-row">
        <AuditLog agenda="2"/>
      </div>
    </div>
  );
}
