import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import { Config } from "../../Config";
import { useAppContext, SET_INFORMATION_ALERT, SET_ERROR_ALERT, SET_LOADING, REFRESH_DATA } from '../../Providers/AppProvider';
import { useDochazkaContext } from '../../Providers/DochazkaProvider';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export default function PridaniPreruseni() {
    const { dispatch } = useAppContext()
    const { state: { globalniUdalosti } } = useDochazkaContext()
    const [open, setOpen] = useState(false)
    const [udalosti, setUdalosti] = useState([])
    const [idUdalosti, setIdUdalosti] = useState(null)

    useEffect(() => {
        if (globalniUdalosti && globalniUdalosti.count !== 0) {
            setUdalosti(globalniUdalosti.filter(x => x.preruseni === true))
            if (globalniUdalosti.filter(x => x.preruseni === true)[0]) {
                setIdUdalosti(globalniUdalosti.filter(x => x.preruseni === true)[0].idUdalosti)
            }
        }
    }, [globalniUdalosti]);

    const cancelButtonRef = useRef(null)

    function AddDoch() {
        dispatch({ type: SET_LOADING, payload: true })
        axios.post(`${Config.ServerURL}/api/Dochazka/Zacatek/Preruseni/${idUdalosti}`, {
        }, {
            withCredentials: true
        })
            .then(res => {
                if (res.data.message) {
                    dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message })
                }
                dispatch({ type: REFRESH_DATA })
                setOpen(false)
                dispatch({ type: SET_LOADING, payload: false })
            })
            .catch(err => {
                dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message })
                dispatch({ type: SET_LOADING, payload: false })
            })
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="dialog-transition" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-20 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="dialog-edit-ramecek">
                                                         
                                    <div className="dialog-edit-obsah">
                                        <div className="grid grid-cols-6 gap-3">
                                            <div className="col-span-6 sm:col-span-6">
                                                <label htmlFor="country" className="dialog-edit-nadpis-maly">
                                                    Vyberte přerušení ...
                                                </label>
                                                <select value={idUdalosti} onChange={(e) => setIdUdalosti(parseInt(e.target.value))} className="dialog-edit-pole-select">
                                                    {udalosti.map((udalost, index) => (
                                                        <option key={index} value={udalost.idUdalosti}>{udalost.nazev} ({udalost.zkrUdal})</option>
                                                    ))}
                                                </select>
                                                {/* LA + AA 13.5.23 Zobrazení popisu vybrané události */}
                                                {udalosti.length > 0 &&
                                                    (<ReactMarkdown className="reactMarkDown dialog-edit-pole-napoveda">
                                                        {udalosti.find(u => u.idUdalosti === idUdalosti)?.vysvetlivky}
                                                    </ReactMarkdown>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dialog-edit-zapati-pozadi">
                                        <button type="button" className="dialog-edit-tlacitko-akce"
                                            onClick={() => AddDoch()}>Zahájit přerušení</button>
                                        <button
                                            type="button"
                                            className="dialog-edit-tlacitko-zavrit"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}>
                                            Zrušit
                                        </button>                   
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <button onClick={() => setOpen(true)} className="tlacitko-plocha-ZacatekUdal m-8">Začátek přerušení</button>
        </>
    )
}
