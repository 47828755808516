import React, { useState, useEffect } from "react";
import axios from "axios";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Config } from "../../Config";
import { SET_LOADING, useAppContext } from "../../Providers/AppProvider";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from 'react-tooltip';

export default function MesicniPrehledPodrobny({ zamestnanec, rok, mesic }) {
  const {
    dispatch,
    state: { selectedRole },
  } = useAppContext();
  const [open, setOpen] = useState(false);
  const [dochazkaMesic, setDochazkaMesic] = useState([]);
  const [rokSelected, setRokSelected] = useState(new Date().getFullYear());
  const [mesicSelected, setMesicSelected] = useState(new Date().getMonth() + 1);
  const [showPrerus, setShowPrerus] = useState(false);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open === true) {
      if (zamestnanec) {
        dispatch({ type: SET_LOADING, payload: true });
        axios
          .get(
            `${Config.ServerURL}/api/Dochazka/PrehledPodrobnyVTP/Mesic/${selectedRole}/${rok}/${mesic}/${zamestnanec.idZam}`,
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            setDochazkaMesic(res.data);
          })
          .finally(() => {
            dispatch({ type: SET_LOADING, payload: false });
          });
      }
      else {
        dispatch({ type: SET_LOADING, payload: true });
        axios
          .get(
            `${Config.ServerURL}/api/Dochazka/PrehledPodrobnyZamestnanec/Mesic/${selectedRole}/${rokSelected}/${mesicSelected}`,
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            setDochazkaMesic(res.data);
          })
          .finally(() => {
            dispatch({ type: SET_LOADING, payload: false });
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, mesicSelected, rokSelected]);

  function PredchoziMesic() {
    if (mesicSelected > 1) {
      setMesicSelected(mesicSelected - 1);
    } else {
      setRokSelected(rokSelected - 1);
      setMesicSelected(12);
    }
  }

  function DalsiMesic() {
    if (mesicSelected < 12) {
      setMesicSelected(mesicSelected + 1);
    } else {
      setRokSelected(rokSelected + 1);
      setMesicSelected(1);
    }
  }

  // LA 24.4.23 - ? proč se musí přičíst 1 ? nemůže být měsíc č. 13 ? (vrací v prosinci číslo 11?)
  function AktualniMesic() {  
    setRokSelected(new Date().getFullYear());
    setMesicSelected(new Date().getMonth()+1);    
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full w-full text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek">
                  <div className="dialog-obsah">
                    <div className="sm:flex sm:items-start">
                      <div className="dialog-obsah-umisteni">
                        <Dialog.Title
                          as="h3"
                          className="dialog-nadpis"
                        >
                          Podrobný měsíční přehled - {mesic || mesicSelected}/{rok || rokSelected} {zamestnanec && " - " + zamestnanec.jmenoZamestnace + " " + zamestnanec.prijmeniZamestnace}
                        </Dialog.Title>
                        {/* ----- Přehled pro vedoucí, tajemníka ----- */}
                        <div className="mt-2">

                          <div className="tabulka-MinMaxVelikost">
                            <table className="">
                              <thead>
                                <tr>
                                  <th className="pr-1 text-left text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi">Den</th>
                                  <th className="pr-1 text-left text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi">Hlavní událost</th>
                                  <th className="pr-1 text-left text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi">Prac.doba</th>
                                  <th className="pr-1 text-left text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi">Oběd</th>
                                  <th className="pr-1 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"  
                                    data-tooltip-id="tooltip-dovd" data-tooltip-content="Dovolená (celé pracovní dny)" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-dovd"/>Dov(dny)</th>

                                  <th className="pr-1 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-dovh" data-tooltip-content="Dovolená (hodiny)" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-dovh"/>Dov(hod)</th>

                                  <th className="pr-1 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help" 
                                    data-tooltip-id="tooltip-nem" data-tooltip-content="Nemoc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-nem"/>Nem</th>

                                  <th className="pr-1 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help" 
                                    data-tooltip-id="tooltip-sc" data-tooltip-content="Služební cesty" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" >
                                    <Tooltip id="tooltip-sc"/>SC</th>                                  

                                  <th className="pr-1 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-ho" data-tooltip-content="Home Office" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-ho"/>HO</th>

                                  <th className="pr-1 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-sv" data-tooltip-content="Sociální volno" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-sv"/>SV</th>
                                  <th className="pr-1 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-ov" data-tooltip-content="Ostatní volno" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-ov"/>OV</th>
                                  
                                  <th className="pr-1 pl-2 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-prepra" data-tooltip-content="Pracovní přerušení" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-prepra"/>Pře+</th>

                                  <th className="pr-1 pl-2 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help" 
                                    data-tooltip-id="tooltip-prenepra" data-tooltip-content="Nepracovní přerušení" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-prenepra"/>Pře-</th>

                                  <th className="pr-1 pl-2 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-nahr" data-tooltip-content="Náhrady (hodiny)" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-nahr"/>Náhr.</th>

                                  <th className="pr-1 pl-2 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-odprac" data-tooltip-content="Odpracováno (hodiny)" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-odprac"/>Odprac.</th>

                                  <th className="pr-1 pl-2 text-right text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi cursor-help"
                                    data-tooltip-id="tooltip-saldo" data-tooltip-content="Saldo (vztaženo k dennímu úvazku)" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info">
                                    <Tooltip id="tooltip-saldo"/>+/-</th>

                                  <th className="pr-1 text-left text-sm tabulka-zahlavi-sticky tabulka-zahlavi-pozadi">Chyby</th>
                                  {showPrerus === true && <th className="pr-5 text-left tabulka-zahlavi-sticky tabulka-zahlavi-pozadi">Přerušení ...</th>}
                                </tr>
                              </thead>
                              <tbody>
                                {dochazkaMesic.map(
                                  (z, index) => (
                                    <tr key={index} className="tabulka-radek-hover">
                                      <td className={`pr-1 text-left tabulka-telo-Xramecek ${z.denvMesici === null && "border"}`}>{z.denvMesici} {z.denvTydnu}</td>
                                      <td className={`pr-1 text-left tabulka-telo-Xramecek ${z.denvMesici === null && "border"} ${z.denvMesici === null && "font-semibold text-sm "} ${z.pracDobaChyba === 3 && "doch-udal-absence"}`}>{z.popisHlUdal}</td>
                                      <td className={`pr-1 text-left tabulka-telo-Xramecek  ${z.denvMesici === null && "border"} ${z.pracDobaChyba === 1 && "doch-udal-nepotvrzeno"} ${z.pracDobaChyba === 2 && "doch-udal-neukon"} ${z.pracDobaChyba === 3 && "doch-udal-absence"}`}>{z.pracDoba}</td>
                                      <td className={`pr-1 text-left tabulka-telo-Xramecek ${z.denvMesici === null && "border"} ${z.obedOdDoChyba === 1 && "doch-udal-nepotvrzeno"} ${z.obedOdDoChyba === 2 && "doch-udal-neukon"} ${z.obedOdDoChyba === 3 && "doch-obed-chybi"}`}>{z.obedOdDo}</td>
                                      <td className={`pr-1 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.dnyDovolena}</td>
                                      <td className={`pr-1 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.prerusDovolena}</td>
                                      <td className={`pr-1 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.dnyNemoc}</td>
                                      <td className={`pr-1 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.dnySC}</td>
                                      <td className={`pr-1 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.dnyHO}</td>
                                      <td className={`pr-1 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.dnySV}</td>
                                      <td className={`pr-1 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.dnyOV}</td>
                                      {/* celkové hodnoty  */}
                                      <td className={`pr-1 pl-2 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.prerusPrac}</td>
                                      <td className={`pr-1 pl-2 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.prerusNePrac}</td>
                                      <td className={`pr-1 pl-2 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.casNahrady}</td>
                                      <td className={`pr-1 pl-2 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.celkovyCas}</td>
                                      <td className={`pr-1 pl-2 text-right tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>{z.casSaldo}</td>
                                      {/* chybové hlášení  */}
                                      <td className={`pr-1 text-left tabulka-telo-Xramecek ${z.denvMesici === null && "border"}`}>{z.poznChyby}</td>

                                      {/* Zobrazení přerušení - jen když je příznak showPrerus aktivní */}
                                      {showPrerus === true &&
                                        <td className={`pr-5 tabulka-telo-Xramecek ${z.denvMesici === null && "border font-semibold"}`}>
                                          <div className="flex flex-row">
                                            {z.preruseni.map((p, index) => {
                                              return (
                                                <div key={index} className={`pr-5 ${p.preruseniOdDoChyba === 1 && "doch-udal-nepotvrzeno"} ${p.preruseniOdDoChyba === 2 && "doch-udal-neukon"}`}>

                                                  {p.zkrPreruseni}/{p.preruseniOdDo}

                                                </div>
                                              );
                                            })}
                                          </div>
                                        </td>}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className="dialog-zapati-zarovnani">
                      <div
                        onClick={() => setShowPrerus(!showPrerus)}
                        className="tlacitko-dialog-prehledy-svetle"
                      >
                        {showPrerus === false ? "Zobrazit přerušení" : "Skrýt přerušení"}
                      </div>
                      {!zamestnanec &&
                        (<>
                          {/* Předchozí měsíc */}
                          <div
                            onClick={() => PredchoziMesic()}
                            className="tlacitko-dialog-prehledy-tmave flex"
                            data-tooltip-id="tooltip-pmesic" data-tooltip-content="Zobrazit předchozí měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                          >
                            <Tooltip id="tooltip-pmesic"/>
                            Měsíc <MinusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                          </div>

                          {/* Aktuální měsíc */}                            
                          <div
                            onClick={() => AktualniMesic()}
                            className="tlacitko-dialog-prehledy-tmave flex"
                            data-tooltip-id="tooltip-amesic" data-tooltip-content="Zobrazit aktuální měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                          >   
                            <Tooltip id="tooltip-amesic"/>
                            Měsíc <CheckCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                          </div>                          

                          {/* Následující měsíc */}
                          <div
                            onClick={() => DalsiMesic()}
                            className="tlacitko-dialog-prehledy-tmave flex"
                            data-tooltip-id="tooltip-nmesic" data-tooltip-content="Zobrazit následující měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                          >
                            <Tooltip id="tooltip-nmesic"/>
                            Měsíc <PlusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                          </div>

                        </>)}
                      <div
                        type="button"
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >     
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {zamestnanec ?
        (<div
          onClick={() => setOpen(true)}
          className="dialog-edit-ikona-informace"
        >
          <span className="sr-only">Otevřit menu úprav</span>
          <InformationCircleIcon className="ikonka-velikostM" aria-hidden="true" />
        </div>)
        : (
          <button
            className="tlacitko-plocha-prehledy"
            onClick={() => setOpen(true)}
          >
            Podrobné<br />Měsíční přehledy
          </button>
        )}
    </>
  );
}








