import React, { useEffect } from 'react';
//import KontaktyPritomnost from '../Kontakty/KontaktyPritomnost';
//import Nepritomni from './Nepritomni';
import ZapisDochazky from './ZapisDochazky';
import MesicniPrehled from './MesicniPrehled';
import RocniPrehled from "./RocniPrehled";
import DenniPrehled from './DenniPrehled';
import TydenniPrehled from './TydenniPrehled';
import { useAppContext, SET_AGENDA } from '../../Providers/AppProvider';
import { DochazkaProvider } from '../../Providers/DochazkaProvider';
import Tlacitka from './Tlacitka';
import DochazkaAdmin from './Admin/DochazkaAdmin';
import LoadUdalosti from './LoadUdalosti';
import MesicniPrehledPodrobny from './MesicniPrehledPodrobny';

export default function Dochazka() {
  const { dispatch, state: { selectedRole } } = useAppContext()
  useEffect(() => {
    dispatch({ type: SET_AGENDA, payload: { header: "Docházka", selectedAgenda: 1 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // LA 18.1.23 - úprava zobrazení tlačítek a informací dle rolí (doplnění pro role Tajemník a Tajemnikvedouci)
  // Pozn. na zobrazení tlačítek a přehledů má vliv také oprávnění dle role definované v příslušném kontroléru
  return (
    selectedRole !== undefined && (
    <DochazkaProvider>
      <LoadUdalosti />
      {/* --- Tlačítka pro rychlý zápis docházky - jen zaměstnanec*/}
      {selectedRole === 0 && <div className='mb-8'><Tlacitka /></div>}
      {/* --- Tlačítka pro zápis docházky a MR přehledy - všichni kromě administrátora*/}
      <div className="w-full">
        {selectedRole !== 3 && (
          <>
            <ZapisDochazky />
            {selectedRole === 0 && <MesicniPrehledPodrobny />}
            <MesicniPrehled />
            <RocniPrehled />
          </>
        )}
      </div>
      {/* --- Denní a týdenní přehledy - všichni kromě administrátora*/}
      {selectedRole !== 3 && (
      <div className="w-full">
        <div className="flex flex-row flex-wrap">
          <DenniPrehled />
          <TydenniPrehled />
        </div>
      </div>)}
      {/* --- Administrace zatím jen administrátor (personalista bude mít jen administraci zaměstnaců) */}  
      {selectedRole === 3 && <DochazkaAdmin />}
    </DochazkaProvider>)
  )
}