// Soubr přidán 13.5.23 pro zobrazení kontaktu (s přítomností) v rezervacích vozidel
import { Config } from "../../Config";
import React, { useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PhoneArrowUpRightIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";

export default function UzivatelKontakt({ kontaktData, kontakty, vozidla }) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-5xl sm:w-fit">
                  <div className="kontakty-okno-plocha">
                    <div className="sm:flex sm:items-start">
                      {kontaktData?.prijmeni != null ?
                        (<div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="kontakty-okno-nadpis">
                            {kontaktData?.titul && kontaktData?.titul}{kontaktData?.jmeno} {kontaktData?.prijmeni} ({kontaktData?.odbor})
                            {kontaktData?.linkaTU && <div className="mt-2">linka: {kontaktData?.linkaTU}</div>}
                          </Dialog.Title>
                          {kontaktData.pozice && <div className="mt-2 kontakty-okno-obsah">{kontaktData.pozice}</div>}
                          <div className="mt-2">
                            <div className="grid grid-cols-2 gap-x-2 gap-y-2 kontakty-okno-obsah">
                              {kontaktData?.pevnaLinka && <div>Pevná linka: <span className="font-semibold">{kontaktData?.pevnaLinka}</span></div>}
                              {kontaktData?.mobil && <div>Mobil: <span className="font-semibold">{kontaktData?.mobil}</span></div>}
                              {kontaktData?.email && <div>E-mail: <a className="kontakty-okno-dynamicke-podtrzeni" href={`mailto:${kontaktData?.email}`}>{kontaktData?.email}</a></div>}
                            </div>
                            {kontaktData.pritomen !== null &&
                              <div className=
                                {`mt-2 font-semibold kontakty-okno-obsah
                                  ${kontaktData.kodDuvoduNepr === 0 && " kontakty-plocha-barva-DN0 "} 
                                  ${kontaktData.kodDuvoduNepr === 1 && " kontakty-plocha-barva-DN1 "} 
                                  ${kontaktData.kodDuvoduNepr === 2 && " kontakty-plocha-barva-DN2 "} 
                                  ${kontaktData.kodDuvoduNepr === 3 && " kontakty-plocha-barva-DN3 "} 
                                  ${kontaktData.kodDuvoduNepr === 4 && " kontakty-plocha-barva-DN4 "} 
                                  ${kontaktData.kodDuvoduNepr === 5 && " kontakty-plocha-barva-DN5 "} `}>
                                {kontaktData.nepritomnost}
                              </div>}
                          </div>
                        </div>) :
                        <Dialog.Title as="h3" className="kontakty-okno-nadpis">
                          Subjekt nemá kontakt ...
                        </Dialog.Title>
                      }
                    </div>
                  </div>

                  <div className="kontakty-okno-zapati">
                    <div className="flex flex-row gap-2 flex-wrap justify-center">
                      <button
                        type="button"
                        className="mt-3 kontakty-okno-tlacitko-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {vozidla && (<div
        data-tooltip-id="kontakt-tooltip"
        data-tooltip-content="Otevřít kontakt"
        data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
        onClick={() => setOpen(true)}
        className="dialog-edit-ikona-informace"
      >
        <span className="sr-only">Otevřit kontakt</span>
        <PhoneArrowUpRightIcon className="ikonka-velikostS ml-1" aria-hidden="true" />
        <Tooltip id="kontakt-tooltip" />
      </div>)}
      {kontakty &&
        (
          <div className={`dark:text-gray-100 dark:shadow-sm px-2 my-[2px] py-[2px] shadow-md rounded-sm border transition-all cursor-pointer text-sm 
                            ${kontaktData.kodDuvoduNepr === 0 && " kontakty-plocha-barva-DN0 "} 
                            ${kontaktData.kodDuvoduNepr === 1 && " kontakty-plocha-barva-DN1 "} 
                            ${kontaktData.kodDuvoduNepr === 2 && " kontakty-plocha-barva-DN2 "} 
                            ${kontaktData.kodDuvoduNepr === 3 && " kontakty-plocha-barva-DN3 "} 
                            ${kontaktData.kodDuvoduNepr === 4 && " kontakty-plocha-barva-DN4 "} 
                            ${kontaktData.kodDuvoduNepr === 5 && " kontakty-plocha-barva-DN5 "} 
                            ${kontaktData.vedouci && " font-bold"} `}
            onClick={() => {
              setOpen(true);
            }}>
            {kontaktData.prijmeni} {kontaktData.jmeno} {kontaktData.linkaTU}
          </div>
        )}
    </>
  )
}