import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import { useAppContext } from '../../../Providers/AppProvider';
import { format } from 'date-fns';
//import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import PridaniUpravaStavuTach from './PridaniUpravaStavuTach';
import OdstraneniStavuTach from './OdstraneniStavuTach';
import { useVozidlaContext } from '../../../Providers/VozidlaProvider';
import CurrencyFormat from 'react-currency-format';

export default function StavyTach() {
  const { state:{refresh} } = useAppContext();
  const { state: {vozidla}} = useVozidlaContext();
  const [open, setOpen] = useState(false);
  const [stavTachometru, setStavTachometru] = useState([])
  const [idVozidla, setIdVozidla] = useState(undefined)
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open === true && idVozidla !== undefined) {
      axios.get(`${Config.ServerURL}/api/Vozidla/StavyTachometruZapis/${idVozidla}`, {
        withCredentials: true
      })
        .then(res => {
          setStavTachometru(res.data)
        })
    }
  }, [open, refresh, idVozidla]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Stavy tachometru vozidla
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              {/* 
                              <th className="pr-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Id StavuTach
                              </th> */}
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Období
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Datum odečtu
                              </th>
                              <th className="text-right tabulka-zahlavi-sticky-admin">
                                Stav tachometru
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Poznámka
                              </th>
                              <th className='pr-5 tabulka-zahlavi-sticky-admin'>
                              </th>
                            </tr>
                          </thead>

                          <tbody className="tabulka-telo-Ylinka">
                            {stavTachometru.map((s) => {
                              return (
                                <tr key={s.idStavuTach} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{s.obdobiRok}/{s.obdobiMesic}</td>
                                  <td className='tabulka-bunka-basic'>{format(new Date(s.datumOdectu), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic text-right'>{s.stavTachometru && <CurrencyFormat value={s.stavTachometru} displayType={'text'} thousandSeparator={" "} />}</td>
                                  <td className='tabulka-bunka-basic'>{s.poznamka}</td>
                                  <td className='flex mt-1 flex-row'>
                                    <PridaniUpravaStavuTach data={s} novyZaznam={false}/>
                                    <OdstraneniStavuTach idStavuTach={s.idStavuTach}/>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>
                    <div className="text-sm">
                          <select value={idVozidla} onChange={(e) => setIdVozidla(e.target.value)} className="admin-vyber-vozidla-select">
                            {!idVozidla && <option value={undefined}>Vyberte vozidlo</option>}
                            { vozidla.filter((v) => v.sledovatProvozNaklady === true).map((v) => {
                              return (
                                <option className="admin-vyber-vozidla-option" key={v.idVozidla} value={v.idVozidla}>
                                  {v.typVozidla} ({v.spz})
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      {idVozidla &&<PridaniUpravaStavuTach novyZaznam={true} data={{idVozidla: idVozidla}}/>}
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" 
      onClick={() => setOpen(true)}>
       Editace stavů tachometrů
      </button>
    </>
  )
}