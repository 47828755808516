import { ClockIcon, PhoneArrowUpRightIcon, TruckIcon } from '@heroicons/react/24/outline'

const routes = [
    {
      name: "Docházka",
      path: "/dochazka",
      icon: <ClockIcon/>,
      agendaType: 1,
    },
    {
      name: "Kontakty",
      path: "/kontakty/seznam",
      icon: <PhoneArrowUpRightIcon/>,
      agendaType: 3,
    },
    {
      name: "Vozidla",
      path: "/vozidla/datum",
      icon: <TruckIcon/>,
      agendaType: 2,
    }
]

export default routes
