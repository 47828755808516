import React from 'react';
import Dochazka from './Components/Dochazka/Dochazka';
import Layout from "./Components/Layout";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Kontakty from './Components/Kontakty/Kontakty';
import Vozidla from './Components/Vozidla/Vozidla';
import { AppProvider } from './Providers/AppProvider';
import { ThemeProvider } from './Providers/ThemeProvider';
import 'react-tooltip/dist/react-tooltip.css';
import Navod from './Components/Navod';

function App() {
  return (
    <div className='App select-none'>
      <AppProvider>
        <ThemeProvider>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route index path="/" element={<Dochazka />} />
                <Route path="/dochazka" element={<Dochazka />} />
                <Route path="/kontakty/:type" element={<Kontakty />} />
                <Route path="/vozidla/:type" element={<Vozidla />} />
                <Route path='/navod/:slug' element={<Navod />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      </AppProvider>
    </div>
  );
}

export default App;
