import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from 'react'
 import {
   useAppContext,
   SET_INFORMATION_ALERT,
   SET_ERROR_ALERT,
   SET_LOADING,
   REFRESH_DATA,
 } from "../../Providers/AppProvider";
 import axios from "axios";
 import { Config } from "../../Config";
import { InformationCircleIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { useVozidlaContext } from "../../Providers/VozidlaProvider";
import CheckBox from "../CheckBox";
import { Tooltip } from 'react-tooltip';

export default function InformacePotvrzeniRezervace({ data, potvrzeni }) {
  const { dispatch, state: {selectedRole} } = useAppContext();
  const { state: {zamestnanci} } = useVozidlaContext();
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  function PotvrditPrecteni() {
    dispatch({ type: SET_LOADING, payload: true })
     axios
       .post(
         `${Config.ServerURL}/api/Vozidla/PrecteniOdpovediDispecera/${selectedRole}`, {
            idRezerv: data.idRezerv,
         },
         {
           withCredentials: true,
         }
       )
       .then((res) => {
         if (res.data.message) {
           dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
         }
         dispatch({ type: REFRESH_DATA })
         setOpen(false);
         dispatch({ type: SET_LOADING, payload: false })
       })
       .catch((err) => {
         dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
         dispatch({ type: SET_LOADING, payload: false })
       });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek">
                  <div className="dialog-edit-obsah">
                    <div className="grid grid-cols-6 gap-3">
                    {/* nadpis okna */}
                    <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-nadpis-velky">
                          Informace o rezervaci vozidla
                        </label>  
                    </div>                  
                    {/* Stav rezervace   */}
                    <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-info-pole-popisB">
                          Stav rezervace
                        </label>
                        <div className="dialog-info-pole-div-zvyrazneni">
                          {data.stavRez === 0 && "Nová"}
                          {data.stavRez === 1 && "Potvrzena"}
                          {data.stavRez === 2 && "Jiný termín"}
                          {data.stavRez === 3 && "Sloučena"}
                          {data.stavRez === 4 && "Nerozhodnuta"}
                          {data.stavRez === 5 && "Jiné řešení"}
                          {data.stavRez === 6 && "Zamítnuta"}
                          {data.stavRez === 7 && "Zrušena zaměstnancem"}
                        </div>
                    </div>
                      {/* Vozidlo */}
                      <div className="col-span-6">
                        <label className="dialog-info-pole-popisB">
                          Přidělené vozidlo
                        </label>
                        <div className="dialog-info-pole-div-zvyrazneni">
                          {data.txtVozidlo}
                        </div>
                      </div>
                      {/* Poznámky dispečera */}
                      {data.poznamky && (<div className="col-span-6 sm:col-span-6">
                        <label className="dialog-info-pole-popisB">
                          Poznámky dispečera
                        </label>
                        <div className="dialog-info-pole-div-zvyrazneni">
                          {data.poznamky}
                        </div>
                      </div>)}
                    {/* Začátek rezervace   */}
                    <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Začátek rezervace
                        </label>
                        <div className="dialog-info-pole-div">
                          {format(new Date(data.rezervOd), "dd.MM.yyyy HH:mm")}
                        </div>
                    </div>
                      {/* Konec rezervace   */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Konec rezervace
                        </label>
                        <div className="dialog-info-pole-div">
                          {format(new Date(data.rezervDo), "dd.MM.yyyy HH:mm")}
                        </div>
                      </div>
                      {/* Cíl cesty */}
                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-50">
                          Popis cesty (cíl)
                        </label>
                        <div className="dialog-info-pole-div">
                          {data.cesta}
                        </div>
                      </div>
                      {/* Důvod cesty */}
                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-50">
                          Důvod cesty
                        </label>
                        <div className="dialog-info-pole-div">
                          {data.duvod}
                        </div>
                      </div>
                      {/* Řidič - pokud je vyplněn */}
                      {data.jmenoRidice !== "" && (<div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Řidič
                        </label>
                        <div className="dialog-info-pole-div">
                          {data.jmenoRidice}
                        </div>
                      </div>)}
                      {/* Spolucestující - pokud jsou vyplněni */}
                      {/* console.log(zamestnanci); */}
                      {data.idSpolc1 !== 0 &&(<div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.1
                        </label>
                        <div className="dialog-info-pole-div">
                          {/* LA 13.5.23 pozn. lepší použít ? před názvem vlastnosti, aby nedošlo k chybě při zpracování, když je výsledek null !  */}
                          {/* {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc1).jmeno} {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc1).prijmeni} */}
                          {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc1)?.jmeno} {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc1)?.prijmeni}
                        </div>
                      </div>)}
                      {data.idSpolc2 !== 0 &&(<div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.2
                        </label>
                        <div className="dialog-info-pole-div">
                          {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc2).jmeno} {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc2).prijmeni}
                        </div>
                      </div>)}
                      {data.idSpolc3 !== 0 &&(<div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.3
                        </label>
                        <div className="dialog-info-pole-div">
                          {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc3).jmeno} {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc3).prijmeni}
                        </div>
                      </div>)}
                      {data.idSpolc4 !== 0 &&(<div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.4
                        </label>
                        <div className="dialog-info-pole-div">
                          {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc4).jmeno} {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc4).prijmeni}
                        </div>
                      </div>)}
                      {data.idSpolc5 !== 0 &&(<div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.5
                        </label>
                        <div className="dialog-info-pole-div">
                          {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc5).jmeno} {zamestnanci.length > 0 && zamestnanci.find(z => z.idZam === data.idSpolc5).prijmeni}
                        </div>
                      </div>)}
                      {/* Přípojný vozík */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-50">
                          Přípojný vozík
                        </label>
                        <div className="dialog-info-pole-div">
                          <CheckBox checked={data.pripojnyVozik}/>
                        </div>
                      </div>
                      {/* Dálniční známka */}
                      <div className="col-span-6 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-50">
                          Dálniční známka
                        </label>
                        <div className="dialog-info-pole-div">
                          <CheckBox checked={data.dalnicniZnamka}/>
                        </div>
                      </div>
                      {/* Spec. požadavky - pokud jsou vyplněny  */}
                     {data.pozadavky && (<div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-50">
                          Speciální požadavky
                        </label>
                        <div className="dialog-info-pole-div">
                          {data.pozadavky}
                        </div>
                      </div>)}
                    </div>
                  </div>

                  <div className="dialog-edit-zapati-pozadi"> 
                    {potvrzeni && (<button
                      type="button"
                      className="dialog-edit-tlacitko-akce"
                      onClick={() => PotvrditPrecteni()}
                    >
                      Odsouhlasení rezervace
                    </button>)}
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                    >
                      Zavřít
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


      {potvrzeni ?
        <div
          data-tooltip-id="schvaleni-rezvozidla-tooltip" 
          data-tooltip-content="Odsouhlasení rezervace vozidla"  
          data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info"     
          onClick={() => setOpen(true)}
          className="dialog-edit-ikona-schvaleni"
        >
          <span className="sr-only">Zobrazit informace / potvrdit rezervaci</span>
          <CheckCircleIcon className="ikonka-velikostM" aria-hidden="true" />
          <Tooltip id="schvaleni-rezvozidla-tooltip" />
        </div>
        :
        <div
          data-tooltip-id="informace-rezvozidla-tooltip" 
          data-tooltip-content="Detailní informace o rezervaci vozidla" 
          data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info"     
          onClick={() => setOpen(true)}
          className="dialog-edit-ikona-informace"
        >
          <span className="sr-only">Zobrazit informace / potvrdit rezervaci</span>
          <InformationCircleIcon className="ikonka-velikostM" aria-hidden="true" />
          <Tooltip id="informace-rezvozidla-tooltip" />
        </div>
      }

    </>
  );
}
