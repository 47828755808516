import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { useAppContext, SET_INFORMATION_ALERT, SET_ERROR_ALERT, REFRESH_DATA } from '../../Providers/AppProvider';

import axios from 'axios';
import { Config } from "../../Config";

export default function SchvaleniDochazkyMesic({ selectedZam, mesicSV, rokSV, jmenoZam, prijmeniZam, role }) {
  const { dispatch } = useAppContext()
  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)

  function SchvaleniDochMes() {
    axios.post(`${Config.ServerURL}/api/Dochazka/ZapisDochazky/SchvaleniMesic/${role}/${selectedZam}/${mesicSV}/${rokSV}`, undefined, {
      withCredentials: true
    })
      .then((res) => {
        setOpen(false)
        dispatch({type: REFRESH_DATA})
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message })
        }
      })
      .catch(err => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message })
      })
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-dotaz-ramecek">
                  <div className="dialog-dotaz-obsah">
                    <div className="sm:flex sm:items-start">
                      <div className="dialog-dotaz-ikona-pozadi-oranzovykruh">
                        <QuestionMarkCircleIcon className="dialog-dotaz-ikona-oranzova" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="dialog-dotaz-nadpis-oranzovy">
                          Schválení měsíční docházky <br /> {jmenoZam} {prijmeniZam} <br /> za měsíc {mesicSV} / {rokSV}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="dialog-dotaz-otazka">
                            Opravdu chcete schválit docházku tohoto zaměstnance za celý měsíc ? 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-dotaz-zapati-pozadi">
                    <button type="button" className="dialog-tlacitko-dotaz-schvaleni"
                      onClick={() => SchvaleniDochMes()}>Schválit</button>
                    <button
                      type="button"
                      className="dialog-tlacitko-dotaz-zrusit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        onClick={() => setOpen(true)}
        className="dialog-tlacitko-schvaleni-otevreni"
      >
        Schválit docházku
      </div>
    </>
  )
}


