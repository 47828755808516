import React, { useState, useEffect } from "react";
import axios from "axios";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Config } from "../../Config";
import DeleteDochazka from "./DeleteDochazka";
import UpravaDochazky from "./UpravaDochazky";
import PridaniDochazky from "./PridaniDochazky";
import SchvaleniDochazky from "./SchvaleniDochazky";
import SchvaleniDochazkyMesic from "./SchvaleniDochazkyMesic";
import {
  useDochazkaContext,
  SET_ZAPIS_DOCHAZKY,
} from "../../Providers/DochazkaProvider";
import { SET_ERROR_ALERT, SET_LOADING, useAppContext } from "../../Providers/AppProvider";
import { PlusCircleIcon, MinusCircleIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from 'react-tooltip';
import Napoveda from '../Napoveda';

export default function ZapisDochazky() {
  const { dispatch, state: { selectedRole, refresh } } = useAppContext();
  const { dispatchDoch, state: { zapisDochazky } } = useDochazkaContext();
  const [open, setOpen] = useState(false);
  const [rok, setRok] = useState(new Date().getFullYear());
  const [mesic, setMesic] = useState(new Date().getMonth() + 1);
  const [podrizeni, setPodrizeni] = useState([]);
  const [selectedZam, setSelectedZam] = useState(undefined);

  //const [dochazkaMesic, setDochazka] = useState([]);

  function PredchoziMesic() {
    if (mesic > 1) {
      setMesic(mesic - 1);
    } else {
      setRok(rok - 1);
      setMesic(12);
    }
  }

  function DalsiMesic() {
    if (mesic < 12) {
      setMesic(mesic + 1);
    } else {
      setRok(rok + 1);
      setMesic(1);
    }
  }

  function AktualniMesic() {
    setRok(new Date().getFullYear());
    setMesic(new Date().getMonth() + 1);
  }

  const cancelButtonRef = useRef(null);

  function GetData(withLoader) {
    if (withLoader) {
      dispatch({ type: SET_LOADING, payload: true });
    }
    axios
      .get(
        `${Config.ServerURL}/api/Dochazka/ZapisDochazky/${selectedRole}/${rok}/${mesic}${(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) && selectedZam ? `/${selectedZam}` : ""}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        dispatchDoch({ type: SET_ZAPIS_DOCHAZKY, payload: res.data });
        if (withLoader) {
          dispatch({ type: SET_LOADING, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.message })
        dispatch({ type: SET_LOADING, payload: false });
      });
  }

  useEffect(() => {
    if (open === true) {
      if (selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) {
        dispatch({ type: SET_LOADING, payload: true });
        axios
          .get(`${Config.ServerURL}/api/Dochazka/Podrizeni/${selectedRole}`, {
            withCredentials: true,
          })
          .then((res) => {
            setPodrizeni(res.data);
            setSelectedZam(res.data[0].idZam)
          })
          .finally(() => {
            dispatch({ type: SET_LOADING, payload: false });
          });
      }
    }
    if (open === false) {
      if (selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) {
        dispatchDoch({ type: SET_ZAPIS_DOCHAZKY, payload: [] });
      }
      setSelectedZam(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selectedZam) {
      GetData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZam])

  useEffect(() => {
    if (selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) {
      if (selectedZam) {
        GetData(true);
      }
    }
    else {
      GetData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mesic, selectedRole, refresh]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />

          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek">

                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title
                        as="h3"
                        className=""
                      >
                        <div className="flex justify-between w-full">
                          <div className="flex dialog-nadpis">
                            Zápis a oprava docházky - {mesic}/{rok}
                            {(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) && selectedZam && (` - ${podrizeni.find((x) => x.idZam === parseInt(selectedZam))?.jmeno} ${podrizeni.find((x) => x.idZam === parseInt(selectedZam))?.prijmeni}`)}
                          </div>

                          {/* --- Nápověda --- */}
                          <Napoveda urlSlug="zapis-dochazky" />

                        </div>
                      </Dialog.Title>
                      {/* Zde se definuje min / max velikost okna */}
                      <div className="mt-2 tabulka-MinMaxVelikost">
                        {/* <div className="sticky top-0 w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-500 border rounded-lg px-2 py-1 mb-1 overflow-auto flex flex-row font-semibold "> */}
                        <div className="sticky top-0 w-full bg-gray-100 dark:bg-gray-700 rounded-md px-1 py-0.5 mb-1 overflow-auto flex flex-row font-semibold ">
                          <div className="w-[260px] px-2">Hlavní událost</div>
                          <div className="">Přerušení</div>
                        </div>
                        {/* Pozn. LA 16.5.23 - linky mezi řádky jsou definovány pomocí divide-y */}
                        <div className="">
                          {zapisDochazky.map((d) => {
                            var className = "px-1 py-1 min-w-[250px] w-min rounded-lg shadow-sm transition duration-300 border";
                            if (d.hlavniUdalost.typUdalostiTisk === 1)
                              className = className + " doch-typudal-1PD";
                            if (d.hlavniUdalost.typUdalostiTisk === 3)
                              className = className + " doch-typudal-3DOV";
                            if (d.hlavniUdalost.typUdalostiTisk === 4)
                              className = className + " doch-typudal-4NEM";
                            if (d.hlavniUdalost.typUdalostiTisk === 5)
                              className = className + " doch-typudal-5SC";
                            if (d.hlavniUdalost.typUdalostiTisk === 6)
                              className = className + " doch-typudal-6SV";
                            if (d.hlavniUdalost.typUdalostiTisk === 7)
                              className = className + " doch-typudal-7HO";
                            if (d.hlavniUdalost.typUdalostiTisk === 8)
                              className = className + " doch-typudal-8OV";
                            return (
                              <div key={d.hlavniUdalost.idDoch}>
                                <div className="tabulka-radek-hover rounded-lg p-1 flex gap-2">
                                  {/* ----- Zobrazení editačních ikon hlavní události ----- */}
                                  <div className={className}>
                                    <div className="flex">
                                      <div className="mr-1">
                                        {/* LA 29.11.22 přidány podmínky pro zobrazení značek pro editaci a odstranění záznamů */}
                                        {d.hlavniUdalost.editOd ||
                                          d.hlavniUdalost.editDo ? (
                                          <div>
                                            <UpravaDochazky
                                              editOd={d.hlavniUdalost.editOd}
                                              editDo={d.hlavniUdalost.editDo}
                                              preruseni={false}
                                              idDoch={d.hlavniUdalost.idDoch}
                                              role={selectedRole}
                                            />
                                          </div>
                                        ) : (
                                          <div className="px-2"></div>
                                        )}
                                        {d.hlavniUdalost.odstranit ? (
                                          <div className="mt-1">
                                            <DeleteDochazka
                                              idDoch={d.hlavniUdalost.idDoch}
                                              nazevUdal={d.hlavniUdalost.nazev}
                                              casUdal={d.hlavniUdalost.od + " - " + d.hlavniUdalost.do}
                                              role={selectedRole}
                                            />
                                          </div>
                                        ) : (
                                          <div className="px-2"></div>
                                        )}
                                        {/* LA 11.1.23 přidána možnost schálení docházky vedoucím - zobrazení ikony */}
                                        {(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) && d.hlavniUdalost.stavUdalosti === 2 ? (
                                          <div className="mt-1">
                                            <SchvaleniDochazky
                                              idDoch={d.hlavniUdalost.idDoch}
                                              nazevUdal={d.hlavniUdalost.nazev}
                                              casUdal={d.hlavniUdalost.od + " - " + d.hlavniUdalost.do}
                                              role={selectedRole}
                                            />
                                          </div>
                                        ) : (
                                          <div className="px-2"></div>
                                        )}
                                      </div>
                                      {/* ----- Zobrazení hlavní události ----- */}
                                      <div>
                                        <div className="text-sm text-black font-bold">
                                          {d.hlavniUdalost.nazev}
                                        </div>
                                        {/* LA 29.11.22 přidány podmínky pro barevné odlišení podle stavu záznamu události */}
                                        <div
                                          className={`text-sm text-black font-semibold rounded-md w-fit px-1 ${d.hlavniUdalost.stavUdalosti ===
                                            1 && "doch-udal-neukon"
                                            } ${d.hlavniUdalost.stavUdalosti ===
                                            2 && "doch-udal-nepotvrzeno"
                                            }`}
                                        >
                                          {d.hlavniUdalost.od}
                                          {d.hlavniUdalost.do && " - "}
                                          {d.hlavniUdalost.do}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* ----- Zobrazení editačních ikon přerušení ----- */}
                                  {d.preruseni.map((p) => {
                                    return (
                                      <div
                                        key={p.idDoch}
                                        className="py-1 px-2 rounded-lg shadow-sm border dark:border-gray-600 transition duration-300 doch-typudal-2PRE"
                                      >
                                        <div className="flex">
                                          <div className="mr-1">
                                            {/* LA 29.11.22 přidány podmínky pro zobrazení značek pro editaci a odstranění záznamů */}
                                            {p.editOd || p.editDo ? (
                                              <div>
                                                <UpravaDochazky
                                                  editOd={p.editOd}
                                                  editDo={p.editDo}
                                                  preruseni={true}
                                                  idDoch={p.idDoch}
                                                  role={selectedRole}
                                                />
                                              </div>
                                            ) : (
                                              // <div className="px-2"></div>
                                              <></>
                                            )}
                                            {p.odstranit ? (
                                              <div className="mt-1">
                                                <DeleteDochazka
                                                  idDoch={p.idDoch}
                                                  nazevUdal={p.nazev}
                                                  casUdal={p.od + " - " + p.do}
                                                  role={selectedRole}
                                                />
                                              </div>
                                            ) : (
                                              // <div className="px-2"></div>
                                              <></>
                                            )}
                                            {/* LA 11.1.23 přidána možnost schálení docházky vedoucím - zobrazení ikony schválení*/}
                                            {(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) && p.stavUdalosti === 2 ? (
                                              <div className="mt-1">
                                                <SchvaleniDochazky idDoch={p.idDoch}
                                                  nazevUdal={d.hlavniUdalost.nazev}
                                                  casUdal={d.hlavniUdalost.od + " - " + d.hlavniUdalost.do}
                                                  role={selectedRole}
                                                />
                                              </div>
                                            ) : (
                                              // <div className="px-2"></div>
                                              <></>
                                            )}
                                          </div>
                                          <div></div>
                                          {/* ----- Zobrazení přerušení -----*/}
                                          <div>
                                            <div className="text-sm">
                                              {p.nazev}
                                            </div>
                                            {/* LA 29.11.22 přidány podmínky pro barevné odlišení podle stavu záznamu události */}
                                            <div
                                              className={`text-sm text-black rounded-md w-fit px-1 ${p.stavUdalosti === 1 &&
                                                "doch-udal-neukon dark:text-gray-900"
                                                } ${p.stavUdalosti === 2 &&
                                                "doch-udal-nepotvrzeno dark:text-gray-900"
                                                } dark:text-gray-200`}
                                            >
                                              {p.od} - {p.do}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className="dialog-zapati-zarovnani">
                      {(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) && (
                        <div className="">
                          {/* Zaměstnanec:{" "} */}
                          <select value={selectedZam} onChange={(e) => setSelectedZam(e.target.value)} className="vyber-zamestnance-select">
                            {!selectedZam && <option value={undefined}>Vyberte zaměstnance</option>}
                            {podrizeni.map((p) => {
                              return (
                                <option className="vyber-zamestnance-option" key={p.idZam} value={p.idZam}>
                                  {p.prijmeni} {p.jmeno} - {p.odbor.zkrOd}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      {/* Předchozí měsíc */}
                      <div
                        onClick={() => PredchoziMesic()}
                        className="tlacitko-dialog-prehledy-tmave flex"
                        data-tooltip-id="tooltip-pmesic" data-tooltip-content="Zobrazit předchozí měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                      >
                        <Tooltip id="tooltip-pmesic" />
                        Měsíc <MinusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>
                      {/* Aktuální měsíc */}
                      <div
                        onClick={() => AktualniMesic()}
                        className="tlacitko-dialog-prehledy-tmave flex"
                        data-tooltip-id="tooltip-amesic" data-tooltip-content="Zobrazit aktuální měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                      >
                        <Tooltip id="tooltip-amesic" />
                        Měsíc <CheckCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>
                      {/* Následující měsíc */}
                      <div
                        onClick={() => DalsiMesic()}
                        className="tlacitko-dialog-prehledy-tmave flex"
                        data-tooltip-id="tooltip-nmesic" data-tooltip-content="Zobrazit následující měsíc" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2} data-tooltip-variant="info"
                      >
                        <Tooltip id="tooltip-nmesic" />
                        Měsíc <PlusCircleIcon className="ikonka-velikostL ml-1" aria-hidden="true" />
                      </div>
                      <PridaniDochazky selectedZam={(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) ? selectedZam : null} preruseni={false} role={selectedRole} />
                      <PridaniDochazky selectedZam={(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) ? selectedZam : null} preruseni={true} role={selectedRole} />
                      {(selectedRole === 1 || selectedRole === 2 || selectedRole === 4 || selectedRole === 5) && <SchvaleniDochazkyMesic selectedZam={selectedZam} mesicSV={mesic} rokSV={rok} jmenoZam={podrizeni.find((x) => x.idZam === parseInt(selectedZam))?.jmeno} prijmeniZam={podrizeni.find((x) => x.idZam === parseInt(selectedZam))?.prijmeni} role={selectedRole} />}
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button
        className="tlacitko-plocha-zapis"
        onClick={() => setOpen(true)}
      >
        Zápis a oprava
        <br />
        docházky
      </button>
    </>
  );
}
