import axios from "axios";
import React, { useState, Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";
import { Config } from "../Config";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { SET_LOADING, SET_WITH_LOADER, useAppContext } from "../Providers/AppProvider";

export default function Napoveda({ urlSlug }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const { dispatch } = useAppContext();

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open === true) {
      dispatch({ type: SET_WITH_LOADER} )
      dispatch({ type: SET_LOADING, payload: true} )
      axios
        .get(`${Config.ServerURL}/api/navody/${urlSlug}`, {
          withCredentials: true,
        })
        .then((response) => {
          setData(response.data.data);
        })
        .catch(() => {
          setData("### Návod nenalezen");
        })
        .finally(() => {
          dispatch({ type: SET_LOADING, payload: false})
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSlug, open]);

  return (
    // <a
    //   target="ipes-navod"
    //   href={`../navod/${urlSlug}`}
    //   data-tooltip-id="tooltip-napoveda"
    //   data-tooltip-content="Zobrazit Nápovědu"
    //   data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2}
    //   data-tooltip-variant="info"
    //   data-tooltip-place="left"
    // >
    //   <QuestionMarkCircleIcon className="napoveda-ikona-otaznik" />
    //   <Tooltip id="tooltip-napoveda" />
    // </a>
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek min-w-[80%]">
                  <div className="dialog-edit-obsah">
                    <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                      Nápověda
                    </Dialog.Title>
                      <div className="navod">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {data}
                        </ReactMarkdown>
                      </div>
                  </div>

                  <div className="kontakty-okno-zapati">
                    <div className="flex flex-row gap-2 flex-wrap justify-center">
                      <button
                        type="button"
                        className="mt-3 kontakty-okno-tlacitko-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="cursor-pointer"
        data-tooltip-id="tooltip-napoveda"
        data-tooltip-content="Zobrazit Nápovědu"
        data-tooltip-delay-show={Config.ToolTipAutoRefreshTime2}
        data-tooltip-variant="info"
        data-tooltip-place="left"
        onClick={() => setOpen(true)}
      >
        <QuestionMarkCircleIcon className="napoveda-ikona-otaznik" />
        <Tooltip id="tooltip-napoveda" />
      </div>
    </>
  );
}
