import React, { useState, useEffect } from "react";
import { TrashIcon, ArrowPathIcon, BellIcon } from '@heroicons/react/24/solid'
import { Dropdown, DropdownItem } from '@windmill/react-ui'
import axios from "axios";
//import { ThemeContext } from "../Providers/ThemeProvider";
import { Config } from "../Config";
import { useAppContext, REFRESH_DATA, SET_ERROR_ALERT, SET_INFORMATION_ALERT } from "../Providers/AppProvider"; 
import { Tooltip } from 'react-tooltip';
import { format } from "date-fns";
import Napoveda from './Napoveda';

function Header() {
  const [notifikace, setNotifikace] = useState([]);
  const {
    dispatch,
    state: { header, authenticated, refresh, loading },
  } = useAppContext();
  const [updateNotifikace, setUpdateNotifikace] = useState(false);
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
  const [load, setLoad] = useState(true)
  function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
      if (loading === true) {
          setLoad(true)
      }
      if (loading === false) {
          sleep(100).then(() => {
              if (loading === false) {
                  setLoad(false)
              }
            })
      }
  }, [loading])

  useEffect(() => {
    if (authenticated === true) {
      axios
      .get(`${Config.ServerURL}/api/Auth/Notifikace`, {
        withCredentials: true,
      })
      .then((res) => {
        setNotifikace(res.data);
      });
    }
  }, [refresh, isNotificationsMenuOpen, updateNotifikace, authenticated]);

  function OdstranitNotifikaci(id) {
    axios.post(`${Config.ServerURL}/api/Auth/OdstranitNotifikaci/${id}`, undefined, {
      withCredentials: true
    })
      .then(res => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message })
        }
        setUpdateNotifikace(!updateNotifikace)
      })
      .catch(err => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message })
      })
  }

  return (
    <header className="hlokno-hlavicka-pozadi">
      <div className="hlokno-hlavicka-obsah">
        <div data-tooltip-id="header-refresh-tooltip" data-tooltip-content="Obnovení obrazovky"  data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" onClick={() => dispatch({ type: REFRESH_DATA })}>
          <ArrowPathIcon className={`${load && "animate-spin "} rounded-full text-white dark:text-black p-1 transition-all duration-300 w-7 h-7 cursor-pointer bg-amber-600 hover:bg-amber-700 dark:bg-amber-500 hover:dark:bg-amber-600`} />
          <Tooltip id="header-refresh-tooltip" />
        </div>
        <div className="flex justify-center flex-1">
          <div className="hlokno-hlavicka-nadpis">
            <h1>{header}</h1>
          </div>
        </div>
        <ul className="flex items-center flex-shrink-0 space-x-6">
          {/* <!-- Theme toggler --> */}
          {/* <li className="flex">
            <button
              className="rounded-md focus:outline-none focus:shadow-outline-purple"
              onClick={toggleTheme}
              aria-label="Toggle color mode"
            >
              {theme === 'dark' ? (
                <SunIcon className="w-5 h-5" aria-hidden="true" />
              ) : (
                <MoonIcon className="w-5 h-5" aria-hidden="true" />
              )}
            </button>
          </li> */}
          {/* <!-- Notifications menu --> */}
          <li className="relative">

            {/* LA - div pro umístění nápověd a notifikace */}
            <div className="flex justify-between w-full">

              {/* --- Nápověda - pro uživatele --- */}
              <Napoveda urlSlug="hlavokno-uvod" /> 

            <div
              data-tooltip-id="header-notifikace-tooltip" data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
              data-tooltip-content= {notifikace && notifikace.length !== 0 ? "Máte nepřečtená upozornění (notifikace)" : "Žádná upozornění (notifikace)"}
              className="hlokno-hlavicka-notifikace ml-1"
              onClick={() => {setIsNotificationsMenuOpen(true)}}
            >
              <BellIcon className="w-8 h-8" aria-hidden="true" />
              {notifikace && notifikace.length !== 0 && (<span
                aria-hidden="true"
                className="absolute top-0 right-0 inline-block w-4 h-4 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
              ></span>)}
              <Tooltip id="header-notifikace-tooltip" />
            </div>

            </div>

            {isNotificationsMenuOpen && (
            <Dropdown
              align="right"
              isOpen={true}
              className="w-80"
              onClose={() => setIsNotificationsMenuOpen(false)}
            >
              {notifikace && notifikace.map((n) => (
                <DropdownItem key={n.idNotifikace} tag="div" className="hlokno-hlavicka-notifikace-seznam">
                  <div className="flex justify-between w-full"><div><div className="text-xs text-gray-700 dark:text-gray-300"> {format(new Date(n.odeslano), "dd.MM.yyyy HH:mm")}</div><div>{n.zprava}</div></div>
                  <div className="grid content-center">
                    <div className="cursor-pointer align-center" 
                      data-tooltip-id="header-smaznotifikaci-tooltip" 
                      data-tooltip-content="Odstranění upozornění (notifikace)"
                      data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
                      onClick={() => OdstranitNotifikaci(n.idNotifikace)}><TrashIcon className="h-5 hover:text-orange-700 dark:hover:text-orange-300" />
                      <Tooltip id="header-smaznotifikaci-tooltip" />
                    </div>
                    </div>
                  </div>
                </DropdownItem>
              )
              )}
              {notifikace && notifikace.length === 0 && <div>žádné notifikace...</div>}

            </Dropdown>)}
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
