import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { format } from "date-fns";
import axios from "axios";
import { Config } from "../../Config";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  REFRESH_DATA,
} from "../../Providers/AppProvider";
import {
  useDochazkaContext,
} from "../../Providers/DochazkaProvider";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export default function PridaniDochazky({ preruseni, selectedZam, role }) {
  const {
    state: { globalniUdalosti },
  } = useDochazkaContext();
  const { dispatch } = useAppContext();
  const [open, setOpen] = useState(false);
  const [udalosti, setUdalosti] = useState([]);
  const [idUdalosti, setIdUdalosti] = useState(null);
  const [zacatek, setZacatek] = useState(new Date());
  const [konec, setKonec] = useState(new Date());
  const [upravenKonec, setUpravenKonec] = useState(false);
  const [showTime, setShowTime] = useState(true);

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  useEffect(() => {
    if (globalniUdalosti && globalniUdalosti.count !== 0) {
      if (preruseni === true) {
        setUdalosti(globalniUdalosti.filter(x => x.preruseni === true))
        if (globalniUdalosti.filter(x => x.preruseni === true)[0]) {
          setIdUdalosti(globalniUdalosti.filter(x => x.preruseni === true)[0].idUdalosti)
        }
      }
      else if (preruseni === false) {
        setUdalosti(globalniUdalosti.filter(x => x.zakladni === true))
        if (globalniUdalosti.filter(x => x.zakladni === true)[0]) {
          setIdUdalosti(globalniUdalosti.filter(x => x.zakladni === true)[0].idUdalosti)
        }
      }
      else {
        setUdalosti(globalniUdalosti)
        if (globalniUdalosti.filter[0]) {
          setIdUdalosti(globalniUdalosti[0].idUdalosti)
        }
      }
    }
  }, [preruseni, globalniUdalosti]);

  useEffect(() => {
    var zac = new Date();
    var kon = new Date();
    zac.setMilliseconds(0);
    if (preruseni === false) {
      zac.setHours(0);
      zac.setMinutes(0);
      zac.setSeconds(0);
    }
    kon.setHours(23);
    kon.setSeconds(59);
    kon.setMinutes(59);
    kon.setMilliseconds(0);
    setUpravenKonec(false)
    setZacatek(format(
      new Date(zac),
      "yyyy-MM-dd HH:mm:ss"
    ));
    setKonec(format(
      new Date(kon),
      "yyyy-MM-dd HH:mm:ss"
    )); //dnes 23:59:59
  }, [open, globalniUdalosti, preruseni]);

  useEffect(() => {
    const udalost = globalniUdalosti.find((u) => u.idUdalosti === idUdalosti);
    if (udalost) {
      if (udalost.casIn === true) {
        setShowTime(true);
      } else {
        setShowTime(false);
      }
    }
  }, [open, idUdalosti, globalniUdalosti]);

  const cancelButtonRef = useRef(null);

  function AddDoch() {
    dispatch({ type: SET_LOADING, payload: true })
    axios
      .post(
        `${Config.ServerURL}/api/Dochazka/ZapisDochazky/${role}${selectedZam ? `/${selectedZam}` : ""}`,
        {
          idUdalosti: idUdalosti,
          zacatek: zacatek,
          konec: konec,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        dispatch({type: REFRESH_DATA})
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        setOpen(false);
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: SET_ERROR_ALERT, payload: (err.response ? err.response.data.message : err.message) });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                  {preruseni === false ? "Přidání hlavní události" :  "Přidání přerušení"}
                  </Dialog.Title>

                    <div className="grid grid-cols-6 gap-3">
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">Typ události</label>
                        <select
                          value={idUdalosti}
                          onChange={(e) =>
                            setIdUdalosti(parseInt(e.target.value))
                          }
                          className="dialog-edit-pole-select"
                        >
                          {udalosti.map((udalost, index) => (
                            <option key={index} value={udalost.idUdalosti}>
                              {udalost.nazev} ({udalost.zkrUdal})
                            </option>
                          ))}
                        </select>
                        {/* LA + AA 13.5.23 Zobrazení popisu vybrané události */}
                        <ReactMarkdown className="reactMarkDown dialog-edit-pole-napoveda">
                        {udalosti.length > 0 ? udalosti.find(u => u.idUdalosti === idUdalosti)?.vysvetlivky : ""}
                        </ReactMarkdown>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Začátek
                        </label>
                        {showTime ? (
                          <input
                            value={format(
                              new Date(zacatek),
                              "yyyy-MM-dd HH:mm"
                            )}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setZacatek(e.target.value);
                              if (upravenKonec === false) {
                                var d = new Date(e.target.value)
                                d.setHours(23)
                                d.setMinutes(59)
                                d.setSeconds(59)
                                setKonec(format(d, "yyyy-MM-dd HH:mm" ))}
                            }}
                            type="datetime-local"
                            className="dialog-edit-pole-input"
                          ></input>
                        ) : (
                          <input
                            value={format(new Date(zacatek), "yyyy-MM-dd")}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setZacatek(e.target.value);
                              if (upravenKonec === false) {
                                setKonec(format(new Date(e.target.value), "yyyy-MM-dd"))}
                            }}
                            type="date"
                            className="dialog-edit-pole-input"
                          ></input>
                        )}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Konec
                        </label>
                        {showTime ? (
                          <input
                            value={format(new Date(konec), "yyyy-MM-dd HH:mm")}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setKonec(e.target.value);
                              setUpravenKonec(true)
                            }}
                            type="datetime-local"
                            className="dialog-edit-pole-input"
                          ></input>
                        ) : (
                          <input
                            value={format(new Date(konec), "yyyy-MM-dd")}
                            onChange={(e) => {
                              dateIsValid(e.target.value) &&
                                setKonec(e.target.value);
                              setUpravenKonec(true)
                            }}
                            type="date"
                            className="dialog-edit-pole-input"
                          ></input>
                        )}
                      </div>
                      {/* {preruseni === false && (
                        <div className="col-span-6 sm:col-span-3">
                          <label className="block text-sm font-medium text-gray-700">
                            Počet prac. dnů
                          </label>
                          <input
                            disabled
                            value={pocpracdnu}
                            onChange={(e) => setPocpracdnu(e.target.value)}
                            type="number"
                            min="0"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-akce" 
                      onClick={() => AddDoch()}
                    >
                      Přidat
                    </button>
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        onClick={() => setOpen(true)}
        className="tlacitko-dialog-zapisUdalosti-male"
      >
        {preruseni ? "Přidat přerušení" : "Přidat hlavní událost"}
      </div>
    </>
  );
}
