import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { format } from "date-fns";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  REFRESH_DATA,
} from "../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../Config";
import { ArrowUpOnSquareStackIcon } from "@heroicons/react/24/solid";
import { Tooltip } from 'react-tooltip';

export default function KopieRezervace({ data }) {
  const { dispatch } = useAppContext();
  const [open, setOpen] = useState(false);
  const [rezervaceData, setRezervaceData] = useState({});

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  useEffect(() => {
    if (open === true) {
      setRezervaceData({
        vychDatum: format(
          new Date(),
          "yyyy-MM-dd"
        ),
        poslDatum: format(
          new Date(),
          "yyyy-MM-dd"
        ),
        idRezerv: data.idRezerv,
        cetnostOpakovani: 1,
      });

    }
  }, [data, open]);

  const cancelButtonRef = useRef(null);

  function KopirovatRezervaci() {
    dispatch({ type: SET_LOADING, payload: true })

    console.log(rezervaceData)
    axios
      .post(
        `${Config.ServerURL}/api/Vozidla/KopieRezervaceVozidla`,
        rezervaceData,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        //DO: refresh rezervaci
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        setOpen(false);
        dispatch({ type: REFRESH_DATA })
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }


  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek">
                  <div className="dialog-edit-obsah">
                    <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                      Kopie rezervace vozidla: <br /> 
                          {data.txtVozidlo} <br /> 
                          {data.jmenoZam} <br /> 
                          {data.cesta} <br />
                          {data.txtCasRez} <br />
                    </Dialog.Title>
                    <div className="grid grid-cols-6 gap-3">
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Výchozí datum
                        </label>
                        <input
                          value={rezervaceData.vychDatum}
                          onChange={(e) => {
                            dateIsValid(e.target.value) &&
                              setRezervaceData({ ...rezervaceData, vychDatum: e.target.value })
                          }}
                          type="date"
                          className="dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Poslední datum
                        </label>
                        <input
                          value={rezervaceData.poslDatum}
                          onChange={(e) => {
                            dateIsValid(e.target.value) &&
                              setRezervaceData({ ...rezervaceData, poslDatum: e.target.value })
                          }}
                          type="date"
                          className="dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Četnost opakování
                        </label>
                        <select
                          value={rezervaceData.cetnostOpakovani || 1}
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              cetnostOpakovani: e.target.value,
                            })
                          }
                          className="dialog-edit-pole-select"
                        >
                          <option value={1}>Každý týden</option>
                          <option value={2}>Každý měsíc</option>
                        </select>
                      </div>
                      {/* LA - Přidáno 15.2.23 */}
                      <div className="col-span-6 sm:col-span-6 flex content-center">
                        <label className="dialog-edit-checkbox-popis">
                          Kopie označit "Zaměstnanec přečetl"  
                        </label>
                        <input
                          checked={rezervaceData.oznacRezPrectena || false}
                          type="checkbox"
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              oznacRezPrectena: e.target.checked,
                            })
                          }
                          className="dialog-edit-pole-checkbox"
                        ></input>
                      </div>


                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-akce"
                      onClick={() => KopirovatRezervaci()}
                    >
                      Kopírovat rezervaci
                    </button>
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        data-tooltip-id="kopie-rezvozidla-tooltip" 
        data-tooltip-content="Kopírování rezervace vozidla"  
        data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
        onClick={() => setOpen(true)}
        className="dialog-edit-ikona-oprava"
      >
        <span className="sr-only">Otevřit menu úprav</span>
        <ArrowUpOnSquareStackIcon className="ikonka-velikostS" aria-hidden="true" />
        <Tooltip id="kopie-rezvozidla-tooltip" />
      </div>
    </>
  );
}
