import axios from "axios"
import React, { useState } from "react"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import rehypeRaw from "rehype-raw";
import { useParams } from "react-router"
import { Config } from "../Config"
import { useEffect } from "react"
import Loader from "./Loader"

export default function Navod() {
    const slug = useParams()["slug"]
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState("")

    useEffect(() => {
        axios.get(`${Config.ServerURL}/api/navody/${slug}`, {
            withCredentials: true
        }).then((response) => {
            setData(response.data.data)
        })
        .catch(() => {
            setData("### Návod nenalezen")
        })
        .finally(() => {
            setLoading(false)
        })
    }, [slug])

    if (loading) return (<Loader />)

    return (
        <div className="min-h-screen min-w-screen dark:text-gray-200 navod p-10">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{data}</ReactMarkdown>
        </div>
    )
}
