import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import UpravaZamestnance from './UpravaZamestnance';
// import DeleteZamestnanec from './DeleteZamestnanec';
import DeleteZaznamu from './DeleteZaznamu';
import PridaniZamestnance from './PridaniZamestnance';
import CheckBox from '../../CheckBox';
import { format } from 'date-fns';
import { useAppContext } from '../../../Providers/AppProvider';

export default function ZapisZamestnanci() {
  const { dispatch, state: {refresh} } = useAppContext()
  const [open, setOpen] = useState(false)
  const [aktivniZamestnanci, setAktivniZamestnanci] = useState(true)
  const [zamestnanci, setZamestnanci] = useState([])
  const [zamestZAPI, setZamestZAPI] = useState([])
  const cancelButtonRef = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (open) {
      dispatch({ type: 'SET_LOADING', payload: true })
      axios.get(`${Config.ServerURL}/api/Dochazka/ZamestnanciZapis/${aktivniZamestnanci ? "1" : "0"}`, {
        withCredentials: true
      })
        .then(res => {
          setZamestZAPI(res.data)
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING', payload: false })
        })
    }
    // eslint-disable-next-line
  }, [open, aktivniZamestnanci, refresh]);

  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setZamestnanci(zamestZAPI.filter(z => z.prijmeni?.toLowerCase().startsWith(search.toLowerCase()) || z.jmeno?.toLowerCase().startsWith(search.toLowerCase()) || 
      z.odbor?.toLowerCase().includes(search.toLowerCase()) || z.pozice?.toLowerCase().includes(search.toLowerCase()) 
      ))
    }
    else {
      setZamestnanci(zamestZAPI)
    }
  }, [zamestZAPI, search])

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Seznam {aktivniZamestnanci ? "aktivních" : "vyřazených"} zaměstnanců
                        </div>
                      </Dialog.Title>

                      {/* Pole pro hledání/filtraci a tlačítko pro zrušení*/}
                      <div className="vyhledavani-div mt-2">
                        <input className='vyhledavani-input' 
                          value={search} placeholder="Vyhledání zaměstnanců (jméno, odbor, pozice) ..." onChange={(e) => setSearch(e.target.value)} />   
                        {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
                        {search.length >= 2 &&
                          <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
                            Zrušit hledání
                          </div>}
                      </div>

                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                ID zam.
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Příjmení 
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Jméno
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Titul
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Pohlaví
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Odbor
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                               Vedoucí
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Pozice
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Datum <br /> nástupu
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Ukončení <br /> prac.poměru
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Přihlašovací jméno
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Denní úvazek
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Zobrazit <br /> v kontaktech
                              </th>
                              {/* <th className="text-left tabulka-zahlavi-sticky-admin">
                                Nevkládat pauzu na oběd
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                               Nezkracovat ...
                              </th> */}
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                               Nezobrazovat <br /> nepřítomnost
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                               Vyřazen
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Editace
                              </th>
                            </tr>
                          </thead>

                         <tbody className="tabulka-telo-Ylinka">
                            {zamestnanci.map((z) => {
                              return (
                                <tr key={z.idZam}  className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{z.idZam}</td>
                                  <td className='tabulka-bunka-basic'>{z.prijmeni}</td>
                                  <td className='tabulka-bunka-basic'>{z.jmeno}</td>
                                  <td className='tabulka-bunka-basic'>{z.titul}</td>
                                  <td className='tabulka-bunka-basic'>{z.pohlavi}</td>                                  
                                  <td className='tabulka-bunka-basic'>{z.odbor}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={z.vedouci }/></td>
                                  <td className='tabulka-bunka-basic'>{z.pozice}</td>
                                  <td className='tabulka-bunka-basic'>{z.datNastupu != null && format(new Date(z.datNastupu), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic'>{z.datUkonceniPP != null && format(new Date(z.datUkonceniPP), "dd.MM.yyyy")}</td>
                                  <td className='tabulka-bunka-basic'>{z.domenaPrihlasovaciJmeno}</td>
                                  <td className='tabulka-bunka-basic'>{z.denuvazek}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={z.zobrazVKontPritom}/></td>
                                  {/* <td className='tabulka-bunka-basic'><CheckBox checked={z.nevkladatPauzuObed}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={z.nezkracovatPriViceHod}/></td> */}
                                  <td className='tabulka-bunka-basic'><CheckBox checked={z.nezobrazVNepritomnosti}/></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={z.vyrazen }/></td>
                                  <td className='flex mt-1 flex-row'>
                                    <UpravaZamestnance data={z}/> 
                                    <DeleteZaznamu urlAPI="/api/Dochazka/OdstraneniZamestnance/" idZaznamu={z.idZam} 
                                                  titulek={"Zaměstnanec: " + z.prijmeni + " " + z.jmeno}
                                                  dotaz={"Přejete si odstranit uvedeného zaměstnance?"}/> 
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                  <div className='flex flex-row gap-2 flex-wrap justify-center'>  <PridaniZamestnance/>
                    <div className='admin-dialog-tlacitko-prepinac' onClick={() => setAktivniZamestnanci(!aktivniZamestnanci)}>{aktivniZamestnanci ? "Zobrazit vyřazené zaměstnance" : "Zobrazit aktivní zaměstnance"}</div>
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
        Zaměstnanci
      </button>
    </>
  )
}