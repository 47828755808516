import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  REFRESH_DATA
} from "../../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../../Config";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { useVozidlaContext } from "../../../Providers/VozidlaProvider";
import { Tooltip } from "react-tooltip";

export default function PridaniUpravaNakladu({ data, novyZaznam }) {
  const { dispatch } = useAppContext();
  const { state: { kategorie }} = useVozidlaContext();
  const [open, setOpen] = useState(false);
  const [nakladyData, setNakladyData] = useState({});

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  useEffect(() => {
    if (open === true) {
      setNakladyData(data)
      if (novyZaznam === false) {
        var d = structuredClone(data)
        d.datumVznikuNakladu = format(new Date(data.datumVznikuNakladu), "yyyy-MM-dd")
        setNakladyData(d)
      }
      if (novyZaznam === true) {
        setNakladyData({datumVznikuNakladu: format(new Date(), "yyyy-MM-dd"), idVozidla: data.idVozidla})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const cancelButtonRef = useRef(null);

  function UpravitKategorii() {
    dispatch({ type: SET_LOADING, payload: true })
    axios
      .post(
        `${Config.ServerURL}/api/Vozidla/UpravaProvoznihoNakladu`,
        nakladyData,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        setOpen(false);
        dispatch({ type: REFRESH_DATA })
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  function PridatKategorii() {
    dispatch({ type: SET_LOADING, payload: true })
    axios
      .post(
        `${Config.ServerURL}/api/Vozidla/PridaniProvoznihoNakladu`,
        nakladyData,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch({ type: REFRESH_DATA })
        setOpen(false);
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-3xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                  {novyZaznam === true && ("Přidání provozních nákladů:")}
                  {novyZaznam === false && ("Oprava provozních nákladů:")}
                  </Dialog.Title>

                  <div className="grid grid-cols-6 gap-3">
                      
                    <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Kategorie
                        </label>
                        <select
                          value={nakladyData.idKategorieNakladu}
                          onChange={(e) =>{
                            setNakladyData({
                              ...nakladyData,
                              idKategorieNakladu: e.target.value,
                            });
                            novyZaznam === true && setNakladyData({...nakladyData, idKategorieNakladu: e.target.value, popisNakladu: kategorie.find(k => k.idKategorie === parseInt(e.target.value))?.popisNakladuVzor || ""})}
                          }
                          className="admin-dialog-edit-pole-select"
                        >
                          {!nakladyData.idKategorieNakladu && <option value={undefined}>Vyberte kategorii</option>}
                          {kategorie.map((kategorie) => (
                            <option key={kategorie.idKategorie} value={kategorie.idKategorie}>{kategorie.zkrNazevKat} - {kategorie.nazevKategorie}</option>
                            // ({kategorie.jednotkaMnozstvi})
                          ))}
                        </select>
                      </div>
                      
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                        Popis nákladů
                        </label>
                        <input
                          value={nakladyData.popisNakladu || ""}
                          onChange={(e) =>
                            setNakladyData({
                              ...nakladyData,
                              popisNakladu: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Datum vzniku nákladů
                        </label>
                        <input
                          value={nakladyData.datumVznikuNakladu}
                          type="date"
                          onChange={(e) => {dateIsValid(e.target.value) &&
                            setNakladyData({
                              ...nakladyData,
                              datumVznikuNakladu: e.target.value,
                            })
                          }}
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        
                      </div>
                      
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Stav tachometru
                        </label>
                        <input
                          value={nakladyData.stavTachometru || 0}
                          type="number"
                          onChange={(e) =>
                            setNakladyData({
                              ...nakladyData,
                              stavTachometru: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Množství {nakladyData.idKategorieNakladu && `(${kategorie.find(k => k.idKategorie === parseInt(nakladyData.idKategorieNakladu))?.jednotkaMnozstvi || ""})`}
                        </label>
                        <input
                          value={nakladyData.mnozstvi || 0}
                          type="number"
                          onChange={(e) =>
                            setNakladyData({
                              ...nakladyData,
                              mnozstvi: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Náklady v Kč
                        </label>
                        <input
                          value={nakladyData.nakladyKc || 0}
                          type="number"
                          onChange={(e) =>
                            setNakladyData({
                              ...nakladyData,
                              nakladyKc: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>
                      
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          Poznámka
                        </label>
                        <textarea
                          value={nakladyData.poznamka || ""}
                          onChange={(e) =>
                            setNakladyData({
                              ...nakladyData,
                              poznamka: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  
                  {/* Tlačítka */}
                  <div className="dialog-edit-zapati-pozadi">
                    {novyZaznam === false &&
                      (<button
                        type="button"
                        className="admin-dialog-edit-tlacitko-akce"
                        onClick={() => UpravitKategorii()}
                      >
                        Uložit
                      </button>)}
                    {novyZaznam === true &&
                      (
                        <button
                          type="button"
                          className="admin-dialog-edit-tlacitko-akce"
                          onClick={() => PridatKategorii()}
                        >
                          Přidat
                        </button>
                      )}
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {novyZaznam === false &&
        (<div         
          data-tooltip-id="oprava-provnakladu-tooltip" 
          data-tooltip-content="Oprava provozního nákladu"
          data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
          onClick={() => setOpen(true)}
          className="admin-dialog-edit-ikona-oprava"
        >
          <span className="sr-only">Otevřit menu úprav</span>
          <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
          <Tooltip id="oprava-provnakladu-tooltip" />
        </div>)}
      {novyZaznam === true && (
        <div
          onClick={() => setOpen(true)}
          className={`admin-dialog-tlacitko-akce`}
        >
          Přidat náklady
        </div>
      )}
    </>
  );
}
