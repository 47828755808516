import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { format } from "date-fns";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  REFRESH_DATA,
} from "../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../Config";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { useVozidlaContext } from "../../Providers/VozidlaProvider";
import { Tooltip } from 'react-tooltip';

export default function PridaniUpravaRezervace({ data, novaRezervace }) {
  const { dispatch, state:{selectedRole} } = useAppContext();
  const { state: { zamestnanci, vozidla}} = useVozidlaContext();
  const [open, setOpen] = useState(false);
  const [rezervaceData, setRezervaceData] = useState({});
  const [vybranaVozidla, setVybranaVozidla] = useState([]);

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  useEffect(() => {
    if (open === true) {
      if (novaRezervace === true) {
        setRezervaceData({
          rezervOd: format(
            new Date(),
            "yyyy-MM-dd HH:mm"
          ),
          rezervDo: format(
            new Date(),
            "yyyy-MM-dd HH:mm"
          ),
          stavRez: 0,
        });
      }
      if (novaRezervace === false) {
        console.log(data)
        setRezervaceData(data, {
          rezervOd: format(
            new Date(data.rezervOd),
            "yyyy-MM-dd HH:mm"
          ),
          rezervDo: format(
            new Date(data.rezervDo),
            "yyyy-MM-dd HH:mm"
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (rezervaceData.pripojnyVozik === true && rezervaceData.dalnicniZnamka === true) {
      setVybranaVozidla(vozidla.filter(v => v.rezervaceNabizet && v.tazneZarizeni === true && v.dalnicniZnamka === true))
    }
    else if (rezervaceData.dalnicniZnamka === true) {
      setVybranaVozidla(vozidla.filter(v => v.rezervaceNabizet && v.dalnicniZnamka === true))
    }
    else if (rezervaceData.pripojnyVozik === true) {
      setVybranaVozidla(vozidla.filter(v => v.rezervaceNabizet && v.tazneZarizeni === true))
    }
    else {
      setVybranaVozidla(vozidla.filter(v => v.rezervaceNabizet))
    }
  }, [rezervaceData, vozidla]);

  const cancelButtonRef = useRef(null);

  function UpravitRezervaci() {
    dispatch({ type: SET_LOADING, payload: true })

    axios
      .post(
        `${Config.ServerURL}/api/Vozidla/UpravaRezervaceVozidla/${selectedRole}`,
          rezervaceData,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        setOpen(false);
        dispatch({ type: REFRESH_DATA })
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  function PridatRezervaci() {
    dispatch({ type: SET_LOADING, payload: true })

    axios
      .post(
        `${Config.ServerURL}/api/Vozidla/PridaniRezervaceVozidla/${selectedRole}`,
          rezervaceData,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        //DO: refresh rezervaci
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch({ type: REFRESH_DATA })
        setOpen(false);
        dispatch({ type: SET_LOADING, payload: false })
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
        dispatch({ type: SET_LOADING, payload: false })
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-3xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                  {novaRezervace === true ? ("Požadavek ZAMĚSTNANCE na rezervaci vozidla") : ("Oprava požadavku ZAMĚSTNANCE na rezervaci vozidla") }                  
                  </Dialog.Title>
                    <div className="grid grid-cols-6 gap-3">
                      {(selectedRole === 3 || selectedRole === 6) && (
                      <div className="col-span-6 sm:col-span-6">
                        <select
                          value={rezervaceData.idZam ? rezervaceData.idZam : undefined}
                          onChange={(e) =>
                            setRezervaceData({...rezervaceData, idZam: e.target.value})
                          }
                          className="dialog-edit-pole-select"
                        >
                          {!rezervaceData.idZam && <option value={undefined}>Vyberte zaměstnance</option>}
                          {zamestnanci.map((p) => (
                            <option key={p.idZam} value={p.idZam}>
                              {p.prijmeni} {p.jmeno} 
                            </option>
                          ))}
                        </select>
                      </div>)}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Začátek *
                        </label>
                        <input
                          value={rezervaceData.rezervOd}
                          onChange={(e) => {
                            if(dateIsValid(e.target.value)) {
                              if (novaRezervace && new Date(rezervaceData.rezervOd) >= new Date(rezervaceData.rezervDo)) {
                                setRezervaceData({ ...rezervaceData, rezervOd: e.target.value, rezervDo: e.target.value })
                              }
                              else {
                                setRezervaceData({ ...rezervaceData, rezervOd: e.target.value })
                              }
                          }
                          }}
                          type="datetime-local"
                          className="dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Konec *
                        </label>
                        <input
                          value={rezervaceData.rezervDo}
                          onChange={(e) => {
                            dateIsValid(e.target.value) &&
                              setRezervaceData({ ...rezervaceData, rezervDo: e.target.value })
                          }}
                          type="datetime-local"
                          className="dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Popis cesty (cíl) *
                        </label>
                        <input
                          value={rezervaceData.cesta || ""}
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              cesta: e.target.value,
                            })
                          }
                          className="dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Důvod cesty *
                        </label>
                        <input
                          value={rezervaceData.duvod || ""}
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              duvod: e.target.value,
                            })
                          }
                          className="dialog-edit-pole-input"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Řidič
                        </label>
                        <select
                          value={rezervaceData.idRidic ? rezervaceData.idRidic : undefined}
                          onChange={(e) =>
                            setRezervaceData({...rezervaceData, idRidic: e.target.value})
                          }
                          className="dialog-edit-pole-select"
                        >
                          {!rezervaceData.idRidic && <option value={undefined}>Vyberte zaměstnance</option>}
                          {zamestnanci.map((p) => (
                            <option key={p.idZam} value={p.idZam}>
                              {p.prijmeni} {p.jmeno} 
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.1
                        </label>
                        <select
                          value={rezervaceData.idSpolc1 ? rezervaceData.idSpolc1 : undefined}
                          onChange={(e) =>
                            setRezervaceData({...rezervaceData, idSpolc1: e.target.value})
                          }
                          className="dialog-edit-pole-select"
                        >
                          {!rezervaceData.idSpolc1 && <option value={undefined}>Vyberte zaměstnance</option>}
                          {zamestnanci.map((p) => (
                            <option key={p.idZam} value={p.idZam}>
                              {p.prijmeni} {p.jmeno} 
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.2
                        </label>
                        <select
                          value={rezervaceData.idSpolc2 ? rezervaceData.idSpolc2 : undefined}
                          onChange={(e) =>
                            setRezervaceData({...rezervaceData, idSpolc2: e.target.value})
                          }
                          className="dialog-edit-pole-select"
                        >
                          {!rezervaceData.idSpolc2 && <option value={undefined}>Vyberte zaměstnance</option>}
                          {zamestnanci.map((p) => (
                            <option key={p.idZam} value={p.idZam}>
                              {p.prijmeni} {p.jmeno} 
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.3
                        </label>
                        <select
                          value={rezervaceData.idSpolc3 ? rezervaceData.idSpolc3 : undefined}
                          onChange={(e) =>
                            setRezervaceData({...rezervaceData, idSpolc3: e.target.value})
                          }
                          className="dialog-edit-pole-select"
                        >
                          {!rezervaceData.idSpolc3 && <option value={undefined}>Vyberte zaměstnance</option>}
                          {zamestnanci.map((p) => (
                            <option key={p.idZam} value={p.idZam}>
                              {p.prijmeni} {p.jmeno} 
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.4
                        </label>
                        <select
                          value={rezervaceData.idSpolc4 ? rezervaceData.idSpolc4 : undefined}
                          onChange={(e) =>
                            setRezervaceData({...rezervaceData, idSpolc4: e.target.value})
                          }
                          className="dialog-edit-pole-select"
                        >
                          {!rezervaceData.idSpolc4 && <option value={undefined}>Vyberte zaměstnance</option>}
                          {zamestnanci.map((p) => (
                            <option key={p.idZam} value={p.idZam}>
                              {p.prijmeni} {p.jmeno} 
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          className="dialog-edit-pole-popis"
                        >
                          Spolucestující č.5
                        </label>
                        <select
                          value={rezervaceData.idSpolc5 ? rezervaceData.idSpolc5 : undefined}
                          onChange={(e) =>
                            setRezervaceData({...rezervaceData, idSpolc5: e.target.value})
                          }
                          className="dialog-edit-pole-select"
                        >
                          {!rezervaceData.idSpolc5 && <option value={undefined}>Vyberte zaměstnance</option>}
                          {zamestnanci.map((p) => (
                            <option key={p.idZam} value={p.idZam}>
                              {p.prijmeni} {p.jmeno} 
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-3 flex content-center">
                        <label className="dialog-edit-checkbox-popis">
                          Přípojný vozík
                        </label>
                        <input
                          checked={rezervaceData.pripojnyVozik || false}
                          type="checkbox"
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              pripojnyVozik: e.target.checked,
                              idVozidla: null,
                            })
                          }
                          className="dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                      <div className="col-span-6 sm:col-span-3 flex content-center">
                        <label className="dialog-edit-checkbox-popis">
                          Dálniční známka
                        </label>
                        <input
                          checked={rezervaceData.dalnicniZnamka || false}
                          type="checkbox"
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              dalnicniZnamka: e.target.checked,
                              idVozidla: null,
                            })
                          }
                          className="dialog-edit-pole-checkbox"
                        ></input>
                      </div>
                     <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          Speciální požadavky
                        </label>
                        <textarea
                          value={rezervaceData.pozadavky || ""}
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              pozadavky: e.target.value,
                            })
                          }
                          className="dialog-edit-pole-input"
                        ></textarea>
                      </div>
                      {/* Odpověď dispečera */}
                      {(selectedRole === 3 || selectedRole === 6) && (<div className="col-span-6 sm:col-span-6">
                        <Dialog.Title as="h3" className="dialog-edit-nadpis-maly">
                        Odpověď DISPEČERA:
                  </Dialog.Title>
                      </div>)}
                      {(selectedRole === 3 || selectedRole === 6) && (
                      <div className="col-span-6 sm:col-span-2">
                        <label className="dialog-edit-pole-popis">
                          Stav rezervace
                        </label>
                        <select
                          value={rezervaceData.stavRez}
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              stavRez: e.target.value,
                            })
                          }
                          className="dialog-edit-pole-input"
                        >
                          <option value="0">Nová</option>
                          <option value="1">Potvrzena</option>
                          <option value="2">Jiný termín</option>
                          <option value="3">Sloučena</option>
                          <option value="4">Nerozhodnuta</option>
                          <option value="5">Jiné řešení</option>
                          <option value="6">Zamítnuta</option>
                          <option value="7">Zrušena zaměstnancem</option>
                        </select>
                      </div>
                      )}
                      {(selectedRole === 3 || selectedRole === 6) && (
                      <div className="col-span-6 sm:col-span-4">
                        <label className="dialog-edit-pole-popis">
                          Přidělené vozidlo
                        </label>
                        <select
                          value={rezervaceData.idVozidla ? rezervaceData.idVozidla : undefined}
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              idVozidla: e.target.value,
                            })
                          }
                          className="dialog-edit-pole-input"
                        >
                          {!rezervaceData.idVozidla && <option value={undefined}>Vyberte vozidlo</option>}
                          {vybranaVozidla.map((v) => (
                            <option key={v.idVozidla} value={v.idVozidla}>{v.typVozidla} ({v.spz})</option>
                          ))}
                        </select>
                      </div>
                      )}
                      {(selectedRole === 3 || selectedRole === 6) &&
                      (<div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          Poznámky dispečera
                        </label>
                        <textarea
                          value={rezervaceData.poznamky || ""}
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              poznamky: e.target.value,
                            })
                          }
                          className="dialog-edit-pole-input"
                        ></textarea>
                      </div>)}
                      {/* Adminovy umožnit nastavit příznak o přečtení, když např. zadává rezervaci sám... */}          
                      {(selectedRole === 3 || selectedRole === 6) &&
                      (<div className="col-span-6 sm:col-span-6 flex content-center">
                         <label className = {`text-sm mr-2 font-medium
                            ${rezervaceData.odpRead && "vozidla-barva-rez-precteno"} 
                            ${!rezervaceData.odpRead && "vozidla-barva-rez-neprecteno"}`}>                               
                            Zaměstnanec si odpověď dispečera PŘEČETL
                        </label>
                        <input
                          checked={rezervaceData.odpRead || false}
                          type="checkbox"
                          onChange={(e) =>
                            setRezervaceData({
                              ...rezervaceData,
                              odpRead: e.target.checked,
                            })
                          }
                          className="dialog-edit-pole-checkbox"
                        ></input>
                      </div>)}


                      {/* Zobrazení spec. příznaku o zrušení rezervace zam.*/}
                      {(selectedRole === 3 || selectedRole === 6) && rezervaceData.zamDele && 
                      (<div className="col-span-6 sm:col-span-6">
                        <label className="vozidla-barva-rez-zrusena">
                          Zaměstnanec rezervaci ZRUŠIL!
                        </label>
                      </div>)}
                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    {novaRezervace === false && 
                    (<button
                      type="button"
                      className="dialog-edit-tlacitko-akce"
                      onClick={() => UpravitRezervaci()}
                    >
                      Uložit
                    </button>)}
                    {novaRezervace === true && 
                    (
                      <button
                      type="button"
                      className="dialog-edit-tlacitko-akce"
                      onClick={() => PridatRezervaci()}
                    >
                      Přidat
                    </button>
                    )}
                    <button
                      type="button"
                      className="dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {novaRezervace === false &&
      (<div
        data-tooltip-id="oprava-rezvozidla-tooltip" 
        data-tooltip-content="Oprava rezervace vozidla"
        data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
        onClick={() => setOpen(true)}
        className="dialog-edit-ikona-oprava"
      >
        <span className="sr-only">Otevřit menu úprav</span>
        <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
        <Tooltip id="oprava-rezvozidla-tooltip" />
      </div>)}
      {novaRezervace === true && (
        <div
          onClick={() => setOpen(true)}
          className={`tlacitko-dialog-zapisUdalosti-male`}
        >
          Přidat rezervaci
        </div>
      )}
    </>
  );
}
