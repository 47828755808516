import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  REFRESH_DATA,
} from "../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../Config";
import { PencilSquareIcon } from "@heroicons/react/24/solid";

export default function AdminKontaktyUprava({ data }) {
  const { dispatch } = useAppContext();
  const [innerProps, setInnerProps] = useState({});
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setInnerProps(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function Edit() {
    axios
      .post(
        `${Config.ServerURL}/api/Kontakty/UpravaKontaktu`,
        {
          idZam: innerProps.idZam,
          tsMobil: innerProps.tsMobil,
          tsPevna: innerProps.tsPevna,
          tsEmail: innerProps.tsEmail,
          zobrazVKontPritom: innerProps.zobrazVKontPritom,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch({ type: REFRESH_DATA });
        setOpen(false);
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_ALERT,
          payload: err.response.data.message || "Chybná vstupní data!",
        });
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-lg">
                  <div className="dialog-edit-obsah">
                    <div className="hidden">{data.idZam}</div>{" "}
                    {/* 2.5 změnit název */}
                    {/* --- Příjmení (jen pro info) */}
                    <div className=" mt-2">
                      <label className="block font-bold dialog-edit-nadpis-velky">
                        Kontakt: {innerProps.prijmeni} {innerProps.jmeno}{" "}
                        {innerProps.titul}
                      </label>
                    </div>
                    <div className=" mt-2" />
                    <div className="grid grid-cols-6 gap-3">
                      {/* --- Input Pevna linka */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">
                          Pevná linka:
                        </label>
                        <input
                          value={innerProps.tsPevna}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              tsPevna: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>
                      {/* --- Input mobil */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="dialog-edit-pole-popis">Mobil:</label>
                        <input
                          value={innerProps.tsMobil}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              tsMobil: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>
                      {/* --- Input email */}
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          E-mail:
                        </label>
                        <input
                          value={innerProps.tsEmail}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              tsEmail: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                      </div>
                      {/* Aktivní / Neaktivní kontakty */}
                      <div className="col-span-6 sm:col-span-6">
                        <label className="dialog-edit-pole-popis">
                          Kontakt je aktivní ?
                        </label>
                        <input
                          type="checkbox"
                          checked={innerProps.zobrazVKontPritom}
                          onChange={(e) =>
                            setInnerProps({
                              ...innerProps,
                              zobrazVKontPritom: e.target.checked,
                            })
                          }
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-akce"
                      onClick={() => Edit()}
                    >
                      Uložit
                    </button>
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        onClick={() => setOpen(true)}
        className="admin-dialog-edit-ikona-oprava"
      >
        <span className="sr-only">Otevřit menu úprav</span>
        <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
      </div>
    </>
  );
}
