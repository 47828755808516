import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  REFRESH_DATA,
} from "../../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../../Config";
import MDEditor from "@uiw/react-md-editor";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
// 1. upravit nazev componenty
export default function PridaniUpravaNavodu({data, novyNavod}) {
  const { dispatch } = useAppContext();
  const [innerProps, setInnerProps] = useState({});
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    if (open === true) {
      if (novyNavod === true) {
        setText("");
        setInnerProps({
          nazev: "",
          slug: "",
          text: "",
        });
      } else {
        setText(data.data);
        setInnerProps(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const cancelButtonRef = useRef(null);

  function Add() {
    axios
      .post(`${Config.ServerURL}/api/Navody`, {
        nazev: innerProps.nazev,
        slug: innerProps.slug,
        data: text,
      }, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch ({ type: REFRESH_DATA })
        setOpen(false);
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message || "Chyba!" });
      });
  }

  function Update() {
    axios
      .post(`${Config.ServerURL}/api/Navody/Uprava`, {
        id: innerProps.id,
        nazev: innerProps.nazev,
        slug: innerProps.slug,
        data: text,
      }, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.message) {
          dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
        }
        dispatch ({ type: REFRESH_DATA })
        setOpen(false);
      })
      .catch((err) => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message || "Chyba!" });
      });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-edit-ramecek sm:max-w-4xl">
                  <div className="dialog-edit-obsah">
                  <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                    Přidání/úprava návodu
                  </Dialog.Title>                 
                    <div className="grid grid-cols-2 gap-3 mb-3">                      
                      <div className="col-span-2 sm:col-span-1">
                       <label className="dialog-edit-pole-popis">
                         Název
                       </label>
                       <input
                         value={innerProps.nazev || ""}
                         onChange={(e) =>
                           setInnerProps({
                             ...innerProps,
                             nazev: e.target.value,
                           })
                         }
                         className="admin-dialog-edit-pole-input"
                       ></input>
                     </div> 
                     <div className="col-span-2 sm:col-span-1">
                       <label className="dialog-edit-pole-popis">
                         Slug (název v URL)
                       </label>
                       <input
                         value={innerProps.slug || ""}
                         onChange={(e) =>
                           setInnerProps({
                             ...innerProps,
                             slug: e.target.value,
                           })
                         }
                         className="admin-dialog-edit-pole-input"
                       ></input>
                     </div>
                    </div>
                    <div className="navod p-0">
                    <MDEditor height={500} value={text} onChange={setText} /> 
                    </div>
                  </div>
                  <div className="dialog-edit-zapati-pozadi">
                    {novyNavod ?                    
                    (<button
                      type="button"
                      className="admin-dialog-edit-tlacitko-akce"
                      onClick={() => Add()}
                    >
                      Přidat
                    </button>) :
                    (<button
                      type="button"
                      className="admin-dialog-edit-tlacitko-akce"
                      onClick={() => Update()}
                    >
                      Upravit
                    </button>)}
                    <button
                      type="button"
                      className="admin-dialog-edit-tlacitko-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zrušit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {novyNavod ?
      (<div
        onClick={() => setOpen(true)}
        className="admin-dialog-tlacitko-akce"
      >
        Přidat návod
      </div>)
      : (
        <div
        onClick={() => setOpen(true)}
        className="admin-dialog-edit-ikona-oprava"
      >
        <span className="sr-only">Otevřit menu úprav</span>
        <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
      </div>
      )}
    </>
  );
}
