import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../Config"
import { REFRESH_DATA, SET_LOADING, useAppContext } from "../Providers/AppProvider";

// Volání funkce s parametrem "agenda" (1=docházka, 2=rezervace vozidel)
export default function AuditLog({ agenda }) {
  const { dispatch, state: {refresh} } = useAppContext({ type: REFRESH_DATA});
  const [open, setOpen] = useState(false)
  const [auditLog, setAuditLog] = useState([])
  const cancelButtonRef = useRef(null);
  const [rok, setRok] = useState(new Date().getFullYear());
  const [mesic, setMesic] = useState(new Date().getMonth() + 1);
  const [showInfo, setShowInfo] = useState(false);
  const [podrizeni, setPodrizeni] = useState([]);
  const [selectedZam, setSelectedZam] = useState(undefined);
  // Pro hledání ...
  const [search, setSearch] = useState("");
    const [auditLogZAPI, setAuditLogZAPI] = useState([])
  
  useEffect(() => {
    if (selectedZam && open) {
      dispatch({ type: SET_LOADING, payload: true });
      axios.get(`${Config.ServerURL}/api/App/AuditLogZapis/${agenda}/${rok}/${mesic}/${selectedZam}`, {
        withCredentials: true
      })
        .then(res => {
          setAuditLogZAPI(res.data)
        })
        .finally(() => {
          dispatch({ type: SET_LOADING, payload: false })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, rok, mesic, selectedZam, refresh]);

  useEffect(() => {
    if (open === true) {

      dispatch({ type: SET_LOADING, payload: true });
      axios
        .get(`${Config.ServerURL}/api/Dochazka/Podrizeni/3`, {
          withCredentials: true,
        })
        .then((res) => {
          setPodrizeni(res.data);
          setSelectedZam(res.data[0].idZam)
        })
        .finally(() => {
          dispatch({ type: SET_LOADING, payload: false });
        });

    }
    if (open === false) {
      // dispatchDoch({ type: SET_ZAPIS_DOCHAZKY, payload: [] });
      setSelectedZam(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  function PredchoziMesic() {
    if (mesic > 1) {
      setMesic(mesic - 1);
    } else {
      setRok(rok - 1);
      setMesic(12);
    }
  }

  function DalsiMesic() {
    if (mesic < 12) {
      setMesic(mesic + 1);
    } else {
      setRok(rok + 1);
      setMesic(1);
    }
  }

  function ShowInfo() {
    if (showInfo === true) { setShowInfo(false); }
    else { setShowInfo(true); }
  }

  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setAuditLog(auditLogZAPI.filter(z => z.jmenoZamestnance?.toLowerCase().startsWith(search.toLowerCase()) || z.roleZamestnanceTxt?.toLowerCase().startsWith(search.toLowerCase()) ||
                                           z.provedenaAkceTxt?.toLowerCase().startsWith(search.toLowerCase()) || z.datumCasProvedeniTxt?.toLowerCase().includes(search.toLowerCase()) || 
                                           z.nazevTabulky?.toLowerCase().startsWith(search.toLowerCase()) || z.jmenoVlastZazTab?.toLowerCase().startsWith(search.toLowerCase()) || 
                                           z.idZaznamuTab === parseInt(search.toLowerCase())
      ))}
    else {
      setAuditLog(auditLogZAPI)
    }
  }, [auditLogZAPI, search])

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                          {agenda === "1" &&"AuditLOG: Docházka - "} {agenda === "2" &&"AuditLOG: Rezervace Vozidel - "} {mesic}/{rok}
                        </div>
                      </Dialog.Title>

                      {/* Pole pro hledání/filtraci a tlačítko pro zrušení hledání*/}
                      <div className="vyhledavani-div mt-3">
                        <input className='vyhledavani-input' 
                          value={search} placeholder="Vyhledání rezervací (datum, jméno, cíl) ..." onChange={(e) => setSearch(e.target.value)} />   
                        {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
                        {search.length >= 2 &&
                          <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
                            Zrušit hledání
                          </div>}
                      </div>

                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Zapisovatel
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Role
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Akce
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Datum a čas
                              </th>
                              {/* <th className="text-left tabulka-zahlavi-sticky-admin">
                                Vlastník záznamu
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                ID záznamu
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                ID Del záznamu
                              </th> */}
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Tabulka
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                ID zaz.tab.
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Vlast.zaz.tab.
                              </th>
                              {showInfo === true && <th className="text-left tabulka-zahlavi-sticky-admin">
                                Podrobné info o akci
                              </th>}

                            </tr>
                          </thead>

                          <tbody className="tabulka-telo-Ylinka">
                            {auditLog.map((a, index) => {
                              return (
                                <tr key={index} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{a.jmenoZamestnance}</td>
                                  <td className='tabulka-bunka-basic'>{a.roleZamestnanceTxt}</td>
                                  <td className='tabulka-bunka-basic'>{a.provedenaAkceTxt}</td>
                                  <td className='tabulka-bunka-basic'>{a.datumCasProvedeniTxt}</td>
                                  {/* {agenda === "1" &&<td className='pr-2'>{a.jmenoZamestDoch}</td>} 
                                  {agenda === "2" &&<td className='pr-2'>{a.jmenoZamestRezervace}</td>}
                                  {agenda === "1" &&<td className='pr-2'>{a.idDoch}</td>} 
                                  {agenda === "2" &&<td className='pr-2'>{a.idRezervaceVozidla}</td>}
                                  {agenda === "1" &&<td className='pr-2'>{a.idDochDelete}</td>} 
                                  {agenda === "2" &&<td className='pr-2'>{a.idRezervaceVozidlaDelete}</td>}                                                                   */}
                                  <td className='tabulka-bunka-basic'>{a.nazevTabulky}</td>
                                  <td className='tabulka-bunka-basic text-right'>{a.idZaznamuTab}</td>
                                  <td className='tabulka-bunka-basic'>{a.jmenoVlastZazTab}</td>
                                  {showInfo === true && <td className='tabulka-bunka-basic'>{a.infoAkce}</td>}
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>
                      {/* výběr zaměstnace - zapisovatele*/}
                      <div className="text-base">
                        {/* Zapisovatel:{" "} */}
                        <select value={selectedZam} onChange={(e) => setSelectedZam(e.target.value)} className="vyber-zamestnance-select">
                          {!selectedZam && <option value={undefined}>Vyberte zaměstnance</option>}
                          {podrizeni.map((p) => {
                            return (
                              <option className="vyber-zamestnance-option" key={p.idZam} value={p.idZam}>
                                {p.prijmeni} {p.jmeno} - {p.odbor.zkrOd}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {/* ovládací tlačítka ...*/}
                      <div
                        onClick={() => ShowInfo()}
                        className="admin-dialog-tlacitko-prepinac"
                      >
                        {showInfo === false ? "Zobrazit Info" : "Skrýt Info"}
                      </div>
                      <div
                        onClick={() => PredchoziMesic()}
                        className="admin-dialog-tlacitko-prepinac"
                      >
                        Předchozí měsíc
                      </div>
                      <div
                        onClick={() => DalsiMesic()}
                        className="admin-dialog-tlacitko-prepinac"
                      >
                        Následující měsíc
                      </div>
                      <div
                        className="cursor-pointer dark:bg-gray-500 transition-all dark:hover:bg-gray-800 dark:text-white dark:border-black inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
      {agenda === "1" &&"AuditLOG: Docházka"} {agenda === "2" &&"AuditLOG: Rezervace Vozidel"}
      </button>
    </>
  )
}