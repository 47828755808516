import React, { useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react"; //, Menu
import RezervaceVozidel from "../RezervaceVozidel";
import RezervaceVozidelSPZ from "../RezervaceVozidelSPZ";
import PridaniUpravaRezervace from "../PridaniUpravaRezervace";
import RezervaceVozidelSkupinyCilu from "../RezervaceVozidelSkupinyCilu";
import RezervaceVozidelHistorie from "../RezervaceVozidelHistorie";

export default function RezervaceVozidelAdmin({ type }) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [pocMesicu, setPocetMesicu] = useState(1);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition " />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek">
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-0 text-left mb-3">
                      <Dialog.Title
                        as="h3"
                        className="dialog-nadpis"
                      >
                        <div className="flex">
                          {type === "datum" && "Rezervace vozidel dle času" } 
                          {type === "spz" && "Rezervace dle vozidel"}
                          {type === "skupiny-cilu" && "Rezervace vozidel dle cíle"}
                          {type === "historie" && "Historie rezervací vozidel"}
                        </div>
                      </Dialog.Title>
                    </div>

                    {/* --- Načtení příslušné verze rezervace do dialogového okna --- */}
                    {type === "datum" && <RezervaceVozidel />}
                    {type === "spz" && <RezervaceVozidelSPZ />}
                    {type === "skupiny-cilu" && <RezervaceVozidelSkupinyCilu />}
                    {type === "historie" && <RezervaceVozidelHistorie pocetMesicu={pocMesicu}/>}

                  </div>
                  <div className="dialog-zapati-pozadi">
                  <div className="flex flex-row gap-2 flex-wrap justify-center">
                  {type !== "historie" && <PridaniUpravaRezervace novaRezervace={true}/>}

                  {/* Tlačítka pro výběr rozsahu historie: 1,3,6 měsíců */}
                  {type === "historie" && 
                    <div onClick={() => setPocetMesicu(1)} className={`${pocMesicu === 1 ? "vozidla-plocha-tlacitko-aktivni" : "vozidla-plocha-tlacitko-neaktivni"} vozidla-dialog-hlavni-tlacitko`} >
                        1 měsíc
                    </div>}
                  {type === "historie" && 
                    <div onClick={() => setPocetMesicu(3)} className={`${pocMesicu === 3 ? "vozidla-plocha-tlacitko-aktivni" : "vozidla-plocha-tlacitko-neaktivni"} vozidla-dialog-hlavni-tlacitko`} >
                        3 měsíce
                    </div>}
                  {type === "historie" && 
                    <div onClick={() => setPocetMesicu(6)} className={`${pocMesicu === 6 ? "vozidla-plocha-tlacitko-aktivni" : "vozidla-plocha-tlacitko-neaktivni"} vozidla-dialog-hlavni-tlacitko`} >
                        6 měsíců
                    </div> }

                    {/* tlačítko zavřít */}
                    <div
                      className="tlacitko-dialog-zavrit"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Zavřít
                    </div>
                  </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button
        className="admin-tlacitko-nabidka"
        onClick={() => setOpen(true)}
      >
        {type === "datum" && <span>Rezervace<br/>dle Času</span>}
        {type === "spz" && <span>Rezervace<br/>dle Vozidel</span>}
        {type === "skupiny-cilu" && <span>Rezervace<br/>dle Cíle</span>}
        {type === "historie" && <span>Historie<br/>Rezervací</span>}
      </button>
    </>
  );
}
