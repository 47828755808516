import RezervaceVozidelAdmin from "./RezervaceVozidelAdmin";

export default function VozidlaDispecer() {
  return (
    <div className="w-full bg-blue-100 p-4 rounded-lg">
      <h3 className="font-bold text-blue-900">Dispečink rezervací vozidel</h3>
      <div className="flex flex-row">
        <RezervaceVozidelAdmin type="spz"/>
        <RezervaceVozidelAdmin type="datum"/>
        <RezervaceVozidelAdmin type="skupiny-cilu"/>
        <RezervaceVozidelAdmin type="historie"/>
      </div>
    </div>
  );
}
