import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import { useAppContext } from '../../../Providers/AppProvider';
import PridaniUpravaKategorie from './PridaniUpravaKategorie';
import { SET_KATEGORIE, useVozidlaContext } from '../../../Providers/VozidlaProvider';
import OdstraneniKategorie from './OdstraneniKategorie';
import CheckBox from '../../CheckBox';
export default function KategorieNakladu() {
  const { state:{refresh} } = useAppContext();
  const { dispatchVoz } = useVozidlaContext();
  const [open, setOpen] = useState(false);
  const [kategorieNakladu, setKategorieNakladu] = useState([])
  const cancelButtonRef = useRef(null);

  useEffect(() => {
      axios.get(`${Config.ServerURL}/api/Vozidla/KategorieNakladuZapis`, {
        withCredentials: true
      })
        .then(res => {
          dispatchVoz({ type: SET_KATEGORIE, payload: res.data })
          setKategorieNakladu(res.data)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, refresh]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />           
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                        Editace Kategorií provozních nákladů
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">                        
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                ID kategorie
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Zkratka
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Název kategorie
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Povinný stav tachometru
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Povinné množství
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Jednotka množství
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Přehledy - Skupina 
                              </th>
                              {/*  <th className="pr-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Povinné zadat dodavatele
                              </th> */}
                              <th className='text-left tabulka-zahlavi-sticky-admin'>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tabulka-telo-Ylinka">
                            {kategorieNakladu.map((k) => {
                              return (
                                <tr key={k.idKategorie} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{k.idKategorie}</td>
                                  <td className='tabulka-bunka-basic'>{k.zkrNazevKat}</td>
                                  <td className='tabulka-bunka-basic'>{k.nazevKategorie}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={k.povinnyStavTachometru} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={k.povinneMnozstvi} /></td>
                                  <td className='tabulka-bunka-basic'>{k.jednotkaMnozstvi}</td>
                                  <td className='tabulka-bunka-basic'>
                                    {k.pnSkupinaVypocty === 0 && "-"}
                                    {k.pnSkupinaVypocty === 1 && "Pohonné hmoty"}
                                    {k.pnSkupinaVypocty === 2 && "Opravy"}
                                    {k.pnSkupinaVypocty === 3 && "Údržba"}
                                    {k.pnSkupinaVypocty === 4 && "Ostatní"}
                                  </td>
                                  {/* <td className='pr-5'><CheckBox checked={k.povinneZadatDodavatele} /></td> */}
                                  <td className='flex mt-1 flex-row'>
                                    <PridaniUpravaKategorie novyZaznam={false} data={k}/>
                                    <OdstraneniKategorie idKategorie={k.idKategorie} />
                                  {/* <PridaniUpravaVozidla noveVozidlo={false} data={k}/>
                                  <DeleteVozidlo idVozidla={k.idVozidla} /> */}
                                    {/* <UpravaOdboru data={o}/>
                                    <DeleteOdbor idOdbor={o.idOdbor}/> */}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>
                    <PridaniUpravaKategorie novyZaznam={true}/>
                      {/* <div className='shadow-md hover:shadow-lg cursor-pointer bg-blue-400 text-white rounded-md px-3 sm:px-4 py-2 transition duration-500 select-none hover:bg-blue-500 focus:outline-none focus:shadow-outline' onClick={() => setAktivniVozidla(!aktivniVozidla)}>{aktivniVozidla ? "Zobrazit vyřazená vozidla" : "Zobrazit aktivní vozidla"}</div> */}
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
       Kategorie provozních nákladů
      </div>
    </>
  )
}