import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' //, Menu
import { Config } from "../../../Config"
import UpravaUdalosti from './UpravaUdalosti';
//import DeleteUdalost from './DeleteUdalost';
import DeleteZaznamu from './DeleteZaznamu';
import PridaniUdalosti from './PridaniUdalosti';
import CheckBox from '../../CheckBox';
import { useAppContext } from '../../../Providers/AppProvider';

export default function ZapisUdalosti() {
  const { dispatch, state: { refresh } } = useAppContext();
  const [open, setOpen] = useState(false)
  const [udalosti, setUdalosti] = useState([])
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    if (open === true) {
      dispatch({ type: 'SET_LOADING', payload: true })
      axios.get(`${Config.ServerURL}/api/Dochazka/UdalostiZapis`, {
        withCredentials: true
      })
        .then(res => {
          setUdalosti(res.data)
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING', payload: false })
        })
    }
    // eslint-disable-next-line
  }, [open, refresh]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="dialog-transition" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-ramecek" >
                  <div className="dialog-obsah">
                    <div className="mt-0 mx-2 text-left">
                      <Dialog.Title as="h3" className="dialog-nadpis">
                        <div className='flex'>
                          Číselník událostí
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 overflow-x-auto max-w-full tabulka-MinMaxVelikost">
                        <table className="tabulka-telo-Ylinka">
                          <thead>
                            <tr>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Id události
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Název události
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Zkratka
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Systémová
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Základní
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Přerušení
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Povolit přerušení
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Edit Datum
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Edit Čas
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Edit Dny
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Celé hod.
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Výpočet PD
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Výpočet Obědy
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Min Dny
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Max Dny
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Min délka
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Max délka
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Od zač.PD
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Max opak.
                              </th>
                              <th className="text-left tabulka-zahlavi-sticky-admin">
                                Zobrazení
                              </th>
                              <th
                                className="text-left tabulka-zahlavi-sticky-admin">
                                Editace
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tabulka-telo-Ylinka">
                            {udalosti.map((i) => {
                              return (
                                <tr key={i.idUdalosti} className='tabulka-radek-hover'>
                                  <td className='tabulka-bunka-basic'>{i.idUdalosti}</td>
                                  <td className='tabulka-bunka-basic'>{i.nazev}</td>
                                  <td className='tabulka-bunka-basic'>{i.zkrUdal}</td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.system} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.zakladni} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.preruseni} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.prepovol} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.datumIn} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.casIn} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.dnyIn} /></td>
                                  <td className='tabulka-bunka-basic'><CheckBox checked={i.pouzeCelaHod} /></td>

                                  <td className='tabulka-bunka-basic pl-2'>
                                    {i.vypPracdoby === 0 && <span> </span>}
                                    {i.vypPracdoby === 1 && <span>-</span>}
                                    {i.vypPracdoby === 2 && <span>0</span>}
                                    {i.vypPracdoby === 3 && <span>+</span>}
                                  </td>

                                  <td className='tabulka-bunka-basic pl-2'>
                                    {i.vypObedy === 0 && <span> </span>}
                                    {i.vypObedy === 1 && <span>-</span>}
                                    {i.vypObedy === 2 && <span>0</span>}
                                    {i.vypObedy === 3 && <span>+</span>}
                                  </td>

                                  <td className='tabulka-bunka-basic'>{i.minDny}</td>
                                  <td className='tabulka-bunka-basic'>{i.maxDny}</td>
                                  <td className='tabulka-bunka-basic'>{i.minCas}</td>
                                  <td className='tabulka-bunka-basic'>{i.maxCas}</td>
                                  <td className='tabulka-bunka-basic'>{i.minCasOdZacPD}</td>
                                  <td className='tabulka-bunka-basic'>{i.maxOpak}</td>
                                  <td className='tabulka-bunka-basic'>
                                    {i.typuTisk === 1 && <span>pracovní den</span>}
                                    {i.typuTisk === 2 && <span>přerušení</span>}
                                    {i.typuTisk === 3 && <span>dovolená</span>}
                                    {i.typuTisk === 4 && <span>nemoc</span>}
                                    {i.typuTisk === 5 && <span>služební cesta</span>}
                                    {i.typuTisk === 6 && <span>soc.volno</span>}
                                    {i.typuTisk === 7 && <span>home office</span>}
                                    {i.typuTisk === 8 && <span>ostatní volno</span>}
                                  </td>
                                  <td className='flex mt-1 flex-row'>
                                    <UpravaUdalosti data={i} />
                                    <DeleteZaznamu urlAPI="/api/Dochazka/OdstraneniUdalosti/" idZaznamu={i.idUdalosti} 
                                                  titulek={"Událost: " + i.nazev}
                                                  dotaz={"Přejete si odstranit uvedenou událost?"}/> 
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-zapati-pozadi">
                    <div className='flex flex-row gap-2 flex-wrap justify-center'>
                      <PridaniUdalosti />
                      <div
                        className="tlacitko-dialog-zavrit"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zavřít
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button className="admin-tlacitko-nabidka" onClick={() => setOpen(true)}>
        Události
      </button>
    </>
  )
}