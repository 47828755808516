import React, { createContext, useReducer, useContext } from "react"

export const RESET = "RESET"
export const SET_AGENDA = "SET_AGENDA"
export const SET_INFORMATION_ALERT = "SET_INFORMATION_ALERT"
export const SET_ERROR_ALERT = "SET_ERROR_ALERT"
export const SET_ROLE = "SET_ROLE"
export const SET_LOADING = "SET_LOADING"
export const REFRESH_DATA = "REFRESH_DATA"
export const UPDATE_ADMIN = "UPDATE_ADMIN"
export const SET_AUTHENTICATED = "SET_AUTHENTICATED"
export const SET_WITH_LOADER = "SET_WITH_LOADER"

const initialState = {
	sections: [],
	informationAlert: null,
	errorAlert: null,
	header: "",
	selectedRole: undefined,
	selectedAgenda: undefined,
	loading: false,
	refresh: false,
	refreshWithoutLoader: false,
	loadingCount: 0,
	authenticated: false,
}

const dataReducer = (state, action) => {
	switch (action.type) {
		case SET_AGENDA: {
			return { ...state, selectedRole: undefined, header: action.payload.header, selectedAgenda: action.payload.selectedAgenda, refreshWithoutLoader: false }
		}
		case SET_INFORMATION_ALERT: {
			return { ...state, informationAlert: action.payload }
		}
		case SET_ERROR_ALERT: {
			return { ...state, errorAlert: action.payload }
		}
		case SET_ROLE: {
			return { ...state, selectedRole: action.payload, refreshWithoutLoader: false }
		}
		case SET_LOADING: {
			if (action.payload === true) {
				return { ...state, loading: true, loadingCount: state.loadingCount + 1 }
			}
			if (action.payload === false) {
				if (state.loadingCount <= 1) {
					return { ...state, loading: false, loadingCount: state.loadingCount - 1, refreshWithoutLoader: false }
				}
				else {
					return { ...state, loadingCount: state.loadingCount - 1 }
				}
			}
			return state
		}
		case SET_WITH_LOADER: {
			return { ...state, refreshWithoutLoader: false }
		}
		case REFRESH_DATA: {
			var r = state.refresh
			if (action.payload && action.payload.withoutLoader === true) {
				return { ...state, refresh: !r, refreshWithoutLoader: true }
			}
			else {
				return { ...state, refresh: !r, refreshWithoutLoader: false }
			}
		}
		case SET_AUTHENTICATED: {
			return { ...state, authenticated: action.payload }
		}
		case RESET: {
			return initialState
		}
		default: {
			return state
		}
	}
}

//const statedData = JSON.parse(localStorage.getItem(STORAGE_KEY));

export const Context = createContext({
	state: initialState,
	dispatch: () => null,
})
export const AppProvider = ({ children, ...rest }) => {
	const [state, dispatch] = useReducer(dataReducer, initialState) // statedData || initialState

	// useEffect(() => {
	// 	localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
	// }, [state])

	return (
		<Context.Provider value={{ state, dispatch }}>
			{children}
		</Context.Provider>
	)
}

export const useAppContext = () => useContext(Context)
