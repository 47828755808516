import { useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../Providers/AppProvider";
import {
  SET_VOZIDLA,
  SET_ZAMESTNANCI,
  useVozidlaContext,
} from "../../Providers/VozidlaProvider";
import { Config } from "../../Config";

export default function LoadVozidlaData() {
  const {
    state: { refresh, selectedRole },
  } = useAppContext();
  const { dispatchVoz } = useVozidlaContext();
  useEffect(() => {
    axios
      .get(`${Config.ServerURL}/api/Vozidla/VozidlaZapis/1`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatchVoz({ type: SET_VOZIDLA, payload: res.data });
      });
    axios
      .get(`${Config.ServerURL}/api/Dochazka/ZamestnanciZapis/1`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatchVoz({ type: SET_ZAMESTNANCI, payload: res.data });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, selectedRole]);
}
