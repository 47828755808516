import React, { useState, useEffect } from 'react'
import { useAppContext, SET_ROLE, SET_LOADING, REFRESH_DATA } from '../../Providers/AppProvider';
import axios from "axios";
import SidebarContent from './SidebarContent'
import { Config } from "../../Config";
import buildInfo from '../../buildInfo';
import { format } from 'date-fns';
import { Tooltip } from "react-tooltip";

export default function Sidebar() {
  const {
    dispatch, state: { selectedAgenda, selectedRole, authenticated },
  } = useAppContext()
  const [role, setRole] = useState([]);

  const [agendaRole, setAgendaRole] = useState([])
  const [startAutoRefresh, setAutoRefresh] = useState(false)

   useEffect(() => {
     let interval;
     if(startAutoRefresh === true) {
      interval = setInterval(() => {
        dispatch({ type: REFRESH_DATA, payload: { withoutLoader: true } })
      }, Config.AutoRefreshTime);
     }
     return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [startAutoRefresh]);

  useEffect(() => {
    if (authenticated === true && selectedAgenda !== undefined) {
      dispatch({ type: SET_LOADING, payload: true });
      axios
        .get(`${Config.ServerURL}/api/Auth/VychoziRoleAgendy/${selectedAgenda}`, {
          withCredentials: true,
        })
        .then((res) => {
          dispatch({ type: SET_ROLE, payload: res.data });
          dispatch({ type: SET_LOADING, payload: false });
        })
        .catch(() => dispatch({ type: SET_LOADING, payload: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgenda, authenticated]);

  useEffect(() => {
    if (authenticated === true) {
      axios
        .get(`${Config.ServerURL}/api/Auth/Role`, {
          withCredentials: true,
        })
        .then((res) => {
          setRole(res.data);
          setAutoRefresh(true)
        });
    }
  }, [authenticated]);

  useEffect(() => {
    if (role) {
      setAgendaRole(role.filter((r) => r.agenda === selectedAgenda || r.agenda === 0))
    }
  }, [role, selectedAgenda])

  return (
    <aside className="z-10 flex-shrink-0 w-48 md:w-52 xl:w-64 overflow-y-auto bg-white dark:bg-gray-800 block shadow-md dark:shadow-md">
      <SidebarContent />
      {agendaRole.length > 1 && 
      (<div 
        data-tooltip-id="nabidka-vybrole-tooltip" 
        data-tooltip-content="Výběr role (pro zvolenou agendu)"
        data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
        className='absolute w-40 md:w-44 xl:w-56 left-4 dark:text-orange-400 text-orange-700 flex justify-center text-lg'>
        {selectedRole !== undefined ? (<select
          className="cursor-pointer w-40 h-8 text-center dark:bg-gray-700 bg-gray-200 rounded-md shadow-md"
          onChange={(e) =>
            dispatch({ type: SET_ROLE, payload: parseInt(e.target.value) })
          }
          value={selectedRole}
        >
          {agendaRole
            .map((r, index) => (
              <option key={index} value={r.role}>
                {r.role === 0 && "Zaměstnanec"}
                {r.role === 1 && "Vedoucí"}
                {r.role === 2 && "Tajemník"}
                {r.role === 3 && "Administrátor"}
                {r.role === 4 && "Personalista"}
                {r.role === 5 && "Tajemnik-vedoucí"}
                {r.role === 6 && "Dispečer RV"}
              </option>
            ))}
        </select>) : <></>}
        <div className=' text-sm'>
          <Tooltip id="nabidka-vybrole-tooltip"/>
        </div>
      </div>)}

      <div className='absolute bottom-2 left-3 text-xs text-gray-700 dark:text-gray-300'>Verze: {buildInfo.buildVersion} ({format(new Date(buildInfo.buildDate), "dd.MM.yyyy")})</div>
    </aside>
  )
}
