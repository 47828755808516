import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from "../../Config";
import { SET_ERROR_ALERT, SET_LOADING, useAppContext } from '../../Providers/AppProvider';
import { SET_LAST_UPDATE, useKontaktyContext } from '../../Providers/KontaktyProvider';

export default function KontaktyPritomnost() {
  const { dispatchKont } = useKontaktyContext();
  const [pritomniZAPI, setPritomniZAPI] = useState([])
  const [pritomniKontakty, setPritomni] = useState([]);
  const { state: { refresh }, dispatch } = useAppContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    axios.get(`${Config.ServerURL}/api/Kontakty/Pritomni`, {
      withCredentials: true
    })
      .then(res => {
        setPritomniZAPI(res.data);
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch(err => {
        dispatch({ type: SET_ERROR_ALERT, payload: err.message })
        dispatch({ type: SET_LOADING, payload: false });
      })
    axios
      .get(`${Config.ServerURL}/api/Dochazka/Cas`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatchKont({ type: SET_LAST_UPDATE, payload: res.data })
      });
    // eslint-disable-next-line
  }, [refresh]);

  // Funkce pro vyhledávání
  useEffect(() => {
    if (search.length >= 2) {
      setPritomni(pritomniZAPI.filter(p => p.prijmeni?.toLowerCase().startsWith(search.toLowerCase()) || p.jmeno?.toLowerCase().startsWith(search.toLowerCase()) || 
      p.odbor?.toLowerCase().startsWith(search.toLowerCase()) || p.pozice?.toLowerCase().includes(search.toLowerCase()) || p.pevnaLinka?.includes(search) || p.mobil?.startsWith(search) ))
    }
    else {
      setPritomni(pritomniZAPI)
    }
  }, [pritomniZAPI, search])


  return (

    <div className="mt-0">
      {/* Pole pro hledání/filtraci a tlačítko pro zrušení*/}
      <div className="vyhledavani-div">
        <input className="vyhledavani-input"
          value={search} placeholder="Vyhledávání v seznamu ..." onChange={(e) => setSearch(e.target.value)} />   
        {/* tlačítko se zobrazí jen když je vyhledávání aktivní         */}
        {search.length >= 2 &&
          <div onClick={() => setSearch("")} className="vyhledavani-tlacitko">
            Zrušit hledání
          </div>}
      </div>

    {/* Zde se definuje min/max velikost tabulky */}
    <div className="kontakty-plocha-tabulka-MinMaxVelikost"> 

      {/* Tabulka s kontakty   */}
      <table className="table-auto">
        <thead>
          <tr className="text-left">
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>Příjmení</th>
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>Jméno</th>
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>Odbor</th>
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>Pozice-náplň práce</th>
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>Pevná linka</th>
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>Mobil</th>
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>E-mail</th>
            <th className='pr-2 kontakty-plocha-tabulka-zahlavi'>Nepřítomnost</th>
          </tr>
        </thead>
        <tbody>
          {pritomniKontakty.map((d, index) => {
            return (
              <tr className={`kontakty-plocha-tabulka-obsah 
                ${d.kodDuvoduNepr === 0 && " kontakty-plocha-barva-DN0 "} 
                ${d.kodDuvoduNepr === 1 && " kontakty-plocha-barva-DN1 "} 
                ${d.kodDuvoduNepr === 2 && " kontakty-plocha-barva-DN2 "} 
                ${d.kodDuvoduNepr === 3 && " kontakty-plocha-barva-DN3 "} 
                ${d.kodDuvoduNepr === 4 && " kontakty-plocha-barva-DN4 "} 
                ${d.kodDuvoduNepr === 5 && " kontakty-plocha-barva-DN5 "} 
                ${d.vedouci && " font-bold "} `} key={index}>
                <td className='py-[2px] pr-2'>{d.prijmeni}</td>
                <td className='pr-2'>{d.jmeno}</td>
                <td className='pr-2'>{d.odbor}</td>
                <td className='pr-2'>{d.pozice}</td>
                <td className='pr-2'>{d.pevnaLinka}</td>
                <td className='pr-2'>{d.mobil}</td>
                <td className='pr-2'>{d.email}</td>
                <td className='pr-2'>{d.nepritomnost}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  </div>
  )
}