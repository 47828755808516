import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { format } from "date-fns";
import {
  useAppContext,
  SET_INFORMATION_ALERT,
  SET_ERROR_ALERT,
  SET_LOADING,
  REFRESH_DATA,
} from "../../../Providers/AppProvider";
import axios from "axios";
import { Config } from "../../../Config";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

export default function PridaniUpravaVozidla({ data, noveVozidlo }) {
  const { dispatch } = useAppContext();
  const [open, setOpen] = useState(false);
  const [vozidloData, setVozidloData] = useState({});

  useEffect(() => {
    if (open === true) {
      if (noveVozidlo === false) {
        var d = structuredClone(data)
        d.techKontr = data.techKontr ? format(new Date(data.techKontr), "yyyy-MM-dd") : null
        d.pristiTechKontr = data.pristiTechKontr ? format(new Date(data.pristiTechKontr), "yyyy-MM-dd") : null
        d.platnostCCS = data.platnostCCS ? format(new Date(data.platnostCCS), "yyyy-MM-dd") : null
        setVozidloData(d)
      }
    if (noveVozidlo === true) {
      setVozidloData({})
    }
  }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

const cancelButtonRef = useRef(null);

function UpravitVozidlo() {
  dispatch({ type: SET_LOADING, payload: true })

  axios
    .post(
      `${Config.ServerURL}/api/Vozidla/UpravaVozidla`,
      vozidloData,
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      //DO: refresh rezervaci
      if (res.data.message) {
        dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
      }
      setOpen(false);
      dispatch({ type: REFRESH_DATA })
      dispatch({ type: SET_LOADING, payload: false })
    })
    .catch((err) => {
      dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
      dispatch({ type: SET_LOADING, payload: false })
    });
}

function PridatVozidlo() {
  dispatch({ type: SET_LOADING, payload: true })

  axios
    .post(
      `${Config.ServerURL}/api/Vozidla/PridaniVozidla`,
      vozidloData,
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      //DO: refresh rezervaci
      if (res.data.message) {
        dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message });
      }
      dispatch({ type: REFRESH_DATA })
      setOpen(false);
      dispatch({ type: SET_LOADING, payload: false })
    })
    .catch((err) => {
      dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message });
      dispatch({ type: SET_LOADING, payload: false })
    });
}

return (
  <>
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="dialog-transition" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="dialog-edit-ramecek sm:max-w-3xl">
                <div className="dialog-edit-obsah">
                <Dialog.Title as="h3" className="dialog-edit-nadpis-velky">
                  {noveVozidlo === true && ("Přidání nového vozidla:")}
                  {noveVozidlo === false && ("Oprava údajů vozidla:")}
                  </Dialog.Title>

                  <div className="grid grid-cols-6 gap-3">
                    <div className="col-span-6 sm:col-span-2">
                      <label className="dialog-edit-pole-popis">
                        Označení vozidla (typ)
                      </label>
                      <input
                        value={vozidloData.typVozidla || ""}
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            typVozidla: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <label className="dialog-edit-pole-popis">
                        SPZ vozidla
                      </label>
                      <input
                        value={vozidloData.spz || ""}
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            spz: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <label className="dialog-edit-pole-popis">
                        Barva
                      </label>
                      <input
                        value={vozidloData.barva || ""}
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            barva: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-1">
                      <label className="dialog-edit-pole-popis">
                        Spotřeba
                      </label>
                      <input
                        value={vozidloData.spotreba || 0.0}
                        type="number"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            spotreba: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-1">
                      <label className="dialog-edit-pole-popis">
                        Benzin
                      </label>
                      <input
                        value={vozidloData.benzin || ""}
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            benzin: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-1">
                      <label className="dialog-edit-pole-popis">
                        Počet míst
                      </label>
                      <input
                        value={vozidloData.maxPocMist || 0}
                        type="number"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            maxPocMist: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label className="dialog-edit-pole-popis">
                        Technický stav
                      </label>
                      <input
                        value={vozidloData.techStav || ""}
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            techStav: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label className="dialog-edit-pole-popis">
                        Technická kontrola
                      </label>
                      <input
                        value={vozidloData.techKontr}
                        type="date"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            techKontr: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label className="dialog-edit-pole-popis">
                        Příští technická kontrola
                      </label>
                      <input
                        value={vozidloData.pristiTechKontr}
                        type="date"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            pristiTechKontr: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label className="dialog-edit-pole-popis">
                        ČÍslo CCS karty
                      </label>
                      <input
                        value={vozidloData.cisloCCSKarty || ""}
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            cisloCCSKarty: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label className="dialog-edit-pole-popis">
                        Platnost CCS karty
                      </label>
                      <input
                        value={vozidloData.platnostCCS}
                        type="date"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            platnostCCS: e.target.value,
                          })
                        }
                        className="admin-dialog-edit-pole-input"
                      ></input>
                    </div>

                    {/* Tažné zařízení */}
                    <div className="col-span-6 sm:col-span-3 flex content-center">
                      <label className="dialog-edit-checkbox-popis">
                        Tažné zařízení
                      </label>
                      <input
                        checked={vozidloData.tazneZarizeni || false}
                        type="checkbox"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            tazneZarizeni: e.target.checked,
                          })
                        }
                        className="dialog-edit-pole-checkbox "
                      ></input>
                    </div>

                    {/* Dalniční známka */}
                    <div className="col-span-6 sm:col-span-3 flex content-center">
                      <label className="dialog-edit-checkbox-popis">
                        Dalniční známka
                      </label>
                      <input
                        checked={vozidloData.dalnicniZnamka || false}
                        type="checkbox"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            dalnicniZnamka: e.target.checked,
                          })
                        }
                        className="dialog-edit-pole-checkbox "
                      ></input>
                    </div>

                    {/* Nabízet pro rezervaci */}
                    <div className="col-span-6 sm:col-span-2 flex content-center">
                      <label className="dialog-edit-checkbox-popis">
                      Nabízet pro rezervaci
                      </label>
                      <input
                        checked={vozidloData.rezervaceNabizet || false}
                        type="checkbox"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            rezervaceNabizet: e.target.checked,
                          })
                        }
                        className="dialog-edit-pole-checkbox "
                      ></input>
                    </div>

                    {/* Zadávat provozní náklady */}
                    <div className="col-span-6 sm:col-span-2 flex content-center">
                      <label className="dialog-edit-checkbox-popis">
                      Zadávat provozní náklady
                      </label>
                      <input
                        checked={vozidloData.sledovatProvozNaklady || false}
                        type="checkbox"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            sledovatProvozNaklady: e.target.checked,
                          })
                        }
                        className="dialog-edit-pole-checkbox "
                      ></input>
                    </div>

                    {/* Vyřazeno */}
                    <div className="col-span-6 sm:col-span-2 flex content-center">
                      <label className="dialog-edit-checkbox-popis">
                        Vyřazeno
                      </label>
                      <input
                        checked={vozidloData.vyrazeno || false}
                        type="checkbox"
                        onChange={(e) =>
                          setVozidloData({
                            ...vozidloData,
                            vyrazeno: e.target.checked,
                          })
                        }
                        className="dialog-edit-pole-checkbox "
                      ></input>
                    </div>
                    {/* Pořadi vozidla v SPZ zobrazení - jen při opravě*/}
                    {noveVozidlo === false &&
                      (<div className="col-span-6 sm:col-span-6 admin-dialog-edit-sekce">

                        <label className="dialog-edit-pole-popis">
                          Preference pořadí vozidla v zobrazení rezervací dle SPZ, vyplňte jen když chcete preferenční zobrazení aktivovat.
                          Pozn. Vozidla s definovanou preferencí budou zobrazena vždy a dle uvedeného pořadí.
                        </label>
                        <input
                          value={vozidloData.poradiZobr}
                          onChange={(e) =>
                            setVozidloData({
                              ...vozidloData,
                              poradiZobr: e.target.value,
                            })
                          }
                          className="admin-dialog-edit-pole-input"
                        ></input>
                        <label className="dialog-edit-pole-popis">
                          Zadejte: Nulu pro posun na první místo. Pořadí + 1 pro posun na zvolené místo. Záporné číslo pro zrušení preference.
                        </label>
                      </div>)}

                  </div>
                </div>

                {/* Tlačítka */}                
                <div className="dialog-edit-zapati-pozadi">
                  {noveVozidlo === false &&
                    (<div
                      type="button"
                      className="admin-dialog-edit-tlacitko-akce"
                      onClick={() => UpravitVozidlo()}
                    >
                      Uložit
                    </div>)}
                  {noveVozidlo === true &&
                    (
                      <div
                        type="button"
                        className="admin-dialog-edit-tlacitko-akce"
                        onClick={() => PridatVozidlo()}
                      >
                        Přidat
                      </div>
                    )}
                  <div
                    type="button"
                    className="admin-dialog-edit-tlacitko-zavrit"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Zrušit
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    {noveVozidlo === false &&
      (<div
        data-tooltip-id="editace-vozidla-tooltip" 
        data-tooltip-content="Oprava informací o vozidle"
        data-tooltip-delay-show={Config.ToolTipAutoRefreshTime1} data-tooltip-variant="info" 
        onClick={() => setOpen(true)}
        className="admin-dialog-edit-ikona-oprava"
      >
        <span className="sr-only">Otevřit menu úprav</span>
        <PencilSquareIcon className="ikonka-velikostS" aria-hidden="true" />
        <Tooltip id="editace-vozidla-tooltip" />
      </div>)}
    {noveVozidlo === true && (
      <div
        onClick={() => setOpen(true)}
        className={`admin-dialog-tlacitko-akce`}
      >
        Přidat vozidlo
      </div>
    )}
  </>
);
}
