import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import { Config } from "../../Config";
import { useAppContext, SET_ERROR_ALERT, SET_INFORMATION_ALERT, SET_LOADING, REFRESH_DATA } from '../../Providers/AppProvider';
import PridaniPreruseni from './PridaniPreruseni';

export default function Tlacitka() {
    const { dispatch, state: {refresh} } = useAppContext()
    const [layout, setLayout] = useState(null);
    const [open, setOpen] = useState(false);
    const [openUrl, setOpenUrl] = useState(null);

    const cancelButtonRef = useRef(null)

    useEffect(() => {
        dispatch({ type: SET_LOADING, payload: true })
        axios.get(`${Config.ServerURL}/api/Dochazka/Layout`, {
            withCredentials: true
        })
            .then(res => {
                setLayout(res.data);
                dispatch({ type: SET_LOADING, payload: false })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);


    function PostButton(url) {
        dispatch({ type: SET_LOADING, payload: true })
        axios.post(`${Config.ServerURL}/api/Dochazka/${url}`, undefined, {
            withCredentials: true
        })
            .then(res => {
                if (res.data.message) {
                    dispatch({ type: SET_INFORMATION_ALERT, payload: res.data.message })
                }
                dispatch({ type: SET_LOADING, payload: false })
                dispatch({type: REFRESH_DATA})
                setOpenUrl(null)
                setOpen(false)
            })
            .catch(err => {
                dispatch({ type: SET_LOADING, payload: false })
                dispatch({ type: SET_ERROR_ALERT, payload: err.response.data.message })
            })
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="dialog-transition" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-30 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="dialog-dotaz-ramecek">
                                    <div className="dialog-dotaz-obsah">
                                        <div className="sm:flex sm:items-start">
                                            {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            </div> */}
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="dialog-dotaz-nadpis-cerveny">
                                                    Ukončení pracovního dne
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="dialog-dotaz-otazka">
                                                        Opravdu chcete ukončit pracovní den?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dialog-dotaz-zapati-pozadi">
                                        <div type="button" className="dialog-tlacitko-dotaz-akce"
                                            onClick={() => PostButton(openUrl)}>Ukončit</div>
                                        <div
                                            type="button"
                                            className="dialog-tlacitko-dotaz-zrusit"
                                            onClick={() => {
                                                setOpen(false)
                                                setOpenUrl(null)
                                            }}
                                            ref={cancelButtonRef}
                                        >
                                            Zrušit
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div>
                {/* !!!! Zvláštní chyba - barvy pozadí tlačítek definované programově nefungují korektně - např při změně intenzity z 500 na 400 se barva pozadí nezobrazí (někdy blbne i změna na ) !!!! */}
                {/* className={`tlacitko-plocha-hlavni disabled:bg-slate-400 bg-${tlac.barva}-500 hover:bg-${tlac.barva}-600`}  */}
                {/* LA 24.5.23 !!!! Nakonec po neúspěšném bádání jsem to vzdal a udělal nastavení barev "natvrdo" takže dle infa z API se jen nastavuje barva zelená / oranžová pro začátek a konec události !!!! */}
                {/* Pozn. původní "chybující verze"  je v souboru tlacitka-problem.jsx  */}
                {layout && layout.filter(d => d.clickPost !== null).map((tlac, index) => (
                    <span key={index}>
                        {tlac.potvrzeni === true ?
                            <button onClick={() => { setOpen(true); setOpenUrl(tlac.clickPost) }} type="button" className="tlacitko-plocha-KonecPD m-2" disabled={tlac.aktivni === false && true}>
                                {tlac.popis}<br />{tlac.data}
                            </button>
                            : <button onClick={() => PostButton(tlac.clickPost)} type="button" className={tlac.barva === "green" ? "tlacitko-plocha-ZacatekUdal m-2" : "tlacitko-plocha-KonecUdal m-2"} disabled={tlac.aktivni === false && true}>
                                {tlac.popis}<br />{tlac.data}
                            </button>}
                    </span>

                ))}
                {layout && layout.find(l => l.nazev === "PreZac").aktivni === true && <PridaniPreruseni />}
                {layout && layout.find(l => l.nazev === "PreKon").aktivni === true && <button onClick={() => PostButton("Konec/Preruseni")} className="tlacitko-plocha-KonecUdal m-8">{layout.find(l => l.nazev === "PreKon").popis}<br />{layout.find(l => l.nazev === "PreKon").data}</button>}
            </div>
        </>
    )
}